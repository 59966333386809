export const getDataForm = () => [
  {
    componenttype: "input",
    label: "Nombre de la agencia",
    type: "text",
    name: "AgencyName",
    value: "",
    size: "small",
    margin: "normal",
    xs: 12,
    validations: [
      {
        type: "required",
      },
    ],
  },
  {
    componenttype: "input",
    label: "Pais",
    type: "text",
    name: "Country",
    value: "",
    size: "small",
    margin: "normal",
    xs: 6,
    validations: [
      {
        type: "required",
      },
    ],
  },
  {
    componenttype: "input",
    label: "Ciudad",
    type: "text",
    name: "City",
    value: "",
    size: "small",
    margin: "normal",
    xs: 6,
    validations: [
      {
        type: "required",
      },
    ],
  },
  {
    componenttype: "input",
    label: "Dirección",
    type: "text",
    name: "Address",
    value: "",
    size: "small",
    margin: "normal",
    xs: 12,
    validations: [
      {
        type: "required",
      },
    ],
  },
  {
    componenttype: "input",
    label: "Correo",
    type: "text",
    name: "Email",
    value: "",
    size: "small",
    margin: "normal",
    xs: 12,
    validations: [
      {
        type: "required",
      },
    ],
  },
  {
    componenttype: "input",
    label: "Telefono",
    type: "text",
    name: "Phone",
    value: "",
    size: "small",
    margin: "normal",
    xs: 12,
    validations: [],
  },
  {
    componenttype: "input",
    label: "Persona de contacto",
    type: "text",
    name: "ContactName",
    value: "",
    size: "small",
    margin: "normal",
    xs: 12,
    validations: [],
  },
  {
    componenttype: "input",
    label: "Sitio web",
    type: "text",
    name: "Web",
    value: "",
    size: "small",
    margin: "normal",
    xs: 12,
    validations: [],
  },
];
