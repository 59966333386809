import { Button } from "@mui/material";
import styled from "styled-components";
import theme from "../theme";

export const StyledMUIButton = styled(Button)`
  && {
    background-color: ${(props) =>
      props.backgroundcolor || theme.Palette.quaternary};
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.4);
    border-radius: 25px;
    color: ${(props) => props.color || theme.Palette.quinary};
    margin-top: 5%;
    width: ${(props) => props.with || "100px"};
    text-transform: none;
    &:hover {
      background-color: ${theme.Palette.secondary};
      color: ${theme.Palette.quinary};
    }
  }
`;
