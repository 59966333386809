import React from "react";
import { useValidateForm } from "../../../Hooks/useValidateForm";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  StyledMUIBoxContainerForm,
  StyledMUIButton,
} from "../../../StyledCrossComponents";
import { Grid, Typography } from "@mui/material";
import {
  CrossCheck,
  CrossInputText,
  CrossLoader,
  CrossSelectFile,
} from "../../CrossComponents";
import theme from "../../../theme";
import {
  useStorageModal,
  useStoreModalNotification,
  useStoreSeattlePaymentStep,
} from "../../../store";
import { SelectPayOutMethod } from "./SelectPayOutMethod";
import { InputAmountSettle } from "./InputAmountSettle";
import { AboutSettlePayment } from "./AboutSettlePayment";
import { useFetchPost } from "../../../Hooks/useFetchPost";
import { useNavigate } from "react-router-dom";
import { SettleAccountDataInput } from "./SettleAccountDataInput";

export const SettleForm = ({
  handleNext,
  handleBack,
  activeStep,
  dataSteps,
  dataStepActive,
  form,
}) => {
  const { setModalCross } = useStorageModal();
  const setLoader = (openModal) => {
    setModalCross({
      openModal: openModal,
      component: () => <CrossLoader />,
      setValue: () => {},
      formValues: {},
    });
  };
  const stateGlobalSeattlePayment = useStoreSeattlePaymentStep(
    (state) => state.seattlePaymentStepState
  );
  const setModalAlert = useStoreModalNotification(
    (state) => state.setModalAlert
  );
  const navigate = useNavigate();
  const { SubmitFormJson } = useFetchPost();

  const { validationshema, initialValues } = useValidateForm(
    dataStepActive.form
  );

  const {
    handleSubmit,
    formState: { errors },
    control,
    watch,
    setValue,
    register,
  } = useForm({
    defaultValues: initialValues,
    resolver: yupResolver(validationshema),
  });

  const formValues = watch();

  const onSubmit = handleSubmit(() => {
    const paymentTypes = ["agencyPayment", "beneficiaryPayment"];
    if (activeStep === dataSteps.length - 1) {
      setLoader(true);
      for (let paymentType of paymentTypes) {
        if (
          parseInt(stateGlobalSeattlePayment[paymentType].senderAmount) === 0
        ) {
          continue;
        }
        SubmitFormJson(
          stateGlobalSeattlePayment[paymentType],
          "PaymentMethod/Monoova/Pay"
        )
          .then((response) => {
            if (!response.ok) {
              setModalAlert({
                openModal: true,
                variant: "filled",
                severity: "error",
                message: "Error al liquidar el pago",
              });
              setLoader(false);
              navigate("/Dashboard/SettlePaymentsList");
            }
            setLoader(false);
            setModalAlert({
              openModal: true,
              variant: "filled",
              severity: "success",
              message: "Pago liquidado con exito",
            });
            handleNext();
          })
          .catch((error) => {
            setLoader(false);
            setModalAlert({
              openModal: true,
              variant: "filled",
              severity: "error",
              message: "Error al liquidar el pago",
            });
            navigate("/Dashboard/SettlePaymentsList");
          });
      }
    } else {
      handleNext();
    }
  });

  const hasErrors = (state) => Object.keys(state.errors).length !== 0;

  return (
    <>
      <StyledMUIBoxContainerForm width={"50%"} mt={5}>
        <form noValidate onSubmit={(e) => e.preventDefault()}>
          <Grid container justifyContent="center" spacing={2} p={5}>
            {dataStepActive.form.map((item) => {
              switch (item.componenttype) {
                case "input":
                  return (
                    <InputAmountSettle
                      key={item.name}
                      errors={errors}
                      control={control}
                      item={item}
                      formValues={formValues}
                      setValue={setValue}
                    />
                  );
                case "inputcross":
                  return (
                    <CrossInputText
                      key={item.name}
                      errors={errors}
                      control={control}
                      item={item}
                      formValues={formValues}
                      setValue={setValue}
                    />
                  );
                case "select":
                  return (
                    <SelectPayOutMethod
                      key={item.nameId ? item.nameId : item.name}
                      errors={errors}
                      control={control}
                      item={item}
                      formValues={formValues}
                      setValue={setValue}
                      nameStep={dataStepActive.nameStep}
                    />
                  );
                case "check":
                  return (
                    <CrossCheck
                      key={item.name}
                      errors={errors}
                      control={control}
                      item={item}
                      register={register}
                    />
                  );
                case "inputfile":
                  return (
                    <CrossSelectFile
                      key={item.name}
                      errors={errors}
                      control={control}
                      item={item}
                      formValues={formValues}
                      setValue={setValue}
                    />
                  );

                case "NoShow":
                  return (
                    <Typography
                      key={item.name}
                      variant="h6"
                      gutterBottom
                      sx={{ display: "none" }}
                    />
                  );
                case "Component":
                  return (
                    <item.component
                      key={item.name}
                      errors={errors}
                      control={control}
                      item={item}
                      formValues={formValues}
                      setValue={setValue}
                    />
                  );

                default:
                  return null;
              }
            })}
          </Grid>
        </form>
        {stateGlobalSeattlePayment.agencyPayment.senderAmount != 0 &&
          dataStepActive.nameStep === "agency" && (
            <SettleAccountDataInput
              key={dataStepActive.nameStep}
              onSubmit={onSubmit}
              nameStep={dataStepActive.nameStep}
            />
          )}
        {stateGlobalSeattlePayment.beneficiaryPayment.senderAmount != 0 &&
          dataStepActive.nameStep === "beneficiary" && (
            <SettleAccountDataInput
              key={dataStepActive.nameStep}
              onSubmit={onSubmit}
              nameStep={dataStepActive.nameStep}
            />
          )}
        {activeStep === dataSteps.length - 1 && (
          <Grid container justifyContent={"center"} alignItems={"center"}>
            <Grid item>
              <AboutSettlePayment />
            </Grid>
          </Grid>
        )}
        <Grid
          container
          //justifyContent={activeStep === 2 ? "end" : "space-between"}
          justifyContent={"space-between"}
          alignItems={"center"}
          mt={5}
        >
          <Grid item>
            <StyledMUIButton
              backgroundcolor={theme.Palette.secondary}
              disabled={activeStep === 0}
              onClick={handleBack}
            >
              Regresar
            </StyledMUIButton>
          </Grid>
          <Grid item>
            <StyledMUIButton
              backgroundcolor={theme.Palette.secondary}
              onClick={onSubmit}
              disabled={
                (hasErrors(stateGlobalSeattlePayment.agencyPayment) &&
                  dataStepActive.nameStep === "agency") ||
                (hasErrors(stateGlobalSeattlePayment.beneficiaryPayment) &&
                  dataStepActive.nameStep === "beneficiary")
              }
            >
              {activeStep === dataSteps.length - 1 ? "Liquidar" : "Siguiente"}
            </StyledMUIButton>
          </Grid>
        </Grid>
      </StyledMUIBoxContainerForm>
    </>
  );
};
