import React from "react";
import { PayUpList } from "../Components/PayUpList";

export const PayUpAdmin = () => {
  return (
    <>
      <PayUpList />
    </>
  );
};
