import React, { useEffect } from "react";
import { StyledMUIButtonIcon } from "../../../StyledCrossComponents";
import { Grid } from "@mui/material";
import {
  useStorePaymentStep,
  useStoreSeattlePaymentStep,
} from "../../../store";

export const SendPaymentLink = ({ setValue }) => {
  const store = useStorePaymentStep();

  const handleClick = () => {
    setValue("paymentOptionId", 2, true);
  };

  return (
    <Grid item xs={12}>
      <StyledMUIButtonIcon
        variant="contained"
        fullWidth
        type="submit"
        onClick={handleClick}
      >
        Enviar link de pago PSE
      </StyledMUIButtonIcon>
    </Grid>
  );
};
