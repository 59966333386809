import React from "react";
import { RecoverPasswordForm } from "../Components/RecoverPasswordForm";
import { BodyContainer } from "../Styled-components";
import { Grid } from "@mui/material";

export const RecoverPassword = () => {
  return (
    <>
      <Grid container justifyContent={"center"} mt={30}>
        <RecoverPasswordForm />
      </Grid>
    </>
  );
};
