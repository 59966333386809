import React from "react";
import {
  StyledMUIBoxContainerForm,
  StyledMUIButtonIcon,
} from "../../../StyledCrossComponents";
import { useValidateForm } from "../../../Hooks/useValidateForm";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { Grid, Typography } from "@mui/material";
import { CrossInputText, CrossSelectList } from "../../CrossComponents";
import { useFetchPost } from "../../../Hooks/useFetchPost";
import { useUserStore } from "../../../store";

export const BeneficiaryForm = ({ beneficiaries, setData, setShow }) => {
  const { user } = useUserStore();
  const formBeneficiary = getFormBeneficiary(user);
  const { validationshema, initialValues } = useValidateForm(formBeneficiary);
  const {
    handleSubmit,
    formState: { errors },
    control,
    watch,
    setValue,
    register,
  } = useForm({
    defaultValues: initialValues,
    resolver: yupResolver(validationshema),
  });

  const { SubmitFormJson } = useFetchPost();
  const formValues = watch();
  const onSubmit = (data) => {
    SubmitFormJson(data, "Beneficiary").then((responseData) => {
      if (!responseData.ok) {
        return;
      }
      responseData.json().then((data) => {
        setData([...beneficiaries, data]);
        setShow(false);
      });
    });
  };

  return (
    <>
      <Typography variant="h6" gutterBottom textAlign={"center"}>
        Crear Provedor
      </Typography>
      <StyledMUIBoxContainerForm width={"100%"}>
        <form noValidate onSubmit={handleSubmit(onSubmit)}>
          <Grid container justifyContent={"right"} spacing={2} p={5}>
            {formBeneficiary.map((item) => {
              switch (item.componenttype) {
                case "input":
                  return (
                    <CrossInputText
                      key={item.name}
                      errors={errors}
                      control={control}
                      item={item}
                    />
                  );
                case "select":
                  return (
                    <CrossSelectList
                      key={item.nameId ? item.nameId : item.name}
                      errors={errors}
                      control={control}
                      item={item}
                      formValues={formValues}
                      setValue={setValue}
                    />
                  );
                default:
                  return null;
              }
            })}

            <StyledMUIButtonIcon
              variant="contained"
              sx={{ marginTop: "10px", marginRight: "10px" }}
              type="submit"
            >
              Agregar
            </StyledMUIButtonIcon>
            <StyledMUIButtonIcon
              variant="contained"
              sx={{ marginTop: "10px" }}
              onClick={() => setShow(false)}
            >
              Regresar
            </StyledMUIButtonIcon>
          </Grid>
        </form>
      </StyledMUIBoxContainerForm>
    </>
  );
};

const getFormBeneficiary = (user) => [
  {
    componenttype: "input",
    label: "Nombre del proveedor",
    type: "text",
    size: "small",
    name: "beneficiaryFirstName",
    value: "",
    margin: "normal",
    xs: 12,
    validations: [
      {
        type: "required",
      },
    ],
  },
  {
    componenttype: "input",
    label: "Dirección",
    type: "text",
    size: "small",
    name: "beneficiaryAddress",
    value: "",
    margin: "normal",
    xs: 12,
    validations: [
      {
        type: "required",
      },
    ],
  },
  {
    componenttype: "select",
    label: "País ",
    size: "small",
    name: "countryId",
    nameId: "beneficiaryCountryId",
    description: "countryName",
    value: "0",
    margin: "normal",
    xs: 12,
    apiEndPoint: "Country",
    validations: [
      {
        type: "required",
      },
    ],
  },
  {
    componenttype: "select",
    label: "Ciudad",
    type: "select",
    name: "cityId",
    nameId: "beneficiaryCityId",
    description: "nameCity",
    value: "0",
    size: "small",
    margin: "normal",
    apiEndPoint: "City/GetCitiesByCountryId/*",
    anidado: true,
    param: { name: "beneficiaryCountryId" },
    xs: 12,
    validations: [
      {
        type: "required",
      },
    ],
  },
  {
    componenttype: "input",
    label: "Correo electronico",
    type: "email",
    size: "small",
    name: "beneficiaryMail",
    value: "",
    margin: "normal",
    xs: 12,
    validations: [
      {
        type: "required",
      },
    ],
  },
  {
    componenttype: "input",
    label: "Número de teléfono",
    type: "text",
    size: "small",
    name: "beneficiaryPhoneNumber",
    value: "",
    margin: "normal",
    xs: 12,
    validations: [
      {
        type: "required",
      },
    ],
  },
  {
    componenttype: "NoShow",
    name: "agencyId",
    value: user.agency.id,
    validations: [],
    xs: 12,
  },
  {
    componenttype: "NoShow",
    name: "userId",
    value: user.userId,
    validations: [],
    xs: 12,
  },
];
