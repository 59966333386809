import { Button, Grid, Modal, Typography } from "@mui/material";
import React from "react";
import { useStorageModal } from "../../store";

export const CrossModal = () => {
  const state = useStorageModal((state) => state.modalcross);
  const resetModalCross = useStorageModal((state) => state.resetModalCross);

  const handleClose = () => {
    resetModalCross();
  };

  return (
    <>
      <Modal open={state.openModal} onClose={handleClose}>
        <Grid container justifyContent="end" alignItems="center">
          {state.component && <state.component />}
        </Grid>
      </Modal>
    </>
  );
};
