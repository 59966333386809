import React from "react";
import { useFetchGet } from "../../../Hooks/useFetchGet";
import { StyledGridActionsCellItem } from "../../../StyledCrossComponents";
import { CrossDataGrid } from "../../CrossComponents";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import {
  useStorageModal,
  useStoreModalNotification,
  useUserStore,
} from "../../../store";
import { DetailedPaymentSummary } from "./DetailedPaymentSummary";
import { Chip } from "@mui/material";
import { AgencyDashboard } from "../../Home/Pages/AgencyDashboard";
import styled from "styled-components";

export const PaymentDataGrid = () => {
  const setModalAlert = useStoreModalNotification(
    (state) => state.setModalAlert
  );
  const { user } = useUserStore();
  const statePaymentlist = useFetchGet("State");

  const columns = [
    {
      headerName: "Acciones",
      field: "actions",
      type: "actions",
      renderCell: (params) => [
        <StyledGridActionsCellItem
          label={"Ver"}
          icon={<AddCircleIcon />}
          key={`read-${params.row.id}`}
          onClick={() => handleSelectRow(params.row)}
        />,
      ],
    },
    {
      field: "paymentReference",
      headerName: "Referencia de pago",
      headerAlign: "center",
      align: "center",
      flex: 2,
    },
    {
      field: "paymentDate",
      headerName: "Fecha de pago",
      headerAlign: "center",
      align: "center",
      flex: 1,
      renderCell: (params) => {
        const date = new Date(params.row.paymentDate);
        return date.toLocaleDateString(userLanguage);
      },
    },
    {
      field: "exchangeRate",
      headerName: "Tasa de cambio",
      headerAlign: "center",
      align: "center",
      flex: 1,
      renderCell: (params) => {
        return (
          <>
            {params.row.exchangeRate.toLocaleString(userLanguage, {
              style: "currency",
              currency: "COP",
            })}
          </>
        );
      },
    },
    {
      field: "estimatePayment",
      headerName: "Monto de pago COP",
      headerAlign: "center",
      align: "center",
      flex: 1,
      renderCell: (params) => {
        return (
          <>
            {params.row.estimatePayment.toLocaleString(userLanguage, {
              style: "currency",
              currency: "COP",
            })}
          </>
        );
      },
    },
    {
      field: "targetAmount",
      headerName: "Monto de pago",
      headerAlign: "center",
      align: "center",
      flex: 1,
      renderCell: (params) => {
        return (
          <>
            {params.row.targetAmount.toLocaleString(userLanguage, {
              style: "currency",
              currency: params.row.currency || "AUD",
            })}
          </>
        );
      },
    },
    {
      field: "stateName",
      headerName: "Estado del pago",
      headerAlign: "center",
      align: "center",
      flex: 1,
      renderCell: (params) => {
        switch (params.row.stateId) {
          case 1:
            return (
              <>
                <StyledChip
                  color="error"
                  label={params.row.stateName}
                  size="small"
                />
              </>
            );
          case 2:
            return (
              <>
                <StyledChip
                  color="warning"
                  label={params.row.stateName}
                  size="small"
                />
              </>
            );
          case 3:
            return (
              <>
                <StyledChip
                  color="warning"
                  label={params.row.stateName}
                  size="small"
                />
              </>
            );
          case 4:
            return (
              <>
                <StyledChip
                  color="success"
                  label={params.row.stateName}
                  size="small"
                />
              </>
            );
          default:
            return (
              <>
                <StyledChip
                  color="success"
                  label={params.row.stateName}
                  size="small"
                />
              </>
            );
        }
      },
    },
  ];
  const setModalCross = useStorageModal((state) => state.setModalCross);
  const handleSelectRow = (row) => {
    setModalCross({
      openModal: true,
      component: () => <DetailedPaymentSummary rowData={row} />,
      setValue: null,
      formValues: null,
    });
  };

  const { data } = useFetchGet(
    `Payments/GetPaymentsByAgencyId/${user.agency.id}`
  );
  return (
    <>
      <AgencyDashboard />
      <CrossDataGrid
        columns={columns}
        data={data}
        rowId={"paymentReference"}
        sortModel={sortModel}
        title={"Lista de pagos realizados:"}
      />
    </>
  );
};

const userLanguage = navigator.language || navigator.userLanguage;

const sortModel = [
  {
    field: "paymentDate",
    sort: "desc",
  },
];

const StyledChip = styled(Chip)`
  width: 180px;
`;
