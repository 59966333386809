import * as React from "react";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Typography from "@mui/material/Typography";
import { Grid } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { PaymentStepForm } from "./PaymentStepForm";
import { useUserStore } from "../../../store";

export default function PaymentSteps() {
  const { user } = useUserStore();
  const [activeStep, setActiveStep] = React.useState(0);
  const [skipped, setSkipped] = React.useState(new Set());
  const navigate = useNavigate();
  const dataSteps = getDataSteps(user);

  const isStepOptional = (step) => {
    return step === 5;
  };

  const isStepSkipped = (step) => {
    return skipped.has(step);
  };

  const handleNext = () => {
    let newSkipped = skipped;

    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStep);
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped(newSkipped);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  return (
    <Grid container alignItems="center" justifyContent="center">
      <Box mt={10} sx={{ width: "100%" }}>
        <Stepper activeStep={activeStep} alternativeLabel>
          {dataSteps.map((item, index) => {
            const stepProps = {};
            const labelProps = {};
            if (isStepOptional(index)) {
              labelProps.optional = (
                <Typography variant="caption">Optional</Typography>
              );
            }
            if (isStepSkipped(index)) {
              stepProps.completed = false;
            }
            return (
              <Step key={item.label} {...stepProps}>
                <StepLabel {...labelProps}>{item.label}</StepLabel>
              </Step>
            );
          })}
        </Stepper>
        {activeStep === dataSteps.length ? (
          handleReset()
        ) : (
          <React.Fragment>
            <PaymentStepForm
              handleNext={handleNext}
              handleBack={handleBack}
              activeStep={activeStep}
              dataStepActive={dataSteps[activeStep]}
              dataSteps={dataSteps}
            />
          </React.Fragment>
        )}
      </Box>
    </Grid>
    //</Grid>
  );
}

const getDataSteps = (user) => [
  {
    label: "Tipo de pago",
    step: 0,
    boxWidth: "100%",
    validations: [],
    form: [
      {
        componenttype: "NoShow",
        name: "exchangeRateId",
        value: 0,
        validations: [],
        xs: 12,
      },
      {
        componenttype: "NoShow",
        name: "userId",
        value: user.userId,
        validations: [],
        xs: 12,
      },
      {
        componenttype: "NoShow",
        name: "userName",
        value: user.email,
        validations: [],
        xs: 12,
      },
      {
        componenttype: "NoShow",
        name: "stateId",
        value: 1,
        validations: [],
        xs: 12,
      },
      {
        componenttype: "NoShow",
        name: "agencyId",
        value: user.agency.id,
        validations: [],
        xs: 12,
      },
      {
        componenttype: "NoShow",
        name: "paymentOptionId",
        value: 0,
        validations: [],
        xs: 12,
      },
      {
        componenttype: "exchangeRate",
        name: "exchangeRate",
        value: 0,
        validations: [],
        xs: 12,
      },
      {
        componenttype: "estimatePayment",
        name: "estimatePayment",
        value: 0,
        validations: [],
        xs: 12,
      },
      {
        componenttype: "offeringInsuranceName",
        name: "offeringInsuranceName",
        value: "n/a",
        validations: [],
        xs: 12,
      },
      {
        componenttype: "offeringInsuranceValue",
        name: "offeringInsuranceValue",
        value: 0,
        validations: [],
        xs: 12,
      },
      {
        componenttype: "offeringInsuranceDateStart",
        name: "offeringInsuranceDateStart",
        value: "1900-01-01",
        validations: [],
        xs: 12,
      },
      {
        componenttype: "offeringInsuranceDateEnd",
        name: "offeringInsuranceDateEnd",
        value: "1900-01-01",
        validations: [],
        xs: 12,
      },
      {
        componenttype: "offeringId",
        name: "offeringId",
        value: 0,
        validations: [],
        xs: 12,
      },
      {
        componenttype: "estimatePaymentWithInsurance",
        name: "estimatePaymentWithInsurance",
        value: 0,
        validations: [],
        xs: 12,
      },
      {
        componenttype: "tagetAmountWithInsurance",
        name: "tagetAmountWithInsurance",
        value: 0,
        validations: [],
        xs: 12,
      },
      {
        componenttype: "select",
        label: "Tipo de pago",
        type: "select",
        name: "id",
        nameId: "paymentTypeId",
        description: "type",
        value: 2,
        size: "small",
        margin: "normal",
        xs: 12,
        apiEndPoint: "PaymentType",
        validations: [
          {
            type: "required",
          },
        ],
        disabled: true,
      },
      {
        componenttype: "select",
        label: "País Origen",
        size: "small",
        name: "countryId",
        nameId: "PaymentCountryOrigenId",
        description: "countryName",
        value: 1,
        margin: "normal",
        xs: 6,
        apiEndPoint: "Country",
        validations: [
          {
            type: "required",
          },
        ],
        disabled: true,
      },
      {
        componenttype: "select",
        label: "País Destino",
        size: "small",
        name: "countryId",
        nameId: "PaymentCountryDestinyId",
        description: "countryName",
        value: 2,
        margin: "normal",
        xs: 6,
        apiEndPoint: "Country",
        validations: [
          {
            type: "required",
          },
        ],
        disabled: true,
      },
      {
        componenttype: "targetAmount",
        label: "Monto a recibir en destino",
        type: "number",
        size: "small",
        name: "targetAmount",
        value: 0,
        margin: "normal",
        xs: 12,
        validations: [
          {
            type: "required",
          },
        ],
      },
    ],
  },
  {
    label: "Datos del proveedor educativo",
    step: 1,
    validations: [],
    boxWidth: "100%",
    form: [
      {
        componenttype: "searchInput",
        label: "Nombre del proveedor educativo",
        type: "text",
        size: "small",
        name: "beneficiaryFirstName",
        value: "",
        margin: "normal",
        endpoint: "provider/*",
        buttonName: "Proveedor",
        xs: 8,
        disabled: true,
        validations: [
          {
            type: "required",
          },
        ],
      },

      {
        componenttype: "input",
        label: "Dirección",
        type: "text",
        size: "small",
        name: "beneficiaryAdress",
        value: "",
        margin: "normal",
        xs: 12,
        disabled: true,
        validations: [
          {
            type: "required",
          },
          // {
          //   type: "pattern",
          //   pattern: /^[a-zA-ZÀ-ÿ\s]{1,40}$/,
          // },
        ],
      },
      {
        componenttype: "select",
        label: "País ",
        size: "small",
        name: "countryId",
        nameId: "beneficiaryCountryId",
        description: "countryName",
        value: "0",
        margin: "normal",
        xs: 12,
        apiEndPoint: "Country",
        disabled: true,
        validations: [
          {
            type: "required",
          },
        ],
      },
      {
        componenttype: "select",
        label: "Ciudad",
        type: "select",
        name: "cityId",
        nameId: "beneficiaryCityId",
        description: "nameCity",
        value: "0",
        size: "small",
        margin: "normal",
        apiEndPoint: "City/GetCitiesByCountryId/*",
        anidado: true,
        param: { name: "beneficiaryCountryId" },
        xs: 12,
        disabled: true,
        validations: [
          {
            type: "required",
          },
        ],
      },
      {
        componenttype: "input",
        label: "Correo electronico",
        type: "email",
        size: "small",
        name: "beneficiaryMail",
        value: "",
        margin: "normal",
        xs: 12,
        disabled: true,
        validations: [
          {
            type: "required",
          },
        ],
      },
      {
        componenttype: "input",
        label: "Número de teléfono",
        type: "text",
        size: "small",
        name: "beneficiaryPhoneNumber",
        value: "",
        margin: "normal",
        xs: 12,
        disabled: true,
        validations: [
          {
            type: "required",
          },
          // {
          //   type: "pattern",
          //   pattern: /^[0-9]{8,10}$/,
          // },
        ],
      },
      {
        componenttype: "inputfile",
        label: "Adjuntar recibo de pago",
        type: "file",
        name: "myFile",
        value: "",
        size: "small",
        margin: "normal",
        xs: 12,
        apiEndPoint: "Country/*",
        param: { name: "countryAgencyId" },
        validations: [
          {
            type: "required",
          },
        ],
      },
      {
        componenttype: "NoShow",
        label: "Nombre del Archivo",
        type: "text",
        name: "nameTypeLegalBusinessDocument",
        value: "",
        size: "small",
        margin: "normal",
        xs: 12,
        validations: [],
      },
    ],
  },
  {
    label: "Datos del estudiante",
    step: 2,
    validations: [],
    boxWidth: "100%",
    form: [
      {
        componenttype: "input",
        label: "Nombre",
        type: "text",
        size: "small",
        name: "StudentName",
        value: "",
        margin: "normal",
        xs: 12,
        disabled: false,
        validations: [
          {
            type: "required",
          },
        ],
      },
      {
        componenttype: "input",
        label: "Apellidos",
        type: "text",
        size: "small",
        name: "StudentLastName",
        value: "",
        margin: "normal",
        xs: 12,
        disabled: false,
        validations: [
          {
            type: "required",
          },
        ],
      },
      {
        componenttype: "datePicker",
        label: "Fecha de nacimiento",
        type: "date",
        size: "small",
        name: "StudentBirthDate",
        value: "",
        margin: "normal",
        xs: 12,
        disabled: false,
        validations: [
          {
            type: "required",
          },
        ],
      },
      {
        componenttype: "input",
        label: "Número telefónico",
        type: "number",
        size: "small",
        name: "StudentPhoneNumber",
        value: "",
        margin: "normal",
        xs: 12,
        disabled: false,
        validations: [
          {
            type: "required",
          },
        ],
      },
      {
        componenttype: "select",
        label: "País de residencia",
        size: "small",
        name: "countryId",
        nameId: "StudentCountryId",
        description: "countryName",
        value: "0",
        margin: "normal",
        xs: 6,
        apiEndPoint: "Country",
        validations: [
          {
            type: "required",
          },
        ],
      },
      {
        componenttype: "select",
        label: "Ciudad de residencia",
        size: "small",
        name: "cityId",
        nameId: "StudentCityId",
        description: "nameCity",
        value: "0",
        margin: "normal",
        xs: 6,
        apiEndPoint: "City/GetCitiesByCountryId/*",
        anidado: true,
        param: { name: "StudentCountryId" },
        validations: [
          {
            type: "required",
          },
        ],
      },
      {
        componenttype: "input",
        label: "Dirección",
        type: "text",
        size: "small",
        name: "StudentAddress",
        value: "",
        margin: "normal",
        xs: 12,
        disabled: false,
        validations: [
          {
            type: "required",
          },
          {
            type: "pattern",
            value: /^[a-zA-Z0-9\s]{1,40}$/,
            message: "Solo se permiten letras y números",
          },
        ],
      },
      {
        componenttype: "input",
        label: "Correo electrónico",
        type: "text",
        size: "small",
        name: "StudentEmail",
        value: "",
        margin: "normal",
        xs: 12,
        disabled: false,
        validations: [
          {
            type: "required",
          },
        ],
      },
      {
        componenttype: "select",
        type: "select",
        label: "Tipo de identificación",
        size: "small",
        name: "id",
        filter: [1, 3, 4, 5],
        nameId: "StudentIdentificationTypeId",
        description: "description",
        value: "0",
        margin: "normal",
        xs: 12,
        apiEndPoint: "IdentificationType",
        validations: [
          {
            type: "required",
          },
        ],
      },
      {
        componenttype: "input",
        label: "Número de identificación",
        type: "text",
        size: "small",
        name: "StudentIdentificationNumber",
        value: "",
        margin: "normal",
        xs: 12,
        disabled: false,
        validations: [
          {
            type: "required",
          },
        ],
      },
      {
        componenttype: "select",
        label: "Pais de expedición del Pasaporte",
        size: "small",
        name: "countryId",
        nameId: "PassportStudentCountryId",
        description: "countryName",
        value: "0",
        margin: "normal",
        xs: 12,
        apiEndPoint: "Country",
        validations: [
          {
            type: "required",
          },
        ],
      },
    ],
  },
  {
    label: "Informacion del pagador",
    step: 1,
    validations: [],
    boxWidth: "100%",
    form: [
      {
        componenttype: "input",
        label: "Nombre",
        type: "text",
        size: "small",
        name: "payerFirstName",
        value: "",
        margin: "normal",
        xs: 12,
        validations: [
          {
            type: "required",
          },
        ],
      },
      {
        componenttype: "input",
        label: "Apellidos",
        type: "text",
        size: "small",
        name: "payerLasttName",
        value: "",
        margin: "normal",
        xs: 12,
        validations: [
          {
            type: "required",
          },
        ],
      },
      {
        componenttype: "input",
        label: "Dirección",
        type: "text",
        size: "small",
        name: "payerAdress",
        value: "",
        margin: "normal",
        xs: 12,
        validations: [
          {
            type: "required",
          },
          {
            type: "pattern",
            value: /^[a-zA-Z0-9\s]{1,64}$/,
            message:
              "Solo se permiten letras o números y la longitud debe ser menor a 64 caracteres",
          },
        ],
      },
      {
        componenttype: "input",
        label: "Número de celular",
        type: "number",
        size: "small",
        name: "payerPhoneNumber",
        value: 0,
        margin: "normal",
        xs: 12,
        validations: [
          {
            type: "required",
          },
          {
            type: "maxDigits",
            message: "El número debe tener máximo 10 digitos",
          },
          {
            type: "min",
            message: "El número de telefono debe tener minimo 10 digitos",
            valueMin: 10,
          },
        ],
      },
      {
        componenttype: "input",
        label: "Correo Electrónico",
        type: "email",
        size: "small",
        name: "payerEmail",
        value: "",
        margin: "normal",
        xs: 12,
        validations: [
          {
            type: "required",
          },
        ],
      },
      {
        componenttype: "select",
        type: "select",
        label: "Tipo de identificación",
        size: "small",
        name: "id",
        filter: [1],
        nameId: "PayerIdentificationTypeId",
        description: "description",
        value: "0",
        margin: "normal",
        xs: 12,
        apiEndPoint: "IdentificationType",
        validations: [
          {
            type: "required",
          },
        ],
      },
      {
        componenttype: "input",
        label: "Numero de identificación",
        type: "number",
        size: "small",
        name: "PayerIdentificationNumber",
        value: "",
        margin: "normal",
        xs: 12,
        validations: [
          {
            type: "required",
          },
          {
            type: "min",
            message: "El número de identificación debe tener minimo 7 digitos",
            valueMin: 7,
          },
        ],
      },
    ],
  },
  {
    label: "Medios de pago",
    step: 3,
    validations: [],
    boxWidth: "100%",
    form: [
      {
        show: false,
        componenttype: "select",
        label: "Tipo de persona",
        type: "select",
        name: "id",
        nameId: "typePersonId",
        description: "typePersonName",
        value: "0",
        size: "small",
        margin: "normal",
        xs: 12,
        sx: { display: "none" },
        apiEndPoint: "TypePerson",
        validations: [
          {
            type: "required",
          },
        ],
      },
      {
        show: false,
        componenttype: "select",
        label: "Banco",
        type: "select",
        name: "codBnak",
        nameId: "codBank",
        description: "nameBank",
        value: "0",
        size: "small",
        margin: "normal",
        xs: 12,
        apiEndPoint: "Bank",
        validations: [
          {
            type: "required",
          },
        ],
      },
      {
        componenttype: "sendPaymentLink",
        name: "sendPaymentLink",
        value: "",
        validations: [],
      },
      {
        componenttype: "sendOthersPaymentsMethods",
        name: "sendOthersPaymentsMethods",
        value: "",
        validations: [],
      },
    ],
  },
];
