import React from "react";
import { useStoreModalNotification } from "../store";
import { sub } from "date-fns";

export const useFetchPut = () => {
  //   const [dataResp, setDataResp] = useState({});
  //   const [error, setError] = useState(false);
  const baseUrl = import.meta.env.VITE_REACT_APP_BASE_URL;
  const setModalAlert = useStoreModalNotification(
    (state) => state.setModalAlert
  );
  const SubmitUpdate = async (dataRequest, apiController) => {
    try {
      const response = await fetch(`${baseUrl}${apiController}`, {
        method: "PUT",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(dataRequest),
      });

      if (response.status === 401) {
        setModalAlert({
          openModal: true,
          message: "Su sesión ha expirado, por favor vuelva a iniciar sesión",
          severity: "error",
          variant: "filled",
        });
        window.location.href = "/";
        return;
      }
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      return response;
    } catch (error) {
      setModalAlert({
        openModal: true,
        message: "Error en la solicitud",
        severity: "error",
        variant: "filled",
      });
    }
  };

  return { SubmitUpdate };
};
