import React from "react";
import { StyledGridActionsCellItem } from "../../../StyledCrossComponents";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import { CrossDataGrid } from "../../CrossComponents";
import {
  useStorePaymentStep,
  useStoreSeattlePaymentStep,
  useUserStore,
} from "../../../store";
import { useFetchGet } from "../../../Hooks/useFetchGet";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { Chip } from "@mui/material";

export const SettlePaymentsDataList = () => {
  const { user } = useUserStore();
  const reset = useStoreSeattlePaymentStep(
    (state) => state.resetSeattlePaymentStep
  );
  // Luego, cuando quieras restablecer el estado:
  reset();
  const columns = [
    {
      headerName: "Acciones",
      field: "actions",
      type: "actions",
      renderCell: (params) => [
        <StyledGridActionsCellItem
          label={"Ver"}
          icon={<AddCircleIcon />}
          key={`read-${params.row.id}`}
          onClick={() => handleSelectRow(params.row)}
        />,
      ],
    },
    {
      field: "paymentReference",
      headerName: "Referencia de pago",
      headerAlign: "center",
      align: "center",
      flex: 2,
    },
    {
      field: "paymentDate",
      headerName: "Fecha de pago",
      headerAlign: "center",
      align: "center",
      flex: 1,
      renderCell: (params) => {
        const date = new Date(params.row.paymentDate);
        return date.toLocaleDateString(userLanguage);
      },
    },
    {
      field: "exchangeRate",
      headerName: "Tasa de cambio",
      headerAlign: "center",
      align: "center",
      flex: 1,
      renderCell: (params) => {
        return (
          <>
            {params.row.exchangeRate.toLocaleString(userLanguage, {
              style: "currency",
              currency: "COP",
            })}
          </>
        );
      },
    },
    {
      field: "estimatePayment",
      headerName: "Monto de pago COP",
      headerAlign: "center",
      align: "center",
      flex: 1,
      renderCell: (params) => {
        return (
          <>
            {params.row.estimatePayment.toLocaleString(userLanguage, {
              style: "currency",
              currency: "COP",
            })}
          </>
        );
      },
    },
    {
      field: "targetAmount",
      headerName: "Monto de pago",
      headerAlign: "center",
      align: "center",
      flex: 1,
      renderCell: (params) => {
        return (
          <>
            {params.row.targetAmount.toLocaleString(userLanguage, {
              style: "currency",
              currency: params.row.currency || "AUD",
            })}
          </>
        );
      },
    },
    {
      field: "stateName",
      headerName: "Estado del pago",
      headerAlign: "center",
      align: "center",
      flex: 1,
      renderCell: (params) => {
        switch (params.row.stateId) {
          case 1:
            return (
              <>
                <StyledChip
                  color="error"
                  label={params.row.stateName}
                  size="small"
                />
              </>
            );
          case 2:
            return (
              <>
                <StyledChip
                  color="warning"
                  label={params.row.stateName}
                  size="small"
                />
              </>
            );
          case 3:
            return (
              <>
                <StyledChip
                  color="warning"
                  label={params.row.stateName}
                  size="small"
                />
              </>
            );
          case 4:
            return (
              <>
                <StyledChip
                  color="success"
                  label={params.row.stateName}
                  size="small"
                />
              </>
            );
          default:
            return (
              <>
                <StyledChip
                  color="success"
                  label={params.row.stateName}
                  size="small"
                />
              </>
            );
        }
      },
    },
  ];

  const navigate = useNavigate();
  const handleSelectRow = (row) => {
    navigate("/Dashboard/DetailedPaymentSettle", { state: { rowData: row } });
  };

  const { data } = useFetchGet(
    `payments/GetPaymentsByAgencyIdAndState/${user.agency.id}/2`
  );

  return (
    <>
      <CrossDataGrid
        title={"Lista de Pagos para liquidar:"}
        columns={columns}
        data={data}
        rowId={"paymentReference"}
        sortModel={sortModel}
      />
    </>
  );
};
const userLanguage = navigator.language || navigator.userLanguage;
const sortModel = [
  {
    field: "paymentDate",
    sort: "desc",
  },
];

const StyledChip = styled(Chip)`
  width: 180px;
`;
