import React, { useState } from "react";
import { useStorageModal, useUserStore } from "../../../store";
import { useFetchGet } from "../../../Hooks/useFetchGet";
import { Box } from "@mui/material";
import { CrossDataGrid } from "../../CrossComponents";
import {
  StyledGridActionsCellItem,
  StyledMUIButtonIcon,
} from "../../../StyledCrossComponents";
import AddCircleIcon from "@mui/icons-material/AddCircle";

export const SearchPerBeneficiaries = () => {
  const { user } = useUserStore();
  const state = useStorageModal((state) => state.modalcross);
  const resetModalCross = useStorageModal((state) => state.resetModalCross);
  const { data, setData, error } = useFetchGet(
    `PerBeneficiary/GetByUserId/${user.userId}`
  );

  // setBeneficiaries(data);

  const handleClose = () => {
    resetModalCross();
  };

  const handleShow = () => {
    setShow(true);
  };

  const columns = [
    {
      headerName: "Acciones",
      field: "actions",
      type: "actions",
      renderCell: (params) => [
        <StyledGridActionsCellItem
          label={"Ver"}
          icon={<AddCircleIcon />}
          key={`read-${params.row.id}`}
          onClick={() => handleSelectRow(params.row)}
        />,
      ],
      headerAlign: "center",
    },
    {
      field: "fullName",
      headerName: "Nombre del beneficiario",
      headerAlign: "center",
      align: "center",
      flex: 1,
    },
  ];

  const handleSelectRow = (row) => {
    console.log(row);
    state.setValue("BeneficiaryFirstName", row.firstName, true);
    state.setValue("BeneficiaryLastName", row.lastName, true);
    state.setValue("BeneficiaryAddress", row.address, true);
    state.setValue("BeneficiaryId", row.id, true);
    state.setValue("beneficiaryCountryId", row.countryId, true);
    state.setValue("beneficiaryCityId", row.cityId, true);
    state.setValue("BeneficiaryMail", row.mail, true);
    state.setValue("BeneficiaryPhoneNumber", row.phoneNumber, true);
    state.setValue(
      "BeneficiaryIdentificationTypeId",
      row.identificationTypeId,
      true
    );
    state.setValue(
      "BeneficiaryIdentificationNumber",
      row.identificationNumber,
      true
    );
    resetModalCross();
  };

  return (
    <Box
      sx={{
        width: { xs: "100%", sm: "50%" },
        backgroundColor: "white",
        padding: "20px",
        height: "100vh",
      }}
    >
      <CrossDataGrid
        title="Bnenficiarios"
        columns={columns}
        data={data}
        rowId="id"
      />

      <StyledMUIButtonIcon
        variant="contained"
        sx={{ float: "right" }}
        onClick={handleClose}
      >
        Cerrar
      </StyledMUIButtonIcon>
    </Box>
  );
};
