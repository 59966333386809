import React from "react";
import { PaymentListCopAud } from "../Components/PaymentListCopAud";
import { useParams } from "react-router-dom";

export const PaymentPerCopAud = () => {
  const { userId } = useParams();
  console.log(userId);
  return (
    <>
      <PaymentListCopAud userId={userId} />
    </>
  );
};
