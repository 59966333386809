import { SearchBeneficiary } from "../../Payments/Components/SearchBeneficiary";

export const getPaymentPerDataSteps = (
  user,
  fromPayment,
  ledgerAccountState
) => [
  {
    label: "Tipo de pago",
    step: 0,
    boxWidth: "100%",
    validations: [],
    form: [
      {
        componenttype: "NoShow",
        name: "userId",
        value: user.userId,
        validations: [],
        xs: 12,
      },
      {
        componenttype: "NoShow",
        name: "exchangeRateId",
        value: 0,
        validations: [],
        xs: 12,
      },

      {
        componenttype: "NoShow",
        name: "stateId",
        value: 1,
        validations: [],
        xs: 12,
      },
      {
        componenttype: "NoShow",
        name: "ledgerAccountNumber",
        value: user.ledgerAccount,
        validations: [],
        xs: 12,
      },
      {
        componenttype: "exchangeRatePersons",
        name: "exchangeRate",
        value: 0,
        validations: [],
        xs: 12,
      },
      {
        componenttype: "estimatePayment",
        name: "estimatePayment",
        value: 0,
        validations: [],
        xs: 12,
      },
      {
        componenttype: "select",
        label: "Tipo de pago",
        type: "select",
        name: "id",
        nameId: "paymentTypeId",
        description: "type",
        value: 1,
        size: "small",
        margin: "normal",
        xs: 12,
        apiEndPoint: "PaymentType",
        validations: [
          {
            type: "required",
          },
        ],
        disabled: true,
      },
      {
        componenttype: "select",
        label: "País Origen",
        size: "small",
        name: "countryId",
        nameId: "PaymentCountryOrigenId",
        description: "countryName",
        value: 1,
        filter: [1, 2],
        margin: "normal",
        xs: 6,
        apiEndPoint: "Country",
        validations: [
          {
            type: "required",
          },
        ],
      },
      {
        componenttype: "select",
        label: "País Destino",
        size: "small",
        name: "countryId",
        nameId: "PaymentCountryDestinyId",
        description: "countryName",
        value: 2,
        filter: [1, 2],
        margin: "normal",
        xs: 6,
        apiEndPoint: "Country",
        validations: [
          {
            type: "required",
          },
        ],
      },
      {
        componenttype: "targetAmount",
        label: "Monto a recibir en destino",
        type: "number",
        size: "small",
        name: "targetAmount",
        value: 0,
        margin: "normal",
        xs: 12,
        validations: [
          {
            type: "required",
          },
        ],
      },
    ],
  },
  {
    label: "Informacion del Beneficiario",
    step: 1,
    validations: [],
    boxWidth: "100%",
    form: [
      {
        componenttype: "searchInput",
        label: "Nombre beneficiario",
        type: "text",
        size: "small",
        name: "BeneficiaryFirstName",
        value: "",
        margin: "normal",
        endpoint: "provider/*",
        buttonName: "Beneficiario",
        xs: 12,
        //disabled: true,
        validations: [
          {
            type: "required",
          },
        ],
      },
      {
        componenttype: "input",
        label: "Apellidos",
        type: "text",
        size: "small",
        name: "BeneficiaryLastName",
        value: "",
        margin: "normal",
        xs: 12,
        validations: [
          {
            type: "required",
          },
        ],
      },
      {
        componenttype: "select",
        label: "País ",
        size: "small",
        name: "countryId",
        nameId: "beneficiaryCountryId",
        description: "countryName",
        value: "0",
        margin: "normal",
        xs: 12,
        apiEndPoint: "Country",
        validations: [
          {
            type: "required",
          },
        ],
      },
      {
        componenttype: "select",
        label: "Ciudad",
        type: "select",
        name: "cityId",
        nameId: "beneficiaryCityId",
        description: "nameCity",
        value: "0",
        size: "small",
        margin: "normal",
        apiEndPoint: "City/GetCitiesByCountryId/*",
        anidado: true,
        param: { name: "beneficiaryCountryId" },
        xs: 12,
        validations: [
          {
            type: "required",
          },
        ],
      },
      {
        componenttype: "input",
        label: "Dirección",
        type: "text",
        size: "small",
        name: "BeneficiaryAddress",
        value: "",
        margin: "normal",
        xs: 12,
        validations: [
          {
            type: "required",
          },
          {
            type: "pattern",
            value: /^[a-zA-Z0-9\s]{1,64}$/,
            message:
              "Solo se permiten letras o números y la longitud debe ser menor a 64 caracteres",
          },
        ],
      },
      {
        componenttype: "input",
        label: "Número de celular",
        type: "number",
        size: "small",
        name: "BeneficiaryPhoneNumber",
        value: "",
        margin: "normal",
        xs: 12,
        validations: [
          {
            type: "required",
          },
          {
            type: "maxDigits",
            message: "El número debe tener máximo 10 digitos",
          },
          {
            type: "min",
            message: "El número de telefono debe tener minimo 10 digitos",
            valueMin: 10,
          },
        ],
      },
      {
        componenttype: "input",
        label: "Correo Electrónico",
        type: "email",
        size: "small",
        name: "BeneficiaryMail",
        value: "",
        margin: "normal",
        xs: 12,
        validations: [
          {
            type: "required",
          },
        ],
      },
      {
        componenttype: "select",
        type: "select",
        label: "Tipo de identificación",
        size: "small",
        name: "id",
        filter: [1],
        nameId: "BeneficiaryIdentificationTypeId",
        description: "description",
        value: "0",
        margin: "normal",
        xs: 12,
        apiEndPoint: "IdentificationType",
        validations: [
          {
            type: "required",
          },
        ],
      },
      {
        componenttype: "input",
        label: "Numero de identificación",
        type: "number",
        size: "small",
        name: "BeneficiaryIdentificationNumber",
        value: "",
        margin: "normal",
        xs: 12,
        validations: [
          {
            type: "required",
          },
          {
            type: "min",
            message: "El número de identificación debe tener minimo 7 digitos",
            valueMin: 7,
          },
        ],
      },
    ],
  },
  {
    label: "Datos de la transferencia",
    step: 2,
    validations: [],
    boxWidth: "100%",
    form:
      fromPayment === "COP"
        ? [
            {
              componenttype: "select",
              type: "select",
              label: "Motivo Trasferencia",
              size: "small",
              name: "id",
              //filter: [1],
              nameId: "PaymentActionTypeId",
              description: "name",
              value: "0",
              margin: "normal",
              xs: 12,
              apiEndPoint: "PaymentActionType",
              validations: [
                {
                  type: "required",
                },
              ],
            },
            {
              componenttype: "input",
              label: "BSB",
              type: "text",
              size: "small",
              name: "Bsb",
              value: "",
              margin: "normal",
              xs: 12,
              validations: [
                {
                  type: "required",
                },
                {
                  type: "min",
                  message: "El número de BSB debe tener minimo 6 digitos",
                  valueMin: 6,
                },
              ],
            },
            // {
            //   componenttype: "input",
            //   label: "Nombre del banco",
            //   type: "text",
            //   size: "small",
            //   name: "BankName",
            //   value: "",
            //   margin: "normal",
            //   xs: 12,
            //   validations: [
            //     {
            //       type: "required",
            //     },
            //   ],
            // },
            {
              componenttype: "input",
              label: "Número de cuenta",
              type: "number",
              size: "small",
              name: "AccountNumber",
              value: "",
              margin: "normal",
              xs: 12,
              validations: [
                {
                  type: "required",
                },
                {
                  type: "min",
                  message:
                    "El número de identificación debe tener minimo 7 digitos",
                  valueMin: 7,
                },
              ],
            },
          ]
        : [
            {
              componenttype: "select",
              type: "select",
              label: "Motivo Trasferencia",
              size: "small",
              name: "id",
              //filter: [1],
              nameId: "PaymentActionTypeId",
              description: "name",
              value: "0",
              margin: "normal",
              xs: 12,
              apiEndPoint: "PaymentActionType",
              validations: [
                {
                  type: "required",
                },
              ],
            },
            {
              componenttype: "select",
              type: "select",
              label: "Tipo de cuenta bancaria",
              size: "small",
              name: "id",
              //filter: [1],
              nameId: "AccountTypeId",
              description: "name",
              value: "0",
              margin: "normal",
              xs: 12,
              apiEndPoint: "AccountType",
              validations: [
                {
                  type: "required",
                },
              ],
            },
            {
              show: false,
              componenttype: "select",
              label: "Banco",
              type: "select",
              name: "id",
              nameId: "BankId",
              description: "nameBank",
              value: "0",
              size: "small",
              margin: "normal",
              xs: 12,
              apiEndPoint: "Bank",
              validations: [
                {
                  type: "required",
                },
              ],
            },
            {
              componenttype: "input",
              label: "Número de cuenta",
              type: "number",
              size: "small",
              name: "AccountNumber",
              value: "",
              margin: "normal",
              xs: 12,
              validations: [
                {
                  type: "required",
                },
                {
                  type: "min",
                  message:
                    "El número de identificación debe tener minimo 7 digitos",
                  valueMin: 7,
                },
              ],
            },
          ],
  },
  {
    label: fromPayment === "COP" ? "Medios de pago" : "Enviar Pago",
    step: 3,
    validations: [],
    boxWidth: "100%",
    form:
      fromPayment === "COP"
        ? [
            {
              show: false,
              componenttype: "select",
              label: "Tipo de persona",
              type: "select",
              name: "id",
              nameId: "typePersonId",
              description: "typePersonName",
              value: 2,
              size: "small",
              margin: "normal",
              xs: 12,
              sx: { display: "none" },
              apiEndPoint: "TypePerson",
              validations: [
                {
                  type: "required",
                },
              ],
            },
            {
              show: false,
              componenttype: "select",
              label: "Banco",
              type: "select",
              name: "codBnak",
              nameId: "BankCod",
              description: "nameBank",
              value: "0",
              size: "small",
              margin: "normal",
              xs: 12,
              apiEndPoint: "Bank",
              validations: [
                {
                  type: "required",
                },
              ],
            },
            {
              componenttype: "sendPaymentLink",
              name: "sendPaymentLink",
              value: "",
              label: "Enviar Pago PSE",
              validations: [],
            },
            // {
            //   componenttype: "sendOthersPaymentsMethods",
            //   name: "sendOthersPaymentsMethods",
            //   value: "",
            //   validations: [],
            // },
          ]
        : [
            {
              componenttype: "personPaymentSumary",
              name: "sendPaymentLink",
              label: "Enviar Pago",
              value: "",
              validations: [],
            },
            {
              componenttype: "sendPaymentLink",
              name: "sendPaymentLink",
              label: "Enviar Pago",
              value: "",
              validations: [],
            },
          ],
  },
];
