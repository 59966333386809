import { Button } from "@mui/material";
import styled from "styled-components";
import theme from "../theme";

export const StyledMUIButtonIcon = styled(Button)`
  && {
    background-color: ${theme.Palette.tertiary};
    border-radius: 25px;
    text-transform: none;
    margin-top: ${(props) => props.marginTop || "0px"};
    &:hover {
      background-color: ${theme.Palette.secondary};
    }
  }
`;
