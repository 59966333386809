import { useUserStore } from "../../../store";

export const GetElementsCreateUser = () => {
  const { user } = useUserStore();
  return [
    {
      componenttype: "NoShow",
      name: "agencyId",
      value: user.agency.id,
      validations: [],
      xs: 12,
    },
    {
      componenttype: "input",
      label: "Nombre",
      type: "text",
      name: "firstName",
      value: "",
      size: "small",
      margin: "normal",
      xs: 12,
      validations: [
        {
          type: "required",
        },
      ],
    },
    {
      componenttype: "input",
      label: "Apellido",
      type: "text",
      name: "lastName",
      value: "",
      size: "small",
      margin: "normal",
      xs: 12,
      validations: [
        {
          type: "required",
        },
      ],
    },
    {
      componenttype: "input",
      label: "Email",
      type: "email",
      name: "email",
      value: "",
      size: "small",
      margin: "normal",
      xs: 12,
      validations: [
        {
          type: "required",
        },
        {
          type: "email",
          pattern: /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/,
        },
      ],
    },
    {
      componenttype: "input",
      label: "Contraseña",
      type: "password",
      name: "password",
      value: "",
      size: "small",
      margin: "normal",
      xs: 12,
      validations: [
        {
          type: "required",
        },
      ],
    },
    {
      componenttype: "input",
      label: "Confirmar contraseña",
      type: "password",
      name: "confirmPassword",
      value: "",
      size: "small",
      margin: "normal",
      xs: 12,
      validations: [
        {
          type: "required",
        },
        {
          type: "confirmPassword",
          name: "password",
        },
      ],
    },
    {
      componenttype: "select",
      label: "Rol",
      type: "select",
      name: "id",
      nameId: "rolId",
      description: "name",
      value: "0",
      size: "small",
      margin: "normal",
      apiEndPoint: "Account/GetRoles",
      xs: 12,
      filter: [
        "e734f93f-fb6a-439d-803f-b7ef3a2e166a",
        "730a47de-283b-4e28-8d15-ea1df55e0263",
      ],
      validations: [
        {
          type: "required",
        },
      ],
    },
  ];
};
