import React from "react";
import { Footer, NavBar } from "../Components";
import { BodyContainer, StyledMUIGridContainer } from "../Styled-components";
import { CalculatorForm } from "../Components/CalculatorForm";
import usePageTracking from "../../../Hooks/usePageTracking";

export const Calcualtor = () => {
  usePageTracking("Calculadora");
  return (
    <>
      <NavBar />
      <BodyContainer>
        <CalculatorForm />
        <Footer />
      </BodyContainer>
    </>
  );
};
