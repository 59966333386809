import React, { useState } from "react";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import { Box, Button, Grid, Paper, Tooltip } from "@mui/material";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import { StyledHr } from "../../../StyledCrossComponents";
import { EnsuranceFormSearch } from "./EnsuranceFormSearch";
import { StyledListItemButton } from "../../../StyledCrossComponents/StyledListItemButton";
import { useStorageModal, useStorePaymentStep } from "../../../store";
import Divider from "@mui/material/Divider";
import CloseIcon from "@mui/icons-material/Close";

export const EnsuranceList = () => {
  const [value, setValue] = React.useState("INSURANCE_TYPE_SINGLE");
  const [data, setData] = useState([]);
  const handleChange = (event) => {
    setValue(event.target.value);
  };
  const { setModalCross, resetModalCross } = useStorageModal();
  const setPaymentStep = useStorePaymentStep((state) => state.setPaymentStep);
  const [loader, setLoader] = useState(false);
  const handleClick = (item) => {
    setPaymentStep({
      offeringInsurance: item,
    });
    setModalCross({
      openModal: false,
    });
  };

  console.log(data);

  return (
    <Box
      sx={{
        bgcolor: "background.paper",
        height: "100vh",
        maxWidth: "900px",
        position: "relative",
      }}
    >
      {loader && <div className="loader"></div>}
      <Grid container justifyContent={"center"} pl={5} spacing={1}>
        <Grid item xs={12} textAlign={"start"}>
          <Tooltip title="Cerrar" arrow>
            <Button onClick={resetModalCross}>
              <CloseIcon />
            </Button>
          </Tooltip>
        </Grid>
        <Grid item xs={12} textAlign={"center"}>
          <h1>Lista de Seguros</h1>
        </Grid>
        <Grid item xs={12} pr={5}>
          <StyledHr />
        </Grid>
        <EnsuranceFormSearch setData={setData} setLoader={setLoader} />

        {data && data.length > 0 && (
          <>
            <Grid item xs={3} mt={5}>
              <Paper elevation={1}>
                <FormControl sx={{ padding: "10px" }}>
                  <FormLabel id="demo-controlled-radio-buttons-group">
                    Ensurance types
                  </FormLabel>
                  <RadioGroup
                    aria-labelledby="demo-controlled-radio-buttons-group"
                    name="controlled-radio-buttons-group"
                    value={value}
                    onChange={handleChange}
                  >
                    <FormControlLabel
                      value="INSURANCE_TYPE_SINGLE"
                      control={<Radio />}
                      label="Single"
                    />
                    <FormControlLabel
                      value="INSURANCE_TYPE_MULTI_FAMILY"
                      control={<Radio />}
                      label="Family"
                    />
                    <FormControlLabel
                      value="INSURANCE_TYPE_COUPLE"
                      control={<Radio />}
                      label="Couple"
                    />
                  </RadioGroup>
                </FormControl>
              </Paper>
            </Grid>
            <Grid item xs={8} mt={5}>
              <Paper elevation={1}>
                <List
                  sx={{
                    maxHeight: "80vh",
                    overflow: "auto",
                    marginLeft: "20px",
                  }}
                >
                  {data.length > 0 &&
                    data
                      .filter(
                        (i) =>
                          i.offering.offeringInsurance
                            .offeringInsuranceTypeTemplate.codeName === value
                      )
                      .map((item) => (
                        <>
                          <ListItem key={item.offeringId}>
                            <ListItemAvatar sx={{ margin: "15px" }}>
                              <img
                                src={item.offering.school.photo.url}
                                alt="descripción"
                                style={{ width: "75px", height: "40px" }}
                              />
                            </ListItemAvatar>
                            <ListItemText
                              primary={item.offering.offeringInsurance.name}
                              secondary={`${item.price.currency.code}${item.price.currency.symbol}${item.price.grossPrice}`}
                            />
                            <StyledListItemButton
                              onClick={() => handleClick(item)}
                            >
                              Comprar
                            </StyledListItemButton>
                          </ListItem>
                          <Divider
                            key={`${item.offeringId}-divider`}
                            variant="fullWidth"
                            component="li"
                          />
                        </>
                      ))}
                </List>
              </Paper>
            </Grid>
          </>
        )}
      </Grid>
    </Box>
  );
};
