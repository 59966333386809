import { Grid } from "@mui/material";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import React, { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import {
  StyledMUIButtonIcon,
  StyledMUITextField,
} from "../../../StyledCrossComponents";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { useFetchPost } from "../../../Hooks/useFetchPost";
import { useStorePaymentStep } from "../../../store";

export const EnsuranceFormSearch = ({ setData, setLoader }) => {
  const store = useStorePaymentStep();
  const [buttonDisable, setButtonDisable] = useState(false);
  const { SubmitFormJson } = useFetchPost();
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const onSubmit = async (data) => {
    setData([]);
    setLoader(true);
    setButtonDisable(true);
    store.setPaymentStep({
      offeringInsuranceDateStart:
        data.PolicyStartsOn.toISOString().split("T")[0],
      offeringInsuranceDateEnd: data.PolicyEndsOn.toISOString().split("T")[0],
    });

    const response = await SubmitFormJson(
      {
        PolicyStartsOn: data.PolicyStartsOn.toISOString().split("T")[0],
        PolicyEndsOn: data.PolicyEndsOn.toISOString().split("T")[0],
        DestinationCountryId: 13, //ToDo: Change this value, it's hardcoded the ContryId Colombia
        NationalityCountryId: 46, //ToDo: Change this value, it's hardcoded the ContryId Australia
        Age: data.Age,
      },
      "SearchEnsurance"
    );

    const responseData = await response.json();
    setData(responseData.data.offeringInsuranceSearchNew);
    setLoader(false);
    setButtonDisable(false);
  };

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={2} mt={5}>
          <Grid item xs={3}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <Controller
                name="PolicyStartsOn"
                control={control}
                rules={{ required: "Este campo es requerido" }}
                render={({ field }) => (
                  <DesktopDatePicker
                    {...field}
                    inputFormat="YYYY/MM/DD"
                    label="Policy Starts On"
                    renderInput={(params) => (
                      <StyledMUITextField
                        {...params}
                        size="small"
                        error={!!errors.PolicyStartsOn}
                        helperText={
                          errors.PolicyStartsOn
                            ? errors.PolicyStartsOn.message
                            : null
                        }
                      />
                    )}
                  />
                )}
              />
            </LocalizationProvider>
          </Grid>
          <Grid item xs={3}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <Controller
                name="PolicyEndsOn"
                control={control}
                rules={{ required: "Este campo es requerido" }}
                render={({ field }) => (
                  <DesktopDatePicker
                    {...field}
                    inputFormat="YYYY/MM/DD"
                    label="Policy ends on"
                    renderInput={(params) => (
                      <StyledMUITextField
                        {...params}
                        size="small"
                        error={!!errors.PolicyEndsOn}
                        helperText={
                          errors.PolicyEndsOn
                            ? errors.PolicyEndsOn.message
                            : null
                        }
                      />
                    )}
                  />
                )}
              />
            </LocalizationProvider>
          </Grid>
          <Grid item xs={3}>
            <Controller
              name="Age"
              control={control}
              defaultValue=""
              rules={{ required: "Este campo es requerido" }}
              render={({ field }) => (
                <StyledMUITextField
                  {...field}
                  label="Age"
                  variant="outlined"
                  size="small"
                  error={!!errors.Age}
                  helperText={errors.Age ? errors.Age.message : null}
                />
              )}
            />
          </Grid>
          <Grid item xs={3}>
            <StyledMUIButtonIcon
              variant="contained"
              type="submit"
              disabled={buttonDisable}
            >
              Buscar
            </StyledMUIButtonIcon>
          </Grid>
        </Grid>
      </form>
    </>
  );
};
