import { Grid, Typography } from "@mui/material";
import React from "react";
import { StyledMUIGridContainer } from "../../Home/Styled-components";
import { NavBar } from "../../Home/Components";
import theme from "../../../theme";

export const ValidateIdentityResponse = () => {
  return (
    <Grid
      container
      spacing={2}
      alignItems="center"
      justifyContent="center"
      sx={{
        backgroundColor: theme.Palette.quinary,
        fontFamily: theme.Font.primary,
        padding: "20px",
      }}
    >
      <NavBar backgroundcolor={theme.Palette.quinary} />
      <StyledMUIGridContainer
        container
        spacing={2}
        alignItems="center"
        justifyContent="center"
        sx={{ maxWidth: theme.Breakpoint.xlarge }}
      >
        <Typography variant="h6" gutterBottom>
          El proceso de validación de identidad ha terminado, le enviaremos un
          correo electrónico cuando tu cuenta este activa.
        </Typography>
      </StyledMUIGridContainer>
    </Grid>
  );
};
