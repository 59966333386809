import React from "react";
import { ExchangeList } from "../Components/ExchangeList";

export const ExchangeAdmin = () => {
  return (
    <>
      <ExchangeList />
    </>
  );
};
