export const GetStatsComponents = (agencyId) => [
  {
    label: "Comisiones acumuladas por pagos",
    componentName: "PaymentCommission",
    url: `Commission/agency/${agencyId}`,
    field: "amountPaymentCommission",
  },
  {
    label: "Comisiones acumuladas por seguros",
    componentName: "PaymentCommissionInsurance",
    url: `CommissionInsurance/agency/${agencyId}`,
    field: "amountPaymentCommissionInsurance",
  },
  {
    label: "Pagos realizados",
    componentName: "amountPayments",
    url: `Commission/agency/${agencyId}`,
    field: "amountPayments",
  },
];
