import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  DivContainer,
  StyledCheckCircleIcon,
  StyledMUIButton,
  StyledMUIGrid,
  StyledMUIGridContainer,
  StyledMUIPaperContainer,
  Styledimg,
  Text,
  Titule,
} from "../Styled-components/index";
import { Grid } from "@mui/material";
import { useTranslation } from "react-i18next";
import ImageComponent from "../Styled-components/StyledImageComponent";
import { homeimage3, animationgreen } from "../../../assets";
import SupportAgentIcon from "@mui/icons-material/SupportAgent";
import theme from "../../../theme";

export const InsuranseSection = () => {
  const [animate, setAnimate] = useState(false);
  const ref = useRef();
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (setAnimate(entry.isIntersecting)) {
          setAnimate(true);
          setTimeout(() => setAnimate(false), 1000);
        }
      },
      { threshold: 0.5 } // Se activa cuando el 50% del elemento es visible
    );

    if (ref.current) {
      observer.observe(ref.current);
    }

    return () => {
      if (ref.current) {
        observer.unobserve(ref.current);
      }
    };
  }, []);
  const handleNavigate = (path, id) => {
    navigate(path);
    setTimeout(() => {
      const element = document.getElementById(id);
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
    }, 100);
  };
  return (
    <StyledMUIGridContainer
      container
      spacing={2}
      alignItems="center"
      mt={60}
      marginBottom="420px"
      sx={{
        backgroundColor: "#EAF4FF",
      }}
      ref={ref}
    >
      <Grid item xs={12} md={5}>
        <Titule>{t("homeContainer_3.title")}</Titule>
        <Text>{t("homeContainer_3.description")}</Text>
        <DivContainer>
          <StyledCheckCircleIcon />
          <Text>{t("homeContainer_3.label_1")}</Text>
        </DivContainer>
        <DivContainer>
          <StyledCheckCircleIcon />
          <Text>{t("homeContainer_3.label_2")}</Text>
        </DivContainer>
        <DivContainer>
          <StyledCheckCircleIcon />
          <Text>{t("homeContainer_3.label_3")}</Text>
        </DivContainer>
        <DivContainer>
          <StyledCheckCircleIcon />
          <Text>{t("homeContainer_3.label_4")}</Text>
        </DivContainer>
        <Grid item xs={12} md={12}>
          <StyledMUIButton
            variant="contained"
            $width="50%"
            onClick={() =>
              handleNavigate(
                "/subservice/#seguros-estudiantiles",
                "seguros-estudiantiles"
              )
            }
          >
            {t("homeContainer_3.button")}
          </StyledMUIButton>
        </Grid>
      </Grid>
      <Grid item xs={12} md={7}>
        <ImageComponent src={homeimage3} alt="home" />
      </Grid>
      <StyledMUIGrid
        container
        justifyContent="center"
        spacing={2}
        sx={{ top: "95%" }}
        // scrolled={scrolled}
      >
        <Grid item xs={12} md={5}>
          <StyledMUIPaperContainer
            elevation={1}
            sx={{ backgroundColor: "#E5E5E5" }}
          >
            <SupportAgentIcon fontSize="large" />
            <Titule fontSize={theme.FontSize.medium}>
              {t("homeContainer_3.subTitle_1")}
            </Titule>
            <Text>{t("homeContainer_3.subDescription_1")}</Text>
          </StyledMUIPaperContainer>
        </Grid>
        <Grid item xs={12} md={5}>
          <StyledMUIPaperContainer
            elevation={1}
            sx={{ backgroundColor: "#E5E5E5" }}
          >
            <SupportAgentIcon fontSize="large" />
            <Titule fontSize={theme.FontSize.medium}>
              {t("homeContainer_3.subTitle_2")}
            </Titule>
            <Text>{t("homeContainer_3.subDescription_2")}</Text>
          </StyledMUIPaperContainer>
        </Grid>
      </StyledMUIGrid>
      <Styledimg $top="-130px" $left="-170px" $animate={animate}>
        <ImageComponent src={animationgreen} alt="home" />
      </Styledimg>
    </StyledMUIGridContainer>
  );
};
