import React, { useState } from "react";
import { StyledMUISelectDataGrid } from "../../../StyledCrossComponents";
import { MenuItem } from "@mui/material";
import { useFetchPut } from "../../../Hooks/useFetchPut";
import { useStoreModalNotification } from "../../../store";

export const SelectStateUser = ({ row }) => {
  const [stateValue, setStateValue] = useState(row);
  const { SubmitUpdate } = useFetchPut();
  const setModalAlert = useStoreModalNotification(
    (state) => state.setModalAlert
  );

  const handleChange = (event, id) => {
    const newStateValue = {
      ...stateValue,
      checkValidationIdentity: event.target.value,
    };
    setStateValue({
      ...stateValue,
      checkValidationIdentity: event.target.value,
    });
    SubmitUpdate(newStateValue, `Account/UpdateUser/${stateValue.id}`).then(
      (response) => {
        if (!response.ok) {
          setModalAlert({
            openModal: true,
            message: "Error al actualizar la validación de identidad",
            severity: "error",
            variant: "filled",
          });
          return;
        }
        setModalAlert({
          openModal: true,
          message: "Validación de identidad actualizada",
          severity: "success",
          variant: "filled",
        });
      }
    );
  };

  return (
    <StyledMUISelectDataGrid
      key={row.id}
      labelId={`select-label-${row.id}`}
      id={`select-props-${row.id}`}
      value={stateValue.checkValidationIdentity}
      onChange={(e) => handleChange(e, row.id)}
    >
      <MenuItem value={true}>Si</MenuItem>
      <MenuItem value={false}>No</MenuItem>
    </StyledMUISelectDataGrid>
  );
};
