import {
  FormControl,
  FormHelperText,
  Grid,
  InputAdornment,
  TextField,
} from "@mui/material";
import React from "react";
import { Controller } from "react-hook-form";
import PersonIcon from "@mui/icons-material/Person";
import { StyledMUITextField } from "../../../StyledCrossComponents";
export const UserTextField = ({ name, label, control, type, errors }) => {
  return (
    <Grid item>
      <Controller
        name={name}
        control={control}
        render={({ field }) => (
          <FormControl fullWidth>
            <StyledMUITextField
              size="small"
              {...field}
              label={label}
              type={type}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <PersonIcon />
                  </InputAdornment>
                ),
              }}
            />
            {errors[name] && (
              <FormHelperText id="my-helper-text" error>
                {errors[name]["message"]}
              </FormHelperText>
            )}
          </FormControl>
        )}
      />
    </Grid>
  );
};
