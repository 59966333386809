import React from "react";
import {
  ImageServiceComponent,
  StyledMUIButton,
  StyledMUIGrid,
  StyledMUIGridContainer,
  StyledMUIGridReverse,
  StyledMUIPaperContainer,
  Text,
  Titule,
} from "../Styled-components";
import { Grid } from "@mui/material";
import PersonIcon from "@mui/icons-material/Person";
import DownloadIcon from "@mui/icons-material/Download";
import PaymentsIcon from "@mui/icons-material/Payments";
import theme from "../../../theme";
import { subservice3 } from "../../../assets";
import { useTranslation } from "react-i18next";

export const SeguroMedico = () => {
  const { t, i18n } = useTranslation();

  return (
    <StyledMUIGridContainer
      id="seguros-estudiantiles"
      container
      spacing={2}
      alignItems="center"
      justifyContent="center"
      mt={10}
      marginBottom="420px"
      sx={{
        backgroundColor: theme.Palette.septenary,
      }}
    >
      <Grid item xs={12} sm={5}>
        <Titule>{t("subServiceContainer_3.title_1")}</Titule>
        <Text>{t("subServiceContainer_3.description_1")}</Text>
        <StyledMUIButton href="/PreRegistro" $width="50%">
          {t("subServiceContainer_3.button")}
        </StyledMUIButton>
      </Grid>
      <StyledMUIGridReverse
        item
        xs={12}
        sm={7}
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <ImageServiceComponent src={subservice3} alt="edupayments" />
      </StyledMUIGridReverse>
      <StyledMUIGrid
        container
        justifyContent="center"
        spacing={3}
        sx={{ top: "95%" }}
      >
        <Grid item xs={12} md={4}>
          <StyledMUIPaperContainer
            elevation={1}
            sx={{
              backgroundColor: theme.Palette.senary,
            }}
          >
            <PersonIcon
              fontSize="large"
              sx={{ color: theme.Palette.quaternary }}
            />
            <Titule fontSize={theme.FontSize.small}>
              {t("subServiceContainer_3.title_2")}
            </Titule>
            <Text>{t("subServiceContainer_3.description_2")}</Text>
          </StyledMUIPaperContainer>
        </Grid>
        <Grid item xs={12} md={4}>
          <StyledMUIPaperContainer
            elevation={1}
            sx={{
              backgroundColor: theme.Palette.senary,
            }}
          >
            <DownloadIcon fontSize="large" />
            <Titule fontSize={theme.FontSize.small}>
              {t("subServiceContainer_3.title_3")}
            </Titule>
            <Text>{t("subServiceContainer_3.description_3")}</Text>
          </StyledMUIPaperContainer>
        </Grid>
        <Grid item xs={12} md={4}>
          <StyledMUIPaperContainer
            elevation={1}
            sx={{
              backgroundColor: theme.Palette.senary,
            }}
          >
            <PaymentsIcon
              fontSize="large"
              sx={{
                color: theme.Palette.octonary,
              }}
            />
            <Titule fontSize={theme.FontSize.small}>
              {t("subServiceContainer_3.title_4")}
            </Titule>
            <Text>{t("subServiceContainer_3.description_4")}</Text>
          </StyledMUIPaperContainer>
        </Grid>
      </StyledMUIGrid>
    </StyledMUIGridContainer>
  );
};
