import React from "react";
import styled from "styled-components";

const ImageWrapper = styled.div`
  width: 100%;
`;

const Image = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

const ImageComponent = ({ src, alt }) => {
  return (
    <ImageWrapper>
      <Image src={src} alt={alt} />
    </ImageWrapper>
  );
};

export default ImageComponent;
