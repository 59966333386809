import React from "react";
import { NppPaymentList } from "./NppPaymentList";

export const PersonVirtualAccount = () => {
  return (
    <>
      <NppPaymentList />
    </>
  );
};
