import React from "react";
import { PaymentDataGrid } from "../Components";

export const PaymentsList = () => {
  return (
    <>
      <PaymentDataGrid />
    </>
  );
};
