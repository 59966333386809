import { useStorePaymentStep } from "../../../store";

export const getPaymentData = ({ key, formValues, formData, store }) => {
  switch (key) {
    case "estimatePaymentWithInsurance":
      formData.append(
        key,
        store.paymentStepState.estimatePaymentWithInsurance !== 0
          ? store.paymentStepState.estimatePaymentWithInsurance
          : formValues[key]
      );

      break;
    case "tagetAmountWithInsurance":
      formData.append(
        key,
        store.paymentStepState.tagetAmountWithInsurance !== 0
          ? store.paymentStepState.tagetAmountWithInsurance
          : formValues[key]
      );
      break;
    case "offeringInsuranceName":
      formData.append(
        key,
        Object.keys(store.paymentStepState.offeringInsurance).length > 0
          ? store.paymentStepState.offeringInsurance.offering.offeringInsurance
              .name
          : formValues[key]
      );
      break;
    case "offeringInsuranceValue":
      formData.append(
        key,
        Object.keys(store.paymentStepState.offeringInsurance).length > 0
          ? store.paymentStepState.offeringInsurance.price.grossPrice
          : formValues[key]
      );
      break;
    case "offeringInsuranceDateStart":
      formData.append(
        key,
        store.paymentStepState.offeringInsuranceDateStart !== ""
          ? store.paymentStepState.offeringInsuranceDateStart
          : formValues[key]
      );
      break;
    case "offeringInsuranceDateEnd":
      formData.append(
        key,
        store.paymentStepState.offeringInsuranceDateEnd !== ""
          ? store.paymentStepState.offeringInsuranceDateEnd
          : formValues[key]
      );
      break;
    case "offeringId":
      formData.append(
        "offeringId",
        Object.keys(store.paymentStepState.offeringInsurance).length > 0
          ? store.paymentStepState.offeringInsurance.offeringId
          : 0
      );
      break;
    default:
      formData.append(key, formValues[key]);
      break;
  }
};
