import React, { useEffect } from "react";
import { useFetchGet } from "../../../Hooks/useFetchGet";
import { StyledMUISelect } from "../../../StyledCrossComponents";
import { Controller } from "react-hook-form";
import {
  Grid,
  MenuItem,
  FormControl,
  InputLabel,
  FormHelperText,
} from "@mui/material";

export const SelectTypeIdeAgency = ({
  item,
  errors,
  control,
  formValues,
  setValue,
}) => {
  const { data, error } = useFetchGet(item.apiEndPoint);
  if (data == null) return null;
  const options = data.filter((i) => item.filter.includes(i.id));

  return (
    <Grid item xs={item.xs}>
      <Controller
        name={item.nameId ? item.nameId : item.name}
        control={control}
        defaultValue={item.value}
        sx={item.sx}
        render={({ field }) => (
          <FormControl fullWidth>
            <InputLabel
              id={`select-label-${item.nameId ? item.nameId : item.name}`}
            >
              {item.label}
            </InputLabel>
            <StyledMUISelect
              labelId={`select-label-${item.nameId ? item.nameId : item.name}`}
              // onChange={(e) => {
              //   field.onChange(e.target.value);
              // }}
              {...field}
              value={field.value || item.value}
              size={item.size}
              disabled={item.disabled}
            >
              <MenuItem value="0">Eliege una opción...</MenuItem>
              {options?.map((i) => (
                <MenuItem key={i[item.name]} value={i[item.name]}>
                  {i[item.description]}
                </MenuItem>
              ))}
            </StyledMUISelect>
            {errors[item.nameId ? item.nameId : item.name] && (
              <FormHelperText id="my-helper-text" error>
                {errors[item.nameId ? item.nameId : item.name]["message"]}
              </FormHelperText>
            )}
          </FormControl>
        )}
      />
    </Grid>
  );
};
