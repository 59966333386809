import React from "react";
import { ResetPasswordForm } from "../Components/ResetPasswordForm";
import { Grid } from "@mui/material";

export const ResetPassword = () => {
  return (
    <Grid container justifyContent={"center"} mt={30}>
      <ResetPasswordForm />
    </Grid>
  );
};
