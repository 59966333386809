import { GridActionsCellItem } from "@mui/x-data-grid";
import styled from "styled-components";
import theme from "../theme";

export const StyledGridActionsCellItem = styled(GridActionsCellItem)`
  && {
    margin: 0px 1px;
    color: #000000;
    //background-color: ${theme.Palette.tertiary};
    &:hover {
      background-color: ${theme.Palette.senary};
    }
  }
`;
