import { Button, Grid, Paper } from "@mui/material";
import { keyframes, styled, css } from "styled-components";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import theme from "../../../theme";

export const BodyContainer = styled.div`
  width: 100%;
  height: 100%;
  margin: 0 auto;
  margin-top: 60px;
  background-color: ${theme.Palette.quinary};
  max-width: ${theme.Breakpoint.xlarge};
  font-family: ${theme.Font.primary};

  @media screen and (max-width: ${theme.Breakpoint.medium}) {
    margin-top: 0;
  }
`;

export const Titule = styled.h1`
  width: 100%;
  font-weight: 600;
  font-size: ${(props) =>
    props.fontSize ? props.fontSize : theme.FontSize.large};
  color: ${(props) => (props.color ? props.color : theme.Palette.primary)};
  text-align: ${(props) => props.$textalign};
  line-height: 60px;
  @media screen and (max-width: ${theme.Breakpoint.medium}) {
    font-size: ${theme.FontSize.medium};
  }
`;

export const Text = styled.p`
  font-weight: 500;
  font-size: ${(props) =>
    props.fontSize ? props.fontSize : theme.FontSize.small};
  line-height: ${(props) =>
    props.$lineheight ? props.$lineheight : theme.LineHeight.small};
  color: ${(props) => (props.color ? props.color : theme.Palette.secondary)};
  width: ${(props) => (props.width ? props.width : "100%")};
  text-align: ${(props) => props.$textalign};
  @media screen and (max-width: 768px) {
    width: 100%;
    font-size: ${theme.FontSize.small};
  }
`;

export const StyledMUIButton = styled(Button)`
  && {
    border-radius: 96px;
    width: ${(props) => (props.$width ? props.$width : "100%")};
    height: ${(props) => (props.$height ? props.$height : "60px")};
    margin: 30px 0;
    color: ${theme.Palette.quinary};
    background-color: ${theme.Palette.quaternary};

    &:hover {
      background-color: ${theme.Hover.primary};
      color: ${theme.Palette.quinary};
    }
    @media screen and (max-width: ${theme.Breakpoint.medium}) {
      width: 100%;
      height: 50px;
      margin-top: 20px;
      margin-bottom: 20px;
    }
  }
`;

export const StyledMUIGrid = styled(Grid)`
  && {
    position: absolute;
    width: ${(props) => (props.sx.width ? props.sx.width : "100%")};
    transform: translateY(${(props) => (props.scrolled ? "-100%" : "0")});
    transition: transform 0.4s ease;
    margin-bottom: 120px;

    @media screen and (max-width: ${theme.Breakpoint.medium}) {
      position: relative;
      transform: translateY(${(props) => (props.scrolled ? "0" : "0")});
      transition: transform 0.8s ease;
      margin-top: 25px;
      width: 100%;
      left: 0;
    }
  }
`;

export const StyledMUIGridContainer = styled(Grid)`
  && {
    border-radius: 20px;
    padding: ${(props) => (props.padding ? props.padding : "20px")};
    position: relative;
    margin-bottom: ${(props) =>
      props.marginBottom ? props.marginBottom : "0px"};
    backdrop-filter: blur(1px);
    opacity: 0.9;

    @media screen and (max-width: ${theme.Breakpoint.medium}) {
      margin-top: 25px;
      margin-bottom: 25px;
    }
  }
`;

export const StyledMUIPaper = styled(Paper)`
  && {
    background-color: ${theme.Palette.quinary};
    border-radius: 25px;
    padding: 10px;
    height: 90%;
    text-align: center;
    border: 4px solid #eaf4ff;

    @media screen and (max-width: ${theme.Breakpoint.medium}) {
      width: 100%;
    }
  }
`;

export const StyledMUIPaperContainer = styled(Paper)`
  && {
    border-radius: 25px;
    padding: 20px;
    height: 90%;
  }
`;

export const StyledMUIGridImageContainer = styled(Grid)`
  && {
    display: flex;
    justify-content: ${(props) => props.justifyContent};
    align-items: center;
    @media screen and (max-width: ${theme.Breakpoint.medium}) {
      justify-content: center;
      flex-direction: column;
    }
  }
`;

export const DivContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const Icon = styled.div`
  width: 44px;
  height: 44px;
`;

export const StyledCheckCircleIcon = styled(CheckCircleIcon)`
  && {
    color: ${theme.Palette.tertiary};
    margin-right: 10px;
    font-size: ${theme.FontSize.medium};
  }
`;

export const StyledMUIGridReverse = styled(Grid)`
  && {
    @media screen and (max-width: ${theme.Breakpoint.small}) {
      order: -1;
    }
  }
`;

const bumerang = keyframes`
  0% {
    transform: translateX(100%)
    rotate(0deg);
  }
 50% {
    transform: translateX(-300%) rotate(360deg);
  }
  /* 100% {
    transform: translateX(100%) rotate(360deg);
  }  */
`;

export const Styledimg = styled.div`
  width: ${(props) => props.$width}; //260px;
  height: ${(props) => props.$width}; //300px;
  position: absolute;
  top: ${(props) => props.$top}; //-80px;
  left: ${(props) => props.$left}; //1010px;
  transform: ${(props) => props.$rotate}; //rotate(160deg);
  z-index: ${(props) => props.$zindex}; //1;
  ${(props) =>
    props.$animate &&
    css`
      animation: ${bumerang} 2s;
    `}
  animation-play-state: ${(props) => (props.$animate ? "running" : "paused")};
  //animation: ${bumerang} 1s;
  @media screen and (max-width: ${theme.Breakpoint.medium}) {
    display: none;
  }
`;
