import {
  Button,
  FormControl,
  FormHelperText,
  Grid,
  Typography,
} from "@mui/material";
import React, { useRef, useState } from "react";
import { Controller } from "react-hook-form";
import FolderIcon from "@mui/icons-material/Folder";
import theme from "../../theme";
import { StyledMUIButtonIcon, StyledSpan } from "../../StyledCrossComponents";

export const CrossSelectFile = ({
  item,
  errors,
  control,
  formValues,
  setValue,
  label,
}) => {
  const hiddenFileInput = useRef(null);

  const handleClick = () => {
    hiddenFileInput.current.click();
  };

  const [fileName, setFileName] = useState("");

  return (
    <>
      <Grid item xs={item.xs} justifyContent={"center"}>
        <Controller
          name={item.name}
          control={control}
          render={({ field }) => (
            <FormControl fullWidth>
              <StyledMUIButtonIcon
                onClick={handleClick}
                variant="contained"
                startIcon={<FolderIcon />}
              >
                {item.label}
              </StyledMUIButtonIcon>
              <input
                label={item.label}
                type={item.type}
                size={item.size}
                ref={hiddenFileInput}
                multiple
                onChange={(e) => {
                  field.onChange(e.target.files);
                  setFileName(e.target.files[0].name);
                }}
                style={{ display: "none" }}
              />
              <Typography
                variant="caption"
                color="text.secondary"
                textAlign={"center"}
              >
                <StyledSpan>Archivos Adjuntos:</StyledSpan>
                {fileName}
              </Typography>
              {errors[item.name] && (
                <FormHelperText id="my-helper-text" error>
                  {errors[item.name]["message"]}
                </FormHelperText>
              )}
            </FormControl>
          )}
        />
      </Grid>
    </>
  );
};
