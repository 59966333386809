import { Title } from "@mui/icons-material";

export const getDataStepsRegisterAgency = () => [
  {
    label: "Usuario y contraseña",
    step: 0,
    validations: [],
    form: [
      {
        componenttype: "input",
        label: "Email",
        type: "email",
        name: "email",
        value: "",
        size: "small",
        margin: "normal",
        xs: 12,
        validations: [
          {
            type: "required",
          },
          {
            type: "email",
            pattern: /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/,
          },
        ],
      },
      {
        componenttype: "input",
        label: "Contraseña",
        type: "password",
        name: "password",
        value: "",
        size: "small",
        margin: "normal",
        xs: 12,
        validations: [
          {
            type: "required",
          },
        ],
      },
      {
        componenttype: "input",
        label: "Confirmar contraseña",
        type: "password",
        name: "confirmPassword",
        value: "",
        size: "small",
        margin: "normal",
        xs: 12,
        validations: [
          {
            type: "required",
          },
          {
            type: "confirmPassword",
            name: "password",
          },
        ],
      },
    ],
  },
  {
    label: "Datos representante legal",
    step: 1,
    validations: [],
    form: [
      {
        componenttype: "selecttypeidentification",
        type: "select",
        label: "Tipo de identificación",
        size: "small",
        name: "id",
        filter: [1, 3, 4, 5],
        nameId: "legalIdentificationTypeId",
        description: "description",
        value: "1",
        margin: "normal",
        xs: 6,
        apiEndPoint: "IdentificationType",
        validations: [
          {
            type: "required",
          },
        ],
      },
      {
        componenttype: "input",
        label: "Número de identificación",
        type: "text",
        size: "small",
        name: "document",
        value: "",
        margin: "normal",
        xs: 6,
        validations: [
          {
            type: "required",
          },
        ],
      },
      {
        componenttype: "input",
        label: "Nombre del representante legal",
        type: "text",
        size: "small",
        name: "firstName",
        value: "",
        margin: "normal",
        xs: 12,
        validations: [
          {
            type: "required",
          },
        ],
      },
      {
        componenttype: "input",
        label: "Apellido del representante legal",
        type: "text",
        size: "small",
        name: "lastName",
        value: "",
        margin: "normal",
        xs: 12,
        validations: [
          {
            type: "required",
          },
          {
            type: "pattern",
            pattern: /^[a-zA-ZÀ-ÿ\s]{1,40}$/,
          },
        ],
      },
      {
        componenttype: "select",
        label: "País de residencia",
        size: "small",
        name: "countryId",
        description: "countryName",
        value: "0",
        margin: "normal",
        xs: 6,
        apiEndPoint: "Country",
        validations: [
          {
            type: "required",
          },
        ],
      },
      {
        componenttype: "select",
        label: "Ciudad de residencia",
        size: "small",
        name: "cityId",
        description: "nameCity",
        value: "0",
        margin: "normal",
        xs: 6,
        apiEndPoint: "City/GetCitiesByCountryId/*",
        anidado: true,
        param: { name: "countryId" },
        validations: [
          {
            type: "required",
          },
        ],
      },
      {
        componenttype: "input",
        label: "Dirección de residencia",
        type: "text",
        size: "small",
        name: "address",
        value: "",
        margin: "normal",
        xs: 12,
        validations: [
          {
            type: "required",
          },
        ],
      },

      {
        componenttype: "input",
        label: "Número de teléfono",
        type: "text",
        size: "small",
        name: "phone",
        value: "",
        margin: "normal",
        xs: 12,
        validations: [
          {
            type: "required",
          },
          {
            type: "pattern",
            pattern: /^[0-9]{8,10}$/,
          },
        ],
      },
      {
        componenttype: "inputfile",
        label_1: "Adjuntar camara de comercio y Composición Accionaria",
        label_2: "Adjuntar ASIC Company Statement",
        label_3: "Adjuntar cedual de ciudadania",
        label_4: "Adjuntar pasaporte",
        label_5: "Adjuntar Australian photo ID",
        label_6: "Adjuntar Australian driver's license",
        type: "file",
        name: "documentFile",
        value: 1,
        size: "small",
        margin: "normal",
        xs: 12,
        apiEndPoint: "Country/*",
        param: { name: "countryAgencyId" },
        validations: [
          {
            type: "required",
          },
        ],
      },
      {
        componenttype: "check",
        label: "Se considera usted una persona públicamente expuesta (PEP)",
        title:
          "Se considerarán como Personas Expuestas Políticamente (PEP) los servidores públicos de cualquier sistema de nomenclatura y clasificación de empleos de la administración pública nacional y territorial, cuando tengan asignadas o delegadas funciones de: expedición de normas o regulaciones, dirección general, formulación de políticas institucionales y adopción de planes, programas y proyectos, manejo directo de bienes, dineros o valores del Estado, administración de bienes, muebles e inmuebles.",
        type: "checkbox",
        size: "small",
        name: "publicPerson",
        value: false,
        margin: "normal",
        xs: 12,
        validations: [],
      },

      {
        componenttype: "input",
        label: "Agencia Id",
        type: "text",
        size: "small",
        name: "agencyId",
        value: "",
        xs: 12,
        sx: { display: "none" },
        validations: [],
      },
      {
        componenttype: "input",
        label: "User Id",
        type: "text",
        size: "small",
        name: "userId",
        value: "",
        xs: 12,
        sx: { display: "none" },
        validations: [],
      },
    ],
  },
  {
    label: "Datos de la Agencia",
    step: 2,
    validations: [],
    form: [
      {
        componenttype: "input",
        label: "Nombre de la agencia",
        type: "text",
        name: "agencyName",
        value: "",
        size: "small",
        margin: "normal",
        xs: 12,
        validations: [
          {
            type: "required",
          },
        ],
      },
      {
        componenttype: "selecttypeidentification",
        type: "select",
        label: "Tipo de identificación",
        size: "small",
        name: "id",
        filter: [6, 2],
        nameId: "agencyIdentificationTypeId",
        description: "description",
        value: 2,
        margin: "normal",
        xs: 6,
        apiEndPoint: "IdentificationType",
        validations: [
          {
            type: "required",
          },
        ],
      },
      {
        componenttype: "input",
        label: "Número de documento legal",
        type: "text",
        name: "agencyNit",
        value: "",
        size: "small",
        margin: "normal",
        xs: 6,
        validations: [
          {
            type: "required",
          },
        ],
      },
      {
        componenttype: "input",
        label: "Número de teléfono",
        type: "text",
        name: "agencyPhoneNumber",
        value: "",
        size: "small",
        margin: "normal",
        xs: 12,
        validations: [
          {
            type: "required",
          },
        ],
      },
      {
        componenttype: "select",
        label: "País",
        type: "select",
        name: "countryId",
        nameId: "countryAgencyId",
        description: "countryName",
        value: "0",
        size: "small",
        margin: "normal",
        apiEndPoint: "Country",
        xs: 6,
        validations: [
          {
            type: "required",
          },
        ],
      },
      {
        componenttype: "select",
        label: "Ciudad",
        type: "select",
        name: "cityId",
        nameId: "cityAgencyId",
        description: "nameCity",
        value: "0",
        size: "small",
        margin: "normal",
        apiEndPoint: "City/GetCitiesByCountryId/*",
        anidado: true,
        param: { name: "countryAgencyId" },
        xs: 6,
        validations: [
          {
            type: "required",
          },
        ],
      },
      {
        componenttype: "input",
        label: "Dirección",
        type: "text",
        name: "address",
        value: "",
        size: "small",
        margin: "normal",
        xs: 12,
        validations: [
          {
            type: "required",
          },
        ],
      },
      {
        componenttype: "check",
        label: "Existen socios con participacion mayor al 25%",
        type: "checkbox",
        size: "small",
        name: "checkPartners",
        value: false,
        margin: "normal",
        xs: 12,
        validations: [],
      },
      {
        componenttype: "inputfile",
        label_1: "Adjuntar camara de comercio y Composición Accionaria",
        label_2: "Adjuntar ASIC Company Statement",
        label_3: "Adjuntar cedual de ciudadania",
        label_4: "Adjuntar pasaporte",
        label_5: "Adjuntar Australian photo ID",
        label_6: "Adjuntar Australian driver's license",
        type: "file",
        name: "myFile",
        value: 2,
        size: "small",
        margin: "normal",
        xs: 12,
        apiEndPoint: "Country/*",
        param: { name: "countryAgencyId" },
        validations: [
          {
            type: "required",
          },
        ],
      },
      {
        componenttype: "input",
        label: "Nombre del Archivo",
        type: "text",
        name: "nameTypeLegalBusinessDocument",
        value: "",
        size: "small",
        margin: "normal",
        sx: { display: "none" },
        xs: 12,
        validations: [],
      },
      {
        componenttype: "input",
        name: "typeLegalBusinessDocumentId",
        value: "",
        sx: { display: "none" },
        validations: [],
      },

      {
        componenttype: "check",
        label: "Aceptar terminos y condiciones",
        size: "small",
        name: "checkTerms",
        value: false,
        margin: "normal",
        xs: 5,
        validations: [
          {
            type: "checkTermsOk",
          },
        ],
      },
      {
        componenttype: "check",
        label: "Aceptar politicas de privacidad",
        size: "small",
        name: "checkPrivacy",
        value: false,
        margin: "normal",
        xs: 5,
        validations: [
          {
            type: "checkPrivacyOk",
          },
        ],
      },
    ],
  },
];
