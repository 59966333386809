import React from "react";
import { StyledMUITextField } from "../../StyledCrossComponents";
import { Controller } from "react-hook-form";
import { FormControl, FormHelperText, Grid } from "@mui/material";

export const CrossInputText = ({
  control,
  errors,
  item,
  formValues,
  setValue,
}) => {
  return (
    <Grid item xs={12} md={item.xs}>
      <Controller
        name={item.name}
        control={control}
        render={({ field }) => (
          <FormControl fullWidth>
            <StyledMUITextField
              {...field}
              label={item.label}
              type={item.type}
              size={item.size}
              sx={item.sx}
              value={field.value || item.value}
              disabled={item.disabled}
            />
            {errors[item.name] && (
              <FormHelperText id="my-helper-text" error>
                {errors[item.name]["message"]}
              </FormHelperText>
            )}
          </FormControl>
        )}
      />
    </Grid>
  );
};
