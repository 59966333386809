import { yupResolver } from "@hookform/resolvers/yup";
import { Grid, Link } from "@mui/material";
import React from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useFetchPost } from "../../../Hooks/useFetchPost";
import { useValidateForm } from "../../../Hooks/useValidateForm";
import {
  useStorageModal,
  useStoreModalNotification,
  useUserStore,
} from "../../../store";
import { CrossLoader } from "../../CrossComponents";
import { PassWordTextField } from "./PassWordTextField";
import { SubmitLoginButton } from "./SubmitLoginButton";
import { UserTextField } from "./UserTextField";

export const FormLogin = () => {
  const { t, i18n } = useTranslation();
  const { validationshema, initialValues } = useValidateForm(loginForm);
  const { setModalCross } = useStorageModal();
  const { SubmitFormJson } = useFetchPost();
  const navigate = useNavigate();
  const {
    handleSubmit,
    formState: { errors },
    control,
  } = useForm({
    defaultValues: initialValues,
    resolver: yupResolver(validationshema),
  });
  const { login } = useUserStore();
  const setModalAlert = useStoreModalNotification(
    (state) => state.setModalAlert
  );
  const setNotification = (openModal, message, severity, variant) => {
    setModalAlert({
      openModal: openModal,
      message: message,
      severity: severity,
      variant: variant,
    });
  };
  const setLoader = (openModal) => {
    setModalCross({
      openModal: openModal,
      component: () => <CrossLoader />,
      setValue: () => {},
      formValues: {},
    });
  };

  const onSubmit = (dataLogin) => {
    setLoader(true, setModalCross);
    SubmitFormJson(dataLogin, "Autentication").then((response) => {
      console.log(response);
      if (!response.ok) {
        setLoader(false);
        setNotification(
          true,
          "Usuario y/o contrasena invalidos",
          "error",
          "filled"
        );
        return;
      }
      response.json().then((data) => {
        if (!data.autenticationState && data.validateIdentityUrl === "") {
          setLoader(false);
          setNotification(
            true,
            "Usuario y/o contrasena invalidos",
            "error",
            "filled"
          );
          return;
        }
        if (!data.autenticationState && data.validateIdentityUrl !== "") {
          setLoader(false);
          window.location.href = data.validateIdentityUrl;
          return;
        }

        login(data);
        setLoader(false);

        if (!data.checkIsAgency) {
          switch (data.roles[0]) {
            case "Owner":
              navigate("/Dashboard");
              break;
            case "Administrador":
              navigate("/Dashboard/PersonPayments");
              break;
          }
        } else {
          switch (data.roles[0]) {
            // case "Owner":
            //   navigate("/Dashboard");
            //   break;
            case "Administrador":
              navigate("/Dashboard/PaymentsList");
              break;
            case "Asesor":
              navigate("/Dashboard/PaymentsList");
            default:
              break;
          }
        }
      });
    });
  };

  return (
    <>
      <form noValidate onSubmit={handleSubmit(onSubmit)}>
        <Grid
          container
          justifyContent="center"
          flexDirection="column"
          spacing={2}
          p={{ xs: 1, sm: 5 }}
        >
          {loginForm.map((cp) => {
            switch (cp.type) {
              case "text":
                return (
                  <UserTextField
                    key={cp.name}
                    control={control}
                    {...cp}
                    errors={errors}
                  />
                );
              case "password":
                return (
                  <PassWordTextField
                    key={cp.name}
                    control={control}
                    {...cp}
                    errors={errors}
                  />
                );
              case "select":
                return (
                  <CrossSelectList
                    key={cp.name}
                    control={control}
                    {...cp}
                    errors={errors}
                  />
                );
              case "submitButton":
                return <SubmitLoginButton key={cp.name} {...cp} />;
            }
          })}
        </Grid>
      </form>

      <Grid container justifyContent="center">
        <Grid item>
          <Link component="button" onClick={() => navigate("/RecoverPassword")}>
            {t("login.link")}
          </Link>
        </Grid>
      </Grid>
    </>
  );
};

const loginForm = [
  {
    type: "text",
    name: "Username",
    label: "Usuario",
    value: "",
    xs: 12,
    iconname: "",
    validations: [
      {
        type: "required",
      },
    ],
  },
  {
    type: "password",
    name: "Password",
    label: "Contraseña",
    value: "",
    xs: 12,
    iconname: "",
    validations: [
      {
        type: "required",
      },
    ],
  },
  {
    type: "submitButton",
    name: "Login",
    label: "Login",
    variant: "contained",
    mode: "submit",
    xs: 12,
    validations: [],
  },
];
