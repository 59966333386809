import React from "react";
import { PersonPaymentSteps } from "../Components/PersonPaymentSteps";
import { PersonLedgerAccount } from "../Components/PersonLedgerAccount";

export const PersonPayments = () => {
  return (
    <>
      {/* <PersonLedgerAccount /> */}
      <PersonPaymentSteps />
    </>
  );
};
