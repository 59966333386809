import React from "react";
import {
  StyledMUIBoxContainerForm,
  StyledMUIButtonIcon,
  StyledSpan,
} from "../../../StyledCrossComponents";
import { Box, Grid, Link, Typography } from "@mui/material";
import theme from "../../../theme";
import styled from "styled-components";
import { useStorageModal } from "../../../store";
import CloseIcon from "@mui/icons-material/Close";

export const UserDetails = ({ rowData }) => {
  const resetModalCross = useStorageModal((state) => state.resetModalCross);
  const handleClose = () => {
    resetModalCross();
  };
  return (
    <>
      <StyledMUIBoxContainerForm
        height={"100vh"}
        ml={4}
        backgroundColor={theme.Palette.senary}
        sx={{ textAlign: "center" }}
      >
        <Typography
          variant="h6"
          gutterBottom
          mt={5}
          sx={{
            color: theme.Palette.tertiary,
          }}
        >
          Datos del Usuario
        </Typography>
        <Box
          component="section"
          sx={{ p: 10, border: "1px dashed grey", textAlign: "center" }}
        >
          <Grid container spacing={2} justifyContent={"space-between"}>
            <Grid item xs={6} sm={6} sx={{ textAlign: "left" }}>
              <StyledSpan>Nombre del Usuario:</StyledSpan>
            </Grid>
            <Grid item xs={6} sm={6} sx={{ textAlign: "right" }}>
              {rowData.fullName}
            </Grid>
            <Grid item xs={6} sm={6} sx={{ textAlign: "left" }}>
              <StyledSpan>Numero de identificación:</StyledSpan>
            </Grid>
            <Grid item xs={6} sm={6} sx={{ textAlign: "right" }}>
              {rowData.document}
            </Grid>
            <Grid item xs={6} sm={6} sx={{ textAlign: "left" }}>
              <StyledSpan>Correo del Usuario:</StyledSpan>
            </Grid>
            <Grid item xs={6} sm={6} sx={{ textAlign: "right" }}>
              {rowData.email}
            </Grid>
            <Grid item xs={6} sm={6} sx={{ textAlign: "left" }}>
              <StyledSpan>Pais:</StyledSpan>
            </Grid>
            <Grid item xs={6} sm={6} sx={{ textAlign: "right" }}>
              {rowData.country.countryName}
            </Grid>
            <Grid item xs={6} sm={6} sx={{ textAlign: "left" }}>
              <StyledSpan>Ciudad:</StyledSpan>
            </Grid>
            <Grid item xs={6} sm={6} sx={{ textAlign: "right" }}>
              {rowData.city.nameCity}
            </Grid>
            <Grid item xs={6} sm={6} sx={{ textAlign: "left" }}>
              <StyledSpan>Dirección:</StyledSpan>
            </Grid>
            <Grid item xs={6} sm={6} sx={{ textAlign: "right" }}>
              {rowData.address}
            </Grid>
            <Grid item xs={6} sm={6} sx={{ textAlign: "left" }}>
              <StyledSpan>Documento adjunto:</StyledSpan>
            </Grid>
            <Grid item xs={6} sm={6} sx={{ textAlign: "right" }}>
              <Link href={rowData.imageFullPath} target="_blank">
                Archivo
              </Link>
            </Grid>
          </Grid>
        </Box>
        <Grid mt={5}>
          <StyledMUIButtonIcon
            variant="contained"
            sx={{ float: "right" }}
            onClick={handleClose}
            endIcon={<CloseIcon />}
          >
            Cerrar
          </StyledMUIButtonIcon>
        </Grid>
      </StyledMUIBoxContainerForm>
    </>
  );
};

const StyledGridUserDetails = styled(Grid)``;
