import React, { useEffect, useRef, useState } from "react";
import { Footer, NavBar } from "../Components";
import { Grid } from "@mui/material";
import {
  BodyContainer,
  ImageServiceComponent,
  StyledMUIGridContainer,
  StyledMUIGridReverse,
  Styledimg,
  Text,
  Titule,
} from "../Styled-components";
import {
  nosotros1,
  nosotros2,
  nosotros3,
  nosotros4,
  nosotros5,
} from "../../../assets";
import ImageComponent from "../Styled-components/StyledImageComponent";
import theme from "../../../theme";
import { useTranslation } from "react-i18next";
import { animationblue } from "../../../assets";
import { animationgreen } from "../../../assets";
import usePageTracking from "../../../Hooks/usePageTracking";

export const Nosotros = () => {
  usePageTracking("Nosotros");
  const { t, i18n } = useTranslation();
  const [animate, setAnimate] = useState(false);
  const ref = useRef();
  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (setAnimate(entry.isIntersecting)) {
          setAnimate(true);
          setTimeout(() => setAnimate(false), 1000);
        }
      },
      { threshold: 0.5 } // Se activa cuando el 50% del elemento es visible
    );

    if (ref.current) {
      observer.observe(ref.current);
    }

    return () => {
      if (ref.current) {
        observer.unobserve(ref.current);
      }
    };
  }, []);

  return (
    <>
      <Grid
        container
        spacing={2}
        alignItems="center"
        justifyContent="center"
        sx={{
          backgroundColor: theme.Palette.quinary,
          fontFamily: theme.Font.primary,
          padding: "20px",
        }}
      >
        <NavBar backgroundcolor={theme.Palette.quinary} />
        <StyledMUIGridContainer
          container
          spacing={2}
          alignItems="center"
          justifyContent="center"
          sx={{ maxWidth: theme.Breakpoint.xlarge }}
          ref={ref}
        >
          <Styledimg
            $top="400px"
            $left="900px"
            $rotate="rotate(360deg)"
            // $width="260px"
            // $height="300px"
            $zindex="1"
            $animate={animate}
          >
            <ImageComponent src={animationblue} alt="hoserviceme" />
          </Styledimg>
          <Grid item xs={12} sm={5}>
            <Titule>{t("weContainer_1.title_1")}</Titule>
            <Text>{t("weContainer_1.description_1")}</Text>
          </Grid>
          <StyledMUIGridReverse item xs={12} sm={7} justifyContent="center">
            <ImageComponent src={nosotros1} alt="edupayments" />
          </StyledMUIGridReverse>
        </StyledMUIGridContainer>
      </Grid>
      <BodyContainer>
        <StyledMUIGridContainer
          container
          spacing={2}
          alignItems="center"
          justifyContent="center"
          mt={10}
          sx={{
            backgroundColor: theme.Palette.senary,
            overflow: "hidden",
          }}
        >
          <Styledimg $top="-80px" $left="1010px" $rotate="rotate(440deg)">
            <ImageComponent src={animationblue} alt="hoserviceme" />
          </Styledimg>
          <StyledMUIGridReverse item xs={12} sm={6} justifyContent="center">
            <ImageComponent src={nosotros2} alt="Edupayments" />
          </StyledMUIGridReverse>
          <Grid item xs={12} sm={6}>
            <Titule>{t("weContainer_2.title_1")}</Titule>
            <Text>{t("weContainer_2.description_1")}</Text>
          </Grid>
        </StyledMUIGridContainer>
        <StyledMUIGridContainer
          container
          spacing={2}
          alignItems="center"
          justifyContent="center"
          mt={10}
          sx={{
            backgroundColor: theme.Palette.octonary,
          }}
        >
          <Grid item xs={12} sm={6}>
            <Titule>{t("weContainer_3.title_1")}</Titule>
            <Text>{t("weContainer_3.description_1")}</Text>
          </Grid>
          <StyledMUIGridReverse item xs={12} sm={6} justifyContent="center">
            <ImageServiceComponent src={nosotros3} alt="" />
          </StyledMUIGridReverse>
        </StyledMUIGridContainer>

        <StyledMUIGridContainer
          container
          spacing={2}
          alignItems="center"
          justifyContent="center"
          mt={10}
          sx={{
            backgroundColor: theme.Palette.quaternary,
          }}
        >
          <Grid item xs={12} sm={6}>
            <Titule>{t("weContainer_4.title_1")}</Titule>
            <Text>{t("weContainer_4.description_1")}</Text>
          </Grid>
          <StyledMUIGridReverse item xs={12} sm={6} justifyContent="center">
            <ImageComponent src={nosotros4} alt="Edupayments" />
          </StyledMUIGridReverse>
        </StyledMUIGridContainer>
        <StyledMUIGridContainer
          container
          spacing={2}
          alignItems="center"
          justifyContent="center"
          mt={10}
          sx={{
            backgroundColor: theme.Palette.senary,
            overflow: "hidden",
          }}
        >
          <Styledimg
            $top="400px"
            $left="-200px"
            $rotate="rotate(80deg)"
            $zindex="-1"
          >
            <ImageComponent src={animationgreen} alt="hoserviceme" />
          </Styledimg>
          <StyledMUIGridReverse item xs={12} sm={6} justifyContent="center">
            <ImageComponent src={nosotros5} alt="" />
          </StyledMUIGridReverse>
          <Grid item xs={12} sm={6}>
            <Titule>{t("weContainer_5.title_1")} </Titule>
            <Text>{t("weContainer_5.description_1")}</Text>
          </Grid>
        </StyledMUIGridContainer>
        {/* <StyledMUIGridContainer
          container
          justifyContent="center"
          alignItems="center"
        >
          <StyledMUIGridImageContainer item xs={12} justifyContent="center">
            <img src={nosotros6} alt="" />
            <img src={nosotros7} alt="" />
            <img src={nosotros8} alt="" />
          </StyledMUIGridImageContainer>
          <Grid item xs={12} sm={9}>
            <Text fontSize="30px" textAlign="center">
              Edupayments hace parte del programa Sydney Scaleup Hub facilitado
              por Stone and Chalk y NSW Investment.
            </Text>
          </Grid>
        </StyledMUIGridContainer> */}
        <Footer />
      </BodyContainer>
    </>
  );
};
