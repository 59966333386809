import { Grid } from "@mui/material";
import React from "react";
import { GetStatsComponents } from "../Helpers/GetStatsComponents";
import { DashboardStats } from "../Components";
import { useUserStore } from "../../../store";

export const AgencyDashboard = () => {
  const { user } = useUserStore();
  const options = GetStatsComponents(user.agency.id);
  return (
    <>
      <Grid container justifyContent={"center"} spacing={2} mt={20}>
        {options.map((item, index) => {
          return (
            <Grid item key={index} xs={4} md={4}>
              <DashboardStats
                key={item.componentName}
                label={item.label}
                url={item.url}
                field={item.field}
              />
            </Grid>
          );
        })}
      </Grid>
    </>
  );
};
