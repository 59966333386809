import { Box } from "@mui/material";
import styled from "styled-components";
import theme from "../../../theme";

export const StyledMUIBoxLogin = styled(Box)`
  && {
    border: 1px solid;
    border-color: ${theme.Palette.octonary};
    border-radius: 25px;
    background-color: ${theme.Palette.quinary};
    padding: 20px;
  }
`;
