import { ListItemButton } from "@mui/material";
import styled from "styled-components";
import theme from "../theme";

export const StyledListItemButton = styled(ListItemButton)`
  && {
    background-color: ${theme.Palette.tertiary};
    border-radius: 25px;
    text-transform: none;
    max-width: 80px;
    color: white;
    font-size: 12px;
    &:hover {
      background-color: ${theme.Palette.secondary};
    }
  }
`;
