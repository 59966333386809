import React from "react";
import { CrossDataGrid } from "../../CrossComponents";
import { useFetchGet } from "../../../Hooks/useFetchGet";
import {
  StyledGridActionsCellItem,
  StyledMUIButtonIcon,
} from "../../../StyledCrossComponents";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import { useNavigate } from "react-router-dom";
import { Tooltip } from "@mui/material";
import ArticleIcon from "@mui/icons-material/Article";

export const AgencyList = () => {
  const { data } = useFetchGet("Agency");
  console.log(data);
  const navigate = useNavigate();
  const columns = [
    {
      headerName: "Acciones",
      field: "actions",
      type: "actions",
      flex: 1,
      renderCell: (params) => [
        <Tooltip title="Ver Usuarios">
          <StyledGridActionsCellItem
            key={`read-${params.row.id}`}
            label={"Ver"}
            icon={<ManageAccountsIcon />}
            onClick={() =>
              navigate("/Dashboard/AgencyUserList", {
                state: {
                  agencyId: params.row.id,
                  AgencyName: params.row.agencyName,
                },
              })
            }
          />
        </Tooltip>,
        <Tooltip title="Ver documento legal de la agencia">
          <StyledGridActionsCellItem
            key={`read-${params.row.id}`}
            label={"Ver"}
            icon={<ArticleIcon />}
            onClick={() => window.open(params.row.imageFullPath, "_blank")}
          />
        </Tooltip>,
      ],
    },
    {
      field: "agencyName",
      headerName: "Nombre de la Agencia",
      headerAlign: "center",
      align: "center",
      flex: 1,
    },
    {
      field: "country",
      headerName: "Pais",
      headerAlign: "center",
      align: "center",
      flex: 1,
      valueGetter: (params) => {
        return params.row.country.countryName;
      },
    },
    {
      field: "city",
      headerName: "Ciudad",
      headerAlign: "center",
      align: "center",
      flex: 1,
      valueGetter: (params) => {
        return params.row.city.nameCity;
      },
    },
    {
      field: "adress",
      headerName: "Dirección de la Agencia",
      headerAlign: "center",
      align: "center",
      flex: 1,
    },
    {
      field: "agencyPhoneNumber",
      headerName: "Teléfono de la Agencia",
      headerAlign: "center",
      align: "center",
      flex: 1,
    },
    {
      field: "checkPartners",
      headerName: "Socios con 25% o más",
      headerAlign: "center",
      align: "center",
      flex: 1,
      renderCell: (params) => {
        return params.value ? "Si" : "No";
      },
    },

    // {
    //   field: "agencyEmail",
    //   headerName: "Correo de la Agencia",
    //   headerAlign: "center",
    //   align: "center",
    //   flex: 1,
    // },
  ];

  return (
    <>
      <CrossDataGrid title="" columns={columns} data={data} rowId={"id"} />
    </>
  );
};
