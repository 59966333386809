import React from "react";
import { Navigate } from "react-router-dom";
import { useUserStore } from "../../store";

export const ProtectedRoute = ({ children }) => {
  const { user } = useUserStore();
  const isAuthenticaded = () => {
    return user !== null ? true : false;
  };
  return isAuthenticaded() ? children : <Navigate to="/Login" />;
};
