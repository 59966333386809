import styled from "styled-components";
import theme from "../theme";
import { Select } from "@mui/material";

export const StyledMUISelectDataGrid = styled(Select)`
  && {
    &.MuiInputBase-root {
      border-radius: 25px;
      height: 25px;
    }
    &.MuiInput-underline:after {
      border-bottom-color: ${theme.Palette.octonary};
    }
    &.MuiOutlinedInput-root {
      & fieldset {
        border-color: ${theme.Palette.octonary};
      }
      &:hover fieldset {
        border-color: ${theme.Palette.septenary};
      }
      &.Mui-focused fieldset {
        border-color: ${theme.Palette.septenary};
      }
    }
  }
`;
