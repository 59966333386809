import { Button, Grid } from "@mui/material";
import React from "react";
import { StyledMUIButton } from "../Styled-components";

export const SubmitLoginButton = (props) => {
  return (
    <Grid item>
      <StyledMUIButton {...props} $width={"100%"}>
        {props.name}
      </StyledMUIButton>
    </Grid>
  );
};
