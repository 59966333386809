import {
  Button,
  FormControl,
  FormHelperText,
  Grid,
  Typography,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { Controller } from "react-hook-form";
import FolderIcon from "@mui/icons-material/Folder";
import {
  StyledMUIButtonIcon,
  StyledSpan,
} from "../../../StyledCrossComponents";

export const SelectFile = ({
  item,
  errors,
  control,
  formValues,
  setValue,
  label,
}) => {
  let documentTypeId = 0;
  if (item.name === "documentFile") {
    documentTypeId = formValues.legalIdentificationTypeId || item.value;
  } else if (item.name == "myFile") {
    documentTypeId = formValues.agencyIdentificationTypeId || item.value;
  }

  const hiddenFileInput = useRef(null);
  const [nameFile, setNameFile] = useState("");
  const handleClick = () => {
    hiddenFileInput.current.click();
  };
  const [fileName, setFileName] = useState([]);

  return (
    <>
      <Grid item xs={item.xs} justifyContent={"center"}>
        <Controller
          name={item.name}
          control={control}
          render={({ field }) => (
            <FormControl fullWidth>
              <StyledMUIButtonIcon
                key={`text-button-${item.name}`}
                onClick={handleClick}
                variant="contained"
                startIcon={<FolderIcon />}
              >
                {getFileName(documentTypeId, item)}
              </StyledMUIButtonIcon>
              <input
                label={item.label}
                type={item.type}
                size={item.size}
                ref={hiddenFileInput}
                multiple
                onChange={(e) => {
                  field.onChange(e.target.files);
                  setFileName(Array.from(e.target.files));
                }}
                style={{ display: "none" }}
              />
              <Typography
                variant="caption"
                color="text.secondary"
                textAlign={"center"}
              >
                <StyledSpan>Archivos Adjuntos:</StyledSpan>
                {fileName.map((item, index) => (
                  <Grid item xs={12} key={index}>
                    <StyledSpan>{item.name}</StyledSpan>
                  </Grid>
                ))}
              </Typography>
              {errors[item.name] && (
                <FormHelperText id="my-helper-text" error>
                  {errors[item.name]["message"]}
                </FormHelperText>
              )}
            </FormControl>
          )}
        />
      </Grid>
    </>
  );
};

const getFileName = (docuemntTypeId, item) => {
  switch (docuemntTypeId) {
    case 1:
      return item.label_3;
    case 2:
      return item.label_1;
    case 3:
      return item.label_6;
    case 4:
      return item.label_5;
    case 5:
      return item.label_4;
    case 6:
      return item.label_2;
    default:
      break;
  }
};
