import React from "react";
import RegisterSteps from "../Components/RegisterSteps";
import usePageTracking from "../../../Hooks/usePageTracking";

export const RegisterAgency = () => {
  usePageTracking("RegisterAgency");
  return (
    <>
      <RegisterSteps />
    </>
  );
};
