import React, { useState } from "react";
import {
  StyledMUIBox,
  StyledMUITextField,
} from "../../../StyledCrossComponents";
import styled from "styled-components";
import { Button, Grid, Typography } from "@mui/material";
import theme from "../../../theme";
import { CrossInputText, CrossLoader } from "../../CrossComponents";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useValidateForm } from "../../../Hooks/useValidateForm";
import { getDataForm } from "../Helpers/GetDataPreRegister";
import { useFetchPost } from "../../../Hooks/useFetchPost";
import { useStorageModal } from "../../../store";
import { set } from "date-fns";

export const FormPreRegistro = () => {
  const getFormData = getDataForm();
  const { validationshema, initialValues } = useValidateForm(getFormData);
  const { SubmitFormJson } = useFetchPost();
  const [loading, setLoading] = useState(false);
  const { setModalCross } = useStorageModal();
  const {
    handleSubmit,
    formState: { errors },
    control,
  } = useForm({
    defaultValues: initialValues,
    resolver: yupResolver(validationshema),
  });

  const onSubmit = (data) => {
    setModalCross({
      openModal: true,
      component: () => <CrossLoader />,
      setValue: () => {},
      formValues: {},
    });

    SubmitFormJson(data, "Agency/PreRegisterByAgency").then((response) => {
      if (!response.ok) {
        setModalCross({
          openModal: false,
          component: () => {},
          setValue: () => {},
          formValues: {},
        });
        return;
      }
      setModalCross({
        openModal: false,
        component: () => {},
        setValue: () => {},
        formValues: {},
      });
      setLoading(true);
    });
  };

  return (
    <>
      <StyledMUIBox>
        {loading && (
          <Typography
            sx={{
              fontSize: "14px",
            }}
            align="center"
            color={"green"}
          >
            Pre-Registro enviado
          </Typography>
        )}

        <StyledFormPreRegistro noValidate onSubmit={handleSubmit(onSubmit)}>
          <Grid
            container
            spacing={2}
            justifyContent={"center"}
            alignItems={"center"}
            mt={5}
          >
            {getFormData.map((item) => {
              switch (item.componenttype) {
                case "input":
                  return (
                    <CrossInputText
                      key={item.name}
                      errors={errors}
                      control={control}
                      item={item}
                    />
                  );
              }
            })}
          </Grid>
          <StyledMUIButton type="submit">Enviar</StyledMUIButton>
        </StyledFormPreRegistro>
      </StyledMUIBox>
    </>
  );
};

const StyledFormPreRegistro = styled.form`
  && {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
`;

const StyledMUIButton = styled(Button)`
  && {
    border-radius: 96px;
    width: 137px;
    height: 54px;
    text-transform: none;
    background-color: ${theme.Palette.quaternary};
    color: #ffffff;
    margin-top: 20px;
  }
`;
