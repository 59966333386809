import React from "react";
import { useStorageModal } from "../../../store";
import { useFetchGet } from "../../../Hooks/useFetchGet";
import { CrossDataGrid } from "../../CrossComponents";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import { StyledGridActionsCellItem } from "../../../StyledCrossComponents";
import { ChangeStateAudCop } from "./ChangeStateAudCop";
import { DetailedAudCopPayment } from "../../PersonPayments/Components/DetailedAudCopPayment";
import styled from "styled-components";
import { Chip } from "@mui/material";
import { min } from "date-fns";
import { minWidth } from "@mui/system";

export const PaymentListAudCop = () => {
  const { setModalCross } = useStorageModal();
  const userLanguage = navigator.language || navigator.userLanguage;
  const columns = [
    {
      headerName: "Acciones",
      field: "actions",
      type: "actions",
      renderCell: (params) => [
        <StyledGridActionsCellItem
          label={"Ver"}
          icon={<AddCircleIcon />}
          key={`read-${params.row.id}`}
          onClick={() => handleSelectRow(params.row)}
        />,
      ],
    },
    {
      field: "paymentReference",
      headerName: "Referencia de pago",
      headerAlign: "center",
      align: "center",
      flex: 2,
      minWidth: 200,
    },
    {
      field: "beneficiaryFullName",
      headerName: "Beneficiario",
      headerAlign: "center",
      align: "center",
      flex: 2,
      minWidth: 200,
    },
    {
      field: "date",
      headerName: "Fecha de pago",
      headerAlign: "center",
      align: "center",
      flex: 1,
      minWidth: 100,
      renderCell: (params) => {
        const date = new Date(params.row.date);
        return date.toLocaleDateString(userLanguage);
      },
    },
    {
      field: "exchangeRate",
      headerName: "Tasa de cambio",
      headerAlign: "center",
      align: "center",
      flex: 1,
      minWidth: 50,
      renderCell: (params) => {
        return (
          <>
            {params.row.exchangeRate.toLocaleString(userLanguage, {
              style: "currency",
              currency: params.row.currency || "COP",
            })}
          </>
        );
      },
    },
    {
      field: "estimatePayment",
      headerName: "Valor del pago",
      headerAlign: "center",
      align: "center",
      flex: 1,
      minWidth: 150,
      renderCell: (params) => {
        return (
          <>
            {params.row.estimatePayment.toLocaleString(userLanguage, {
              style: "currency",
              currency: "COP",
            })}
          </>
        );
      },
    },

    {
      field: "stateId",
      headerName: "Estado del pago",
      headerAlign: "center",
      align: "left",
      flex: 2,
      minWidth: 50,
      renderCell: (params) => (
        <ChangeStateAudCop
          props={params}
          filter={[1, 5]}
          controller={"PerPaymentAudCop"}
        />
      ),
    },
  ];

  const handleSelectRow = (row) => {
    setModalCross({
      openModal: true,
      component: () => <DetailedAudCopPayment row={row} />,
      setValue: () => {},
      formValues: {},
    });
  };

  const { data } = useFetchGet("PerPaymentAudCop");
  return (
    <>
      <CrossDataGrid
        title={"AUD - COP"}
        columns={columns}
        data={data}
        rowId={"paymentReference"}
        sortModel={[
          {
            field: "date",
            sort: "desc",
          },
        ]}
      />
    </>
  );
};

const StyledChip = styled(Chip)`
  width: 180px;
`;
