import React, { useEffect, useRef, useState } from "react";
import {
  DivContainer,
  ImageServiceComponent,
  StyledCheckCircleIcon,
  StyledMUIButton,
  StyledMUIGridContainer,
  Styledimg,
  Text,
  Titule,
} from "../Styled-components/index";
import { Grid } from "@mui/material";
import ImageComponent from "../Styled-components/StyledImageComponent";
import theme from "../../../theme";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { animationblue } from "../../../assets";
import { subservice2 } from "../../../assets";

export const SettleSection = () => {
  const [animate, setAnimate] = useState(false);
  const ref = useRef();
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (setAnimate(entry.isIntersecting)) {
          setAnimate(true);
          setTimeout(() => setAnimate(false), 1000);
        }
      },
      { threshold: 0.5 } // Se activa cuando el 50% del elemento es visible
    );

    if (ref.current) {
      observer.observe(ref.current);
    }

    return () => {
      if (ref.current) {
        observer.unobserve(ref.current);
      }
    };
  }, []);
  return (
    <StyledMUIGridContainer
      id="liquida-en-linea"
      container
      spacing={2}
      alignItems="center"
      justifyContent="center"
      mt={10}
      sx={{
        backgroundColor: theme.Palette.senary,
      }}
      ref={ref}
    >
      <Styledimg
        $top="140px"
        $left="980px"
        $rotate="rotate(310deg)"
        $animate={animate}
      >
        <ImageComponent src={animationblue} alt="hoserviceme" />
      </Styledimg>
      <Grid item xs={12} sm={5}>
        <Titule>{t("subServiceContainer_2.title")}</Titule>
        <Text>{t("subServiceContainer_2.description_1")}</Text>
        <DivContainer>
          <StyledCheckCircleIcon />
          <Text>{t("subServiceContainer_2.description_2")}</Text>
        </DivContainer>
        <DivContainer>
          <StyledCheckCircleIcon />
          <Text>{t("subServiceContainer_2.description_3")}</Text>
        </DivContainer>
        <DivContainer>
          <StyledCheckCircleIcon />
          <Text>{t("subServiceContainer_2.description_4")}</Text>
        </DivContainer>
        <DivContainer>
          <StyledCheckCircleIcon />
          <Text>{t("subServiceContainer_2.description_5")}</Text>
        </DivContainer>
        <StyledMUIButton onClick={() => navigate("/PreRegistro")} $width="50%">
          {t("subServiceContainer_2.button")}
        </StyledMUIButton>
      </Grid>
      <Grid
        item
        xs={12}
        sm={7}
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <ImageServiceComponent src={subservice2} alt="edupayments" />
      </Grid>
    </StyledMUIGridContainer>
  );
};
