import React from "react";
import { useStorageModal, useUserStore } from "../../../store";
import { StyledGridActionsCellItem } from "../../../StyledCrossComponents";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import { DetailedAudCopPayment } from "./DetailedAudCopPayment";
import { useFetchGet } from "../../../Hooks/useFetchGet";
import { CrossDataGrid } from "../../CrossComponents";
import { minWidth } from "@mui/system";
import { min } from "date-fns";

export const PersonPaymentListAudCop = () => {
  const { user } = useUserStore();
  const { setModalCross } = useStorageModal();
  const userLanguage = navigator.language || navigator.userLanguage;
  const columns = [
    {
      headerName: "Acciones",
      field: "actions",
      type: "actions",
      renderCell: (params) => [
        <StyledGridActionsCellItem
          label={"Ver"}
          icon={<AddCircleIcon />}
          key={`read-${params.row.id}`}
          onClick={() => handleSelectRow(params.row)}
        />,
      ],
    },
    {
      field: "paymentReference",
      headerName: "Referencia de pago",
      headerAlign: "center",
      align: "center",
      //flex: 2,
      minWidth: 200,
    },
    {
      field: "beneficiaryFullName",
      headerName: "Beneficiario",
      headerAlign: "center",
      align: "center",
      //flex: 2,
      minWidth: 200,
    },
    {
      field: "date",
      headerName: "Fecha de pago",
      headerAlign: "center",
      align: "center",
      //flex: 1,
      minWidth: 200,
      renderCell: (params) => {
        const date = new Date(params.row.date);
        return date.toLocaleDateString(userLanguage);
      },
    },
    {
      field: "exchangeRate",
      headerName: "Tasa de cambio",
      headerAlign: "center",
      align: "center",
      //flex: 1,
      minWidth: 200,
      renderCell: (params) => {
        return params.row.exchangeRate.toFixed(2);
      },
    },
    {
      field: "estimatePayment",
      headerName: "Valor del pago",
      headerAlign: "center",
      align: "center",
      //flex: 1,
      minWidth: 200,
      renderCell: (params) => {
        return (
          <>
            {params.row.estimatePayment.toLocaleString(userLanguage, {
              style: "currency",
              currency: "COP",
            })}
          </>
        );
      },
    },
    {
      field: "stateName",
      headerName: "Estado",
      headerAlign: "center",
      align: "center",
      //flex: 1,
      minWidth: 200,
      // renderCell: (params) => {
      //   return params.row.exchangeRate.toFixed(2);
      // },
    },
  ];

  const handleSelectRow = (row) => {
    setModalCross({
      openModal: true,
      component: () => <DetailedAudCopPayment row={row} />,
      setValue: () => {},
      formValues: {},
    });
  };
  const { data } = useFetchGet(`PerPaymentAudCop/user/${user.userId}`);

  console.log(data);

  return (
    <>
      <CrossDataGrid
        title={"AUD - COP"}
        columns={columns}
        data={data}
        rowId={"paymentReference"}
        sortModel={[
          {
            field: "date",
            sort: "desc",
          },
        ]}
      />
    </>
  );
};
