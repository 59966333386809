import React from "react";

export const CrossCurrencyFormatter = ({ value, currency }) => {
  const userLanguage = navigator.language || navigator.userLanguage;
  const formattedValue = new Intl.NumberFormat(userLanguage, {
    style: "currency",
    currency: currency || "AUD",
  }).format(value);

  return <span>{formattedValue}</span>;
};
