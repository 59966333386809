import React, { useState } from "react";
import { useValidateForm } from "../../../Hooks/useValidateForm";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { CrossInputText } from "../../CrossComponents";
import { Grid } from "@mui/material";
import theme from "../../../theme";
import {
  StyledMUIBoxContainerForm,
  StyledMUIButton,
} from "../../../StyledCrossComponents";
import { useFetchPut } from "../../../Hooks/useFetchPut";
import { useStorageModal, useStoreModalNotification } from "../../../store";

export const ExchangeForm = ({ rowData, setData }) => {
  const [newExchange, setNewExchange] = useState(rowData);
  const { resetModalCross } = useStorageModal();
  const { setModalAlert } = useStoreModalNotification();
  const { SubmitUpdate } = useFetchPut();
  const exchangeForm = [
    {
      type: "text",
      name: "exchangeAgency",
      label: "Agency exchange",
      value: rowData.exchangeAgency,
      xs: 12,
      iconname: "",
      validations: [
        {
          type: "required",
        },
      ],
    },
    {
      type: "text",
      name: "exchange",
      label: "Person Exchange",
      value: rowData.exchange,
      xs: 12,
      iconname: "",
      validations: [
        {
          type: "required",
        },
      ],
    },
  ];
  const { validationshema, initialValues } = useValidateForm(exchangeForm);
  const {
    handleSubmit,
    formState: { errors },
    control,
  } = useForm({
    defaultValues: initialValues,
    resolver: yupResolver(validationshema),
  });

  const onSubmit = (data) => {
    const newData = {
      ...newExchange,
      exchangeAgency: data.exchangeAgency,
      exchange: data.exchange,
    };
    SubmitUpdate(newData, `ExchangeRate/${rowData.id}`).then((response) => {
      if (!response.ok) {
        resetModalCross();
      }
      setData((prevData) =>
        prevData.map((item) => (item.id === rowData.id ? newData : item))
      );
      resetModalCross();
      setModalAlert({
        openModal: true,
        message: "Exchange rate updated successfully",
        severity: "success",
        variant: "filled",
      });
    });
  };

  return (
    <>
      <StyledMUIBoxContainerForm height="100vh">
        <form noValidate onSubmit={handleSubmit(onSubmit)}>
          <Grid container justifyContent={"center"} spacing={2} p={5}>
            {exchangeForm.map((item, index) => (
              <Grid item xs={item.xs} key={index}>
                <CrossInputText
                  key={item.name}
                  control={control}
                  errors={errors}
                  item={item}
                />
              </Grid>
            ))}
            <Grid container justifyContent={"flex-end"} mt={2}>
              <Grid item>
                <StyledMUIButton
                  type="submit"
                  backgroundcolor={theme.Palette.tertiary}
                >
                  Guardar
                </StyledMUIButton>
              </Grid>
            </Grid>
          </Grid>
        </form>
      </StyledMUIBoxContainerForm>
    </>
  );
};
