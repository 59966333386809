import React from "react";
import {
  StyledHr,
  StyledMUIBoxContainerForm,
  StyledMUIButton,
} from "../../../StyledCrossComponents";
import { Stack, Typography } from "@mui/material";
import { useStoreFromPayment, useStorePaymentStep } from "../../../store";
import theme from "../../../theme";
import styled from "styled-components";

export const PersonPaymentSumary = ({ formValues }) => {
  const userLanguage = navigator.language || navigator.userLanguage;
  const store = useStorePaymentStep();
  const { fromPaymentState } = useStoreFromPayment();
  let fromAmount =
    fromPaymentState.fromPayment !== "COP"
      ? formValues.estimatePayment
      : formValues.targetAmount;
  let toAmount =
    fromPaymentState.fromPayment !== "COP"
      ? formValues.targetAmount
      : formValues.estimatePayment;

  return (
    <>
      <StyledMUIBoxContainerForm width="100%">
        <Typography
          variant="h6"
          gutterBottom
          sx={{ width: "300px", color: theme.Palette.tertiary }}
        >
          Resumen
        </Typography>
        <StyledHr />
        <Stack
          direction={"row"}
          alignItems={"center"}
          justifyContent={"space-between"}
        >
          <StyledTypogrphy gutterBottom component="div">
            Tipo de pago:
          </StyledTypogrphy>
          <Typography gutterBottom variant="body2" component="div">
            {store.paymentStepState.paymentTypeDescription}
          </Typography>
        </Stack>
        <Stack
          direction={"row"}
          alignItems={"center"}
          justifyContent={"space-between"}
        >
          <StyledTypogrphy gutterBottom variant="h6" component="div">
            Nombre del beneficiario:
          </StyledTypogrphy>
          <Typography gutterBottom variant="body2" component="div">
            {`${formValues.BeneficiaryFirstName} ${formValues.BeneficiaryLastName}`}
          </Typography>
        </Stack>
        <Stack
          direction={"row"}
          alignItems={"center"}
          justifyContent={"space-between"}
        >
          <StyledTypogrphy gutterBottom variant="h6" component="div">
            Monto en {store.paymentStepState.currency || "AUD"}:
          </StyledTypogrphy>
          <Typography gutterBottom variant="body2" component="div">
            {fromAmount.toLocaleString(userLanguage, {
              style: "currency",
              currency: store.paymentStepState.currency || "AUD",
              // currencyDisplay: "code",
            })}
          </Typography>
        </Stack>

        <Stack
          direction={"row"}
          alignItems={"center"}
          justifyContent={"space-between"}
        >
          <StyledTypogrphy gutterBottom variant="h6" component="div">
            Monto en (COP):
          </StyledTypogrphy>
          <Typography gutterBottom variant="body2" component="div">
            {toAmount.toLocaleString(userLanguage, {
              style: "currency",
              currency: "COP",
            })}
          </Typography>
        </Stack>

        <Stack
          direction={"row"}
          alignItems={"center"}
          justifyContent={"space-between"}
        >
          <StyledTypogrphy gutterBottom variant="h6" component="div">
            Tasa de cambio {store.paymentStepState.acronym}:
          </StyledTypogrphy>
          <Typography gutterBottom variant="body2" component="div">
            {formValues.exchangeRate.toLocaleString(userLanguage, {
              style: "currency",
              currency: store.paymentStepState.acronym || "AUD",
              currencyDisplay: "code",
            })}
          </Typography>
        </Stack>
        {/* <Stack
          direction={"row"}
          alignItems={"center"}
          justifyContent={"space-between"}
        >
          <StyledMUIButton onClick={handleBack}>Regresar</StyledMUIButton>
          <StyledMUIButton type="submit" onClick={onSubmit}>
            Enviar Pago
          </StyledMUIButton>
        </Stack> */}
      </StyledMUIBoxContainerForm>
    </>
  );
};

const StyledTypogrphy = styled(Typography)`
  && {
    color: ${theme.Palette.tertiary};
    font-size: small;
    font-weight: bold;
  }
`;
