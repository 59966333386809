import React from "react";
import { useStorageModal } from "../../../store";
import { useFetchGet } from "../../../Hooks/useFetchGet";
import { ChangeStateAudCop } from "./ChangeStateAudCop";
import { StyledGridActionsCellItem } from "../../../StyledCrossComponents";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import { CrossDataGrid } from "../../CrossComponents";
import { DetailedAudCopPayment } from "../../PersonPayments/Components/DetailedAudCopPayment";
import { DetailedCopAudPayment } from "../../PersonPayments/Components/DetailedCopAudPayment";
import { min } from "date-fns";
import { minWidth } from "@mui/system";

export const PaymentListCopAud = ({ userId }) => {
  const { setModalCross } = useStorageModal();
  const userLanguage = navigator.language || navigator.userLanguage;
  const columns = [
    {
      headerName: "Acciones",
      field: "actions",
      type: "actions",
      renderCell: (params) => [
        <StyledGridActionsCellItem
          label={"Ver"}
          icon={<AddCircleIcon />}
          key={`read-${params.row.id}`}
          onClick={() => handleSelectRow(params.row)}
        />,
      ],
    },
    {
      field: "paymentReference",
      headerName: "Referencia de pago",
      headerAlign: "center",
      align: "center",
      flex: 2,
      minWidth: 200,
    },
    {
      field: "beneficiaryFullName",
      headerName: "Beneficiario",
      headerAlign: "center",
      align: "center",
      flex: 2,
      minWidth: 200,
    },
    {
      field: "date",
      headerName: "Fecha de pago",
      headerAlign: "center",
      align: "center",
      flex: 1,
      minWidth: 150,
      renderCell: (params) => {
        const date = new Date(params.row.date);
        return date.toLocaleDateString(userLanguage);
      },
    },
    {
      field: "exchangeRate",
      headerName: "Tasa de cambio",
      headerAlign: "center",
      align: "center",
      flex: 1,
      minWidth: 150,
      renderCell: (params) => {
        return (
          <>
            {params.row.exchangeRate.toLocaleString(userLanguage, {
              style: "currency",
              currency: params.row.currency || "COP",
            })}
          </>
        );
      },
    },
    {
      field: "estimatePayment",
      headerName: "Valor del pago",
      headerAlign: "center",
      align: "center",
      flex: 1,
      minWidth: 150,
      renderCell: (params) => {
        return (
          <>
            {params.row.estimatePayment.toLocaleString(userLanguage, {
              style: "currency",
              currency: "COP",
            })}
          </>
        );
      },
    },
    {
      field: "stateId",
      headerName: "Estado del pago",
      headerAlign: "center",
      align: "center",
      flex: 2,
      minWidth: 150,
      renderCell: (params) =>
        userId != "undefined" ? (
          params.row.stateName
        ) : (
          <ChangeStateAudCop
            props={params}
            filter={[1, 2, 5]}
            controller={"PerPayment"}
            userId={userId}
          />
        ),
    },
  ];
  const handleSelectRow = (row) => {
    setModalCross({
      openModal: true,
      component: () => <DetailedCopAudPayment row={row} />,
      setValue: () => {},
      formValues: {},
    });
  };

  const { data } = useFetchGet(
    userId != "undefined"
      ? `PerPayment/GetPersonPaymentsByUserIdAsync/${userId}`
      : "PerPayment"
  );

  return (
    <>
      <CrossDataGrid
        title={"COP - AUD"}
        columns={columns}
        data={data}
        rowId={"paymentReference"}
        sortModel={[
          {
            field: "date",
            sort: "desc",
          },
        ]}
      />
    </>
  );
};
