import { Box, Grid, Step, StepLabel, Stepper, Typography } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";
import { StepForm } from "../../Home/Components";
import { getDataStepsRegPersons } from "../Helpers/getDataStepsRegPerson";
import theme from "../../../theme";
import { StepFormPersons } from "./StepFormPersons";

export const RegistrationPerSteps = () => {
  const [activeStep, setActiveStep] = React.useState(0);
  const [skipped, setSkipped] = React.useState(new Set());
  const navigate = useNavigate();
  const dataSteps = getDataStepsRegPersons();

  const isStepOptional = (step) => {
    return step === 5;
  };

  const isStepSkipped = (step) => {
    return skipped.has(step);
  };

  const handleNext = () => {
    let newSkipped = skipped;

    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStep);
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped(newSkipped);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    // setActiveStep(0);
    navigate("/");
  };

  return (
    <Grid
      container
      spacing={2}
      alignItems="center"
      justifyContent="center"
      sx={{
        //backgroundColor: theme.Palette.senary,
        fontFamily: theme.Font.primary,
        width: "auto",
        height: "auto",
      }}
    >
      <Grid
        container
        spacing={2}
        alignItems="center"
        justifyContent="center"
        sx={{ maxWidth: theme.Breakpoint.xlarge }}
      >
        {/* <Grid item mt={10}>
          <Typography variant="h4" fontFamily={theme.Font.primary}>
            Registrar Persona
          </Typography>
        </Grid> */}
        <Box mt={10} sx={{ width: "100%" }}>
          <Stepper activeStep={activeStep} alternativeLabel>
            {dataSteps.map((item, index) => {
              const stepProps = {};
              const labelProps = {};
              if (isStepOptional(index)) {
                labelProps.optional = (
                  <Typography variant="caption">Optional</Typography>
                );
              }
              if (isStepSkipped(index)) {
                stepProps.completed = false;
              }
              return (
                <Step key={item.label} {...stepProps}>
                  <StepLabel {...labelProps}>{item.label}</StepLabel>
                </Step>
              );
            })}
          </Stepper>
          {activeStep === dataSteps.length ? (
            navigate("/")
          ) : (
            <React.Fragment>
              <Grid
                container
                justifyContent={"center"}
                alignItems={"center"}
                mt={10}
              >
                <StepFormPersons
                  handleNext={handleNext}
                  handleBack={handleBack}
                  activeStep={activeStep}
                  dataStepActive={dataSteps[activeStep]}
                  dataSteps={dataSteps}
                />
              </Grid>
            </React.Fragment>
          )}
        </Box>
      </Grid>
    </Grid>
  );
};
