import { keyframes, styled } from "styled-components";
import theme from "../../../theme";
import { Link, Button } from "@mui/material";

export const NavBarContainer = styled.div`
  width: 100%;
  height: 80px;
  background-color: ${(props) =>
    props.$backgroundcolor ? props.$backgroundcolor : theme.Palette.quinary};
  position: sticky;
  top: 0;
  /* margin: 30px; */
  margin-bottom: 100px;
  z-index: 999;

  @media screen and (max-width: 768px) {
    background-color: ${(props) =>
      props.$backgroundcolor ? props.$backgroundcolor : theme.Palette.quinary};
    margin: 0;
  }
`;

export const NavBarWrapper = styled.div`
  width: 100%;
  height: 100%;
  max-width: 1300px;
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: ${(props) =>
    props.justifycontent ? props.$justifycontent : "space-between"};
  align-items: center;
  position: relative;
`;
export const StyledMUINavBarButton = styled(Button)`
  && {
    border-radius: 96px;
    width: 137px;
    height: 54px;
    text-transform: none;
    color: ${theme.Palette.quinary};
    background-color: ${theme.Palette.quaternary};

    &:hover {
      background-color: ${theme.Hover.primary};
      color: ${theme.Palette.quinary};
    }
  }
`;

export const StyledMUINavbarLink = styled(Link)`
  && {
    background-color: transparent;
    cursor: pointer;
    text-decoration-line: none;
  }
`;

export const NavBarWrapperLenguage = styled.div`
  width: 100%;
  /* height: 100%; */
  max-width: 1300px;
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
`;

export const NavBarLogo = styled.div`
  color: #fff;
  font-size: 1.5rem;
  font-weight: 700;
  cursor: pointer;
  display: flex;
  align-items: center;
  text-decoration: none;
  width: 300px;
  height: 80px;

  @media screen and (max-width: 768px) {
    display: none;
  }
`;

export const NavBarMenu = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  @media screen and (max-width: 768px) {
    width: 100%;
    height: 60vh;
    position: absolute;
    top: 80px;
    //left: ${({ click }) => (click ? 0 : "-105%")};
    left: ${(props) => (props.$showmenu ? 0 : "-105%")};
    flex-direction: column;
    background-color: #fff;
    transition: all 0.5s ease-in-out;
    text-align: center;
  }
`;

export const NavBarMenuItem = styled.div`
  color: #09051a;
  line-height: 20px;
  font-style: normal;
  font-size: 18px;
  font-weight: 500;
  font-family: ${theme.Font.primary};
  cursor: pointer;
  margin: 0 10px;
  transition: all 0.3s ease-in-out;
  &:hover {
    color: #f9a826;
    /* background-color: #fff; */
    border-bottom: 2px solid #f9a826;
    transition: all 0.3s ease-in-out;
  }

  @media screen and (max-width: 768px) {
    width: 100%;
    height: 70px;
  }
`;
export const NavBarButton = styled.div`
  display: none;
  @media screen and (max-width: 768px) {
    display: flex;
    color: #09051a;
  }
`;

export const NavBarButtonLink = styled.div`
  color: #fff;
  font-size: 1rem;
  font-weight: 400;
  cursor: pointer;
  margin: 0 10px;
  transition: all 0.3s ease-in-out;
  &:hover {
    color: #f9a826;
  }
`;
