import { styled } from "styled-components";

export const ServiceContainer = styled.div`
  width: 100%;
  height: 100%;
  background-color: #fff;
  max-width: 1500px;
  margin: 0 auto;
  margin-top: 60px;
`;

export const ImageServiceComponent = styled.img`
  width: 70%;
  height: 70%;
`;
