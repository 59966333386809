import React from "react";
import { useStoreModalNotification } from "../../store";
import { Alert, Grid, IconButton, Modal } from "@mui/material";
import ClosIcon from "@mui/icons-material/Close";

export const CrossModalNotification = () => {
  const state = useStoreModalNotification((state) => state.modalalert);
  const resetModalAlert = useStoreModalNotification(
    (state) => state.resetModalAlert
  );

  const sx = {
    height: "100px",
    width: "auto",
    borderRadius: "25px",
    textAlign: "center",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    fontSize: "20px",
  };

  const handleClose = () => {
    resetModalAlert();
  };
  return (
    <Modal open={state.openModal} onClose={handleClose}>
      <Grid
        container
        sx={{ height: "100vh" }}
        justifyContent="center"
        alignItems="center"
      >
        <Alert
          variant={state.variant}
          severity={state.severity}
          sx={sx}
          action={
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={handleClose}
            >
              <ClosIcon fontSize="inherit" />
            </IconButton>
          }
        >
          {state.message}
        </Alert>
      </Grid>
    </Modal>
  );
};
