import React from "react";
import { useFetchGet } from "../../../Hooks/useFetchGet";
import { CrossDataGrid } from "../../CrossComponents";

export const PayUpList = () => {
  const userLanguage = navigator.language || navigator.userLanguage;
  const columns = [
    {
      field: "Type",
      headerName: "Tipo de pago",
      headerAlign: "center",
      align: "center",
      renderCell: (params) => {
        return <>TOPUP</>;
      },
      flex: 1,
    },
    {
      field: "transactionId",
      headerName: "Id Transacción",
      headerAlign: "center",
      align: "center",
      flex: 1,
    },
    {
      field: "accountName",
      headerName: "Cuenta Destino",
      headerAlign: "center",
      align: "center",
      flex: 2,
    },
    {
      field: "sourceAccountName",
      headerName: "Cuenta origen",
      headerAlign: "center",
      align: "center",
      flex: 2,
    },
    {
      field: "sourceBsb",
      headerName: "Bsb origen",
      headerAlign: "center",
      align: "center",
      flex: 1,
    },
    {
      field: "sourceAccountNumber",
      headerName: "Número Cuenta origen",
      headerAlign: "center",
      align: "center",
      flex: 2,
    },
    {
      field: "dateTime",
      headerName: "Fecha de transacción",
      headerAlign: "center",
      align: "center",
      flex: 1,
      renderCell: (params) => {
        const date = new Date(params.row.dateTime);
        return date.toLocaleDateString(userLanguage);
      },
    },
    {
      field: "amount",
      headerName: "Valor transacción",
      headerAlign: "center",
      align: "center",
      flex: 1,
      renderCell: (params) => {
        return (
          <>
            {params.row.amount.toLocaleString(userLanguage, {
              style: "currency",
              currency: "AUD",
            })}
          </>
        );
      },
    },
    {
      field: "State",
      headerName: "Estado",
      headerAlign: "center",
      align: "center",
      renderCell: (params) => {
        return <>Recibido</>;
      },
      flex: 1,
    },
  ];

  const response = useFetchGet("NppPaymentUp");

  return (
    <>
      <CrossDataGrid
        title={"Lista de transacciones"}
        columns={columns}
        data={response.data}
        rowId={"id"}
        sortModel={[
          {
            field: "dateTime",
            sort: "desc",
          },
        ]}
      />
    </>
  );
};
