import React from "react";
import { PaymentList } from "../Components/PaymentList";

export const PaymentAdmin = () => {
  return (
    <>
      <PaymentList />
    </>
  );
};
