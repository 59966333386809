import { useNavigate } from "react-router-dom";
import { useValidateForm } from "../../../Hooks/useValidateForm";
import { useStoreModalNotification } from "../../../store";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useFetchPost } from "../../../Hooks/useFetchPost";
import {
  StyledMUIBoxContainerForm,
  StyledMUIButton,
} from "../../../StyledCrossComponents";
import { Box, Grid } from "@mui/material";
import {
  CrossCheck,
  CrossInputText,
  CrossModalNotification,
  CrossSelectList,
} from "../../CrossComponents";

import theme from "../../../theme";
import { SelectTypeIdeAgency } from "../../Home/Components";

export const StepFormPersons = ({
  handleNext,
  handleBack,
  activeStep,
  dataSteps,
  dataStepActive,
}) => {
  const { validationshema, initialValues } = useValidateForm(
    dataStepActive.form
  );

  const setModalAlert = useStoreModalNotification(
    (state) => state.setModalAlert
  );

  const navigate = useNavigate();

  const {
    handleSubmit,
    formState: { errors },
    control,
    watch,
    setValue,
    register,
  } = useForm({
    defaultValues: initialValues,
    resolver: yupResolver(validationshema),
  });

  let formValues = watch();

  const { SubmitFormJson } = useFetchPost();
  const onSubmit = () => {
    handleNext();

    if (activeStep !== dataSteps.length - 1) return;
    SubmitFormJson(formValues, "Account/AddUser").then((response) => {
      if (!response.ok) {
        setModalAlert({
          openModal: true,
          message: "Error en al crear el usuario",
          severity: "error",
          variant: "filled",
        });
        return;
      }
      setModalAlert({
        openModal: true,
        message:
          "Usuario creado correctamente, se ha enviado un correo para su confirmacion.",
        severity: "success",
        variant: "filled",
      });
    });
  };

  return (
    <>
      <Box
        bgcolor={theme.Palette.nonary}
        p={5}
        borderRadius={"25px"}
        border={"0px solid"}
        width={{ xs: "100%", sm: "50%" }}
      >
        <form noValidate onSubmit={handleSubmit(onSubmit)}>
          <Grid container justifyContent="center" spacing={2} p={5}>
            {dataStepActive.form.map((item) => {
              switch (item.componenttype) {
                case "input":
                  return (
                    <CrossInputText
                      key={item.name}
                      errors={errors}
                      control={control}
                      item={item}
                    />
                  );
                case "selecttypeidentification":
                  return (
                    <SelectTypeIdeAgency
                      key={item.nameId ? item.nameId : item.name}
                      errors={errors}
                      control={control}
                      item={item}
                      formValues={formValues}
                      setValue={setValue}
                    />
                  );
                case "select":
                  return (
                    <CrossSelectList
                      key={item.nameId ? item.nameId : item.name}
                      errors={errors}
                      control={control}
                      item={item}
                      formValues={formValues}
                      setValue={setValue}
                    />
                  );
                case "check":
                  return (
                    <CrossCheck
                      key={item.name}
                      errors={errors}
                      control={control}
                      item={item}
                      register={register}
                    />
                  );
                // case "inputfile":
                //   return (
                //     <SelectFile
                //       key={item.name}
                //       errors={errors}
                //       control={control}
                //       item={item}
                //       formValues={formValues}
                //       setValue={setValue}
                //     />
                //   );
                default:
                  return null;
              }
            })}
            <Grid container justifyContent={"space-between"} mt={2}>
              <Grid item>
                <StyledMUIButton
                  backgroundcolor={theme.Palette.tertiary}
                  disabled={activeStep === 0}
                  onClick={handleBack}
                >
                  Regresar
                </StyledMUIButton>
              </Grid>
              <Grid item>
                <StyledMUIButton
                  type="submit"
                  backgroundcolor={theme.Palette.tertiary}
                >
                  {activeStep === dataSteps.length - 1
                    ? "Terminar"
                    : "Siguiente"}
                </StyledMUIButton>
              </Grid>
            </Grid>
          </Grid>
        </form>
      </Box>
      <CrossModalNotification />
    </>
  );
};
