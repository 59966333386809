import { Grid, Typography } from "@mui/material";
import React, { useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import { StyledMUITextField } from "../../../StyledCrossComponents";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { useStoreSeattlePaymentStep } from "../../../store";
import { set } from "date-fns";
import { getAccountInfo } from "../Helpers/getAccountInfo";

export const SettleAccountDataInput = ({ nameStep }) => {
  const { setSeattlePaymentStep, seattlePaymentStepState } =
    useStoreSeattlePaymentStep();
  const initialValues = {};
  const requiredFields = {};
  let shema = Yup.string();

  for (const key in inputForm) {
    if (inputForm[key].name === "company_name") {
      initialValues[inputForm[key].name] =
        nameStep === "agency"
          ? seattlePaymentStepState.agencyPayment.companyName
          : seattlePaymentStepState.beneficiaryPayment.companyName;
    } else {
      initialValues[inputForm[key].name] = inputForm[key].value;
    }

    if (inputForm[key].validations) {
      shema = shema.required("Este campo es requerido");
    }

    requiredFields[inputForm[key].name] = shema;
  }

  const validationshema = Yup.object({ ...requiredFields });

  const {
    formState: { errors },
    control,
    trigger,
    watch,
  } = useForm({
    defaultValues: initialValues,
    resolver: yupResolver(validationshema),
    mode: "onChange",
  });

  useEffect(() => {
    trigger();
  }, [inputForm]);

  const numberOfProperties = Object.keys(errors).length;

  useEffect(() => {
    switch (nameStep) {
      case "agency":
        setSeattlePaymentStep({
          agencyPayment: {
            errors: errors,
          },
        });
        break;
      case "beneficiary":
        setSeattlePaymentStep({
          beneficiaryPayment: {
            errors: errors,
          },
        });
        break;
      default:
        break;
    }
  }, [numberOfProperties]);

  const fieldToStateMap = {
    bsb: "bsb",
    account_number: "accountNumber",
    first_name: "firstName",
    last_name: "lastName",
    company_name: "companyName",
  };

  const handleChange = (e, name) => {
    e.preventDefault();
    const stateKey =
      nameStep === "agency" ? "agencyPayment" : "beneficiaryPayment";
    const fieldKey = fieldToStateMap[name];
    if (fieldKey) {
      setSeattlePaymentStep({
        [stateKey]: {
          [fieldKey]: e.target.value,
        },
      });
    }
  };

  return (
    <>
      <form>
        <Grid container justifyContent="center" spacing={2} pl={5} pr={5}>
          {inputForm &&
            inputForm.map((item) => {
              switch (item.componenttype) {
                case "text":
                  return (
                    <Grid item key={item.name} xs={12}>
                      <Controller
                        name={item.name}
                        control={control}
                        render={({
                          field: { onChange, onBlur, value, name },
                          fieldState: { error },
                        }) => (
                          <StyledMUITextField
                            value={value}
                            onChange={(e) => {
                              onChange(e.target.value);
                              handleChange(e, name);
                            }}
                            onBlur={onBlur}
                            size="small"
                            error={!!error}
                            label={item.label}
                            helperText={error ? error.message : null}
                            disabled={item.disabled}
                          />
                        )}
                      />
                    </Grid>
                  );

                case "NoShow":
                  return (
                    <Typography
                      key={item.name}
                      variant="h6"
                      gutterBottom
                      sx={{ display: "none" }}
                    />
                  );
                default:
                  return null;
              }
            })}
        </Grid>
      </form>
    </>
  );
};
const inputForm = [
  {
    componenttype: "text",
    label: "Nombre de la cuenta",
    type: "number",
    size: "small",
    name: "company_name",
    value: "CanoSoft",
    margin: "normal",
    xs: 12,
    disabled: true,
  },
  {
    componenttype: "text",
    label: "bsb",
    type: "number",
    size: "small",
    name: "bsb",
    value: "",
    margin: "normal",
    xs: 12,
    disabled: false,
    validations: [{ type: "required" }],
  },
  {
    componenttype: "text",
    label: "Número de cuenta",
    type: "number",
    size: "small",
    name: "account_number",
    value: "",
    margin: "normal",
    xs: 12,
    disabled: false,
    validations: [{ type: "required" }],
  },
];
