import React from "react";
import { PersonPaymentListAudCop } from "../Components/PersonPaymentListAudCop";

export const PersonPaymentList = () => {
  return (
    <>
      <PersonPaymentListAudCop />
    </>
  );
};
