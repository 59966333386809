import React from "react";
import { CreateUserForm } from "../Components/CreateUserForm";

export const CreateUserByAgency = () => {
  return (
    <>
      <CreateUserForm />
    </>
  );
};
