export const getDataStepsRegPersons = () => {
  return [
    {
      label: "Cuenta de usuario",
      step: 0,
      validations: [],
      form: [
        {
          componenttype: "input",
          label: "Email",
          type: "email",
          name: "email",
          value: "",
          size: "small",
          margin: "normal",
          xs: 12,
          validations: [
            {
              type: "required",
            },
            {
              type: "email",
              pattern: /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/,
            },
          ],
        },
        {
          componenttype: "input",
          label: "Contraseña",
          type: "password",
          name: "password",
          value: "",
          size: "small",
          margin: "normal",
          xs: 12,
          validations: [
            {
              type: "required",
            },
          ],
        },
        {
          componenttype: "input",
          label: "Confirmar contraseña",
          type: "password",
          name: "confirmPassword",
          value: "",
          size: "small",
          margin: "normal",
          xs: 12,
          validations: [
            {
              type: "required",
            },
            {
              type: "confirmPassword",
              name: "password",
            },
          ],
        },
      ],
    },
    {
      label: "Datos del usuario",
      step: 1,
      validations: [],
      form: [
        {
          componenttype: "selecttypeidentification",
          type: "select",
          label: "Tipo de identificación",
          size: "small",
          name: "id",
          filter: [1, 3, 4, 5],
          nameId: "IdentificationTypeId",
          description: "description",
          value: "1",
          margin: "normal",
          xs: 6,
          apiEndPoint: "IdentificationType",
          validations: [
            {
              type: "required",
            },
          ],
        },
        {
          componenttype: "input",
          label: "Número de identificación",
          type: "text",
          size: "small",
          name: "document",
          value: "",
          margin: "normal",
          xs: 6,
          validations: [
            {
              type: "required",
            },
          ],
        },
        {
          componenttype: "input",
          label: "Nombres",
          type: "text",
          size: "small",
          name: "firstName",
          value: "",
          margin: "normal",
          xs: 12,
          validations: [
            {
              type: "required",
            },
          ],
        },
        {
          componenttype: "input",
          label: "Apellidos",
          type: "text",
          size: "small",
          name: "lastName",
          value: "",
          margin: "normal",
          xs: 12,
          validations: [
            {
              type: "required",
            },
            {
              type: "pattern",
              pattern: /^[a-zA-ZÀ-ÿ\s]{1,40}$/,
            },
          ],
        },
        {
          componenttype: "select",
          label: "País de residencia",
          size: "small",
          name: "countryId",
          description: "countryName",
          value: "0",
          margin: "normal",
          xs: 6,
          apiEndPoint: "Country",
          validations: [
            {
              type: "required",
            },
          ],
        },
        {
          componenttype: "select",
          label: "Ciudad de residencia",
          size: "small",
          name: "cityId",
          description: "nameCity",
          value: "0",
          margin: "normal",
          xs: 6,
          apiEndPoint: "City/GetCitiesByCountryId/*",
          anidado: true,
          param: { name: "countryId" },
          validations: [
            {
              type: "required",
            },
          ],
        },
        {
          componenttype: "input",
          label: "Dirección de residencia",
          type: "text",
          size: "small",
          name: "address",
          value: "",
          margin: "normal",
          xs: 12,
          validations: [
            {
              type: "required",
            },
            {
              type: "pattern",
              value: /^[a-zA-Z0-9\s]{1,64}$/,
              message:
                "Solo se permiten letras o números y la longitud debe ser menor a 64 caracteres",
            },
          ],
        },

        {
          componenttype: "input",
          label: "Número de teléfono",
          type: "text",
          size: "small",
          name: "phone",
          value: "",
          margin: "normal",
          xs: 12,
          validations: [
            {
              type: "required",
            },
            {
              type: "maxDigits",
              message: "El número debe tener máximo 10 digitos",
            },
            {
              type: "min",
              message: "El número de telefono debe tener minimo 10 digitos",
              valueMin: 10,
            },
          ],
        },

        {
          componenttype: "check",
          label: "Se considera usted una persona públicamente expuesta (PEP)",
          title:
            "Se considerarán como Personas Expuestas Políticamente (PEP) los servidores públicos de cualquier sistema de nomenclatura y clasificación de empleos de la administración pública nacional y territorial, cuando tengan asignadas o delegadas funciones de: expedición de normas o regulaciones, dirección general, formulación de políticas institucionales y adopción de planes, programas y proyectos, manejo directo de bienes, dineros o valores del Estado, administración de bienes, muebles e inmuebles.",
          type: "checkbox",
          size: "small",
          name: "publicPerson",
          value: false,
          margin: "normal",
          xs: 12,
          validations: [],
        },
        {
          componenttype: "check",
          label: "Aceptar terminos y condiciones",
          size: "small",
          name: "checkTerms",
          value: false,
          margin: "normal",
          xs: 12,
          validations: [
            {
              type: "checkTermsOk",
            },
          ],
        },
        {
          componenttype: "check",
          label: "Aceptar politicas de privacidad",
          size: "small",
          name: "checkPrivacy",
          value: false,
          margin: "normal",
          xs: 12,
          validations: [
            {
              type: "checkPrivacyOk",
            },
          ],
        },
        {
          componenttype: "input",
          label: "User Id",
          type: "text",
          size: "small",
          name: "userId",
          value: "",
          xs: 12,
          sx: { display: "none" },
          validations: [],
        },
      ],
    },
    // {
    //   label: "Datos de Domicilio",
    //   content: "Paso 3",
    // },
    // {
    //   label: "Datos de Trabajo",
    //   content: "Paso 4",
    // },
    // {
    //   label: "Datos de Referencia",
    //   content: "Paso 5",
    // },
    // {
    //   label: "Finalizar Registro",
    //   content: "Paso 6",
    // },
  ];
};
