import { yupResolver } from "@hookform/resolvers/yup";
import { Grid, Typography } from "@mui/material";
import React from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { useFetchPost } from "../../../Hooks/useFetchPost";
import { useValidateForm } from "../../../Hooks/useValidateForm";
import {
  StyledMUIBoxContainerForm,
  StyledMUIButton,
  StyledSpan,
} from "../../../StyledCrossComponents";
import { pse } from "../../../assets";
import {
  useStorageModal,
  useStoreModalNotification,
  useStorePaymentStep,
} from "../../../store";
import theme from "../../../theme";
import {
  CrossCheck,
  CrossDatePicker,
  CrossInputText,
  CrossLoader,
  CrossSearchInput,
  CrossSelectFile,
  CrossSelectList,
} from "../../CrossComponents";
import { CrossModalNotification } from "../../CrossComponents/CrossModalNotification";
import { getPaymentData } from "../../SettlePayments/Helpers";
import { EnsuranceButton } from "./EnsuranceButton";
import { EstimatePayment } from "./EstimatePayment";
import ExchangeRate from "./ExchangeRate";
import { PaymentSumary } from "./PaymentSumary";
import { SendOthersPaymentsMethods } from "./SendOthersPaymentsMethods";
import { SendPaymentLink } from "./SendPaymentLink";
import { TargetAmount } from "./TargetAmount";
import { ExchangeRatePersons } from "./ExchangeRatePersons";
import { SearchBeneficiary } from "./SearchBeneficiary";

export const PaymentStepForm = ({
  handleNext,
  handleBack,
  activeStep,
  dataSteps,
  dataStepActive,
}) => {
  const { validationshema, initialValues } = useValidateForm(
    dataStepActive.form
  );
  const setModalAlert = useStoreModalNotification(
    (state) => state.setModalAlert
  );

  const { setModalCross } = useStorageModal();
  const setLoader = (openModal) => {
    setModalCross({
      openModal: openModal,
      component: () => <CrossLoader />,
      setValue: () => {},
      formValues: {},
    });
  };

  const store = useStorePaymentStep();
  const navigate = useNavigate();
  const {
    handleSubmit,
    formState: { errors },
    control,
    watch,
    setValue,
    register,
    reset,
  } = useForm({
    defaultValues: initialValues,
    resolver: yupResolver(validationshema),
  });
  const { SubmitFormMedia } = useFetchPost();
  const formValues = watch();

  const onSubmit = () => {
    const formData = new FormData();
    if (activeStep === dataSteps.length - 1) {
      setLoader(true);
      for (let key in formValues) {
        if (formValues[key] instanceof FileList) {
          formData.append("myFile", formValues.myFile[0]);
        }
        getPaymentData({ key, formValues, formData, store });
      }

      SubmitFormMedia(formData, "Payments")
        .then((response) => {
          if (!response.ok) {
            setModalAlert({
              openModal: true,
              variant: "filled",
              severity: "error",
              message: "Error al enviar el pago",
            });
            setLoader(false);
            return;
          } else {
            setLoader(false);
            setModalAlert({
              openModal: true,
              variant: "filled",
              severity: "success",
              message: "Pago enviado con exito",
            });
          }
          handleNext();
          reset();
        })
        .catch((error) => {
          setLoader(false);
          setModalAlert(true, "filled", "error", "Error al enviar el pago");
          navigate("/Dashboard/PaymentsList");
        });
    } else {
      handleNext();
    }
  };

  return (
    <Grid container alignItems={"center"} justifyContent={"center"}>
      <Grid item xs={6} mt={10}>
        <StyledMUIBoxContainerForm width={dataStepActive.boxWidth}>
          <form noValidate onSubmit={handleSubmit(onSubmit)}>
            <Grid container justifyContent="center" spacing={2} p={5}>
              <Grid
                item
                xs={12}
                display={activeStep === dataSteps.length - 1 ? "block" : "none"}
              >
                <StyledSpan>Opción 1:</StyledSpan>
              </Grid>
              <StyeleCrossImg
                src={pse}
                alt="pse"
                display={activeStep === dataSteps.length - 1 ? "block" : "none"}
              />
              {dataStepActive.form.map((item) => {
                switch (item.componenttype) {
                  case "input":
                    return (
                      <CrossInputText
                        key={item.name}
                        errors={errors}
                        control={control}
                        item={item}
                      />
                    );
                  case "select":
                    return (
                      <CrossSelectList
                        key={item.nameId ? item.nameId : item.name}
                        errors={errors}
                        control={control}
                        item={item}
                        formValues={formValues}
                        setValue={setValue}
                      />
                    );
                  case "check":
                    return (
                      <CrossCheck
                        key={item.name}
                        errors={errors}
                        control={control}
                        item={item}
                        register={register}
                      />
                    );
                  case "inputfile":
                    return (
                      <CrossSelectFile
                        key={item.name}
                        errors={errors}
                        control={control}
                        item={item}
                        formValues={formValues}
                        setValue={setValue}
                      />
                    );
                  case "exchangeRate":
                    return (
                      <ExchangeRate
                        key={item.name}
                        setValue={setValue}
                        formValues={formValues}
                      />
                    );
                  case "exchangeRatePersons":
                    return (
                      <ExchangeRatePersons
                        key={item.name}
                        setValue={setValue}
                        formValues={formValues}
                      />
                    );
                  case "estimatePayment":
                    return (
                      <EstimatePayment
                        key={item.name}
                        formValues={formValues}
                        setValues={setValue}
                      />
                    );
                  case "targetAmount":
                    return (
                      <TargetAmount
                        key={item.name}
                        setValue={setValue}
                        formValues={formValues}
                        item={item}
                        control={control}
                        errors={errors}
                      />
                    );
                  case "NoShow":
                    return (
                      <Typography
                        key={item.name}
                        variant="h6"
                        gutterBottom
                        sx={{ display: "none" }}
                      />
                    );

                  case "searchInput":
                    return (
                      <CrossSearchInput
                        key={item.name}
                        errors={errors}
                        control={control}
                        item={item}
                        formValues={formValues}
                        setValue={setValue}
                        ComponentModal={() => <SearchBeneficiary />}
                      />
                    );
                  case "datePicker":
                    return (
                      <CrossDatePicker
                        key={item.name}
                        errors={errors}
                        control={control}
                        item={item}
                      />
                    );
                  case "sendPaymentLink":
                    return <SendPaymentLink setValue={setValue} />;
                  case "sendOthersPaymentsMethods":
                    return (
                      <SendOthersPaymentsMethods
                        formValues={formValues}
                        handleNext={handleNext}
                        activeStep={activeStep}
                        dataSteps={dataSteps}
                        setValue={setValue}
                        reset={reset}
                      />
                    );

                  default:
                    return null;
                }
              })}

              <Grid container justifyContent={"space-between"} mt={5}>
                <Grid item>
                  <StyledMUIButton
                    backgroundcolor={theme.Palette.secondary}
                    disabled={activeStep === 0}
                    onClick={handleBack}
                  >
                    Regresar
                  </StyledMUIButton>
                </Grid>
                <Grid item>
                  {activeStep !== dataSteps.length - 1 ? (
                    <StyledMUIButton
                      backgroundcolor={theme.Palette.secondary}
                      type="submit"
                    >
                      Siguiente
                    </StyledMUIButton>
                  ) : null}
                </Grid>
              </Grid>
            </Grid>
          </form>
        </StyledMUIBoxContainerForm>
      </Grid>
      {activeStep == dataSteps.length - 1 ? (
        <>
          <Grid container justifyContent={"center"} item xs={6} pl={5}>
            <PaymentSumary formValues={formValues} setValue={setValue} />
            <EnsuranceButton />
          </Grid>
        </>
      ) : null}
      <CrossModalNotification />
    </Grid>
  );
};

const StyeleCrossImg = styled.img`
  width: 20%;
  display: ${(props) => (props.display ? props.display : "block")};
`;
