import React, { useEffect, useState } from "react";
import { useStoreModalNotification, useUserStore } from "../store";
import { useNavigate } from "react-router-dom";

export const useFetchGet = (apiController) => {
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);
  const [controller, setController] = useState(null);
  const baseUrl = import.meta.env.VITE_REACT_APP_BASE_URL;
  //const navigate = useNavigate();

  const setModalAlert = useStoreModalNotification(
    (state) => state.setModalAlert
  );

  const { user } = useUserStore();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`${baseUrl}${apiController}`, {
          credentials: "include",
          headers: {
            "Content-Type": "application/json",
            Authorization: user ? `Bearer ${user.token}` : "",
          },
        });

        if (response.status === 401) {
          setModalAlert({
            openModal: true,
            message: `${response.url}-${response.type}`, //"Su sesión ha expirado, por favor vuelva a iniciar sesión",
            severity: "error",
            variant: "filled",
          });
          //window.location.href = "/";
        }
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        const json = await response.json();
        setData(json);
      } catch (err) {
        setError(err.message);
      } finally {
        //setIsLoading(false);
      }
    };

    fetchData();
  }, [apiController]);

  return { data, setData, error };
};
