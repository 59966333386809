import { Grid } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { Footer, NavBar, SeguroMedico } from "../Components";
import {
  BodyContainer,
  StyledCheckCircleIcon,
  DivContainer,
  ImageServiceComponent,
  StyledMUIButton,
  StyledMUIGridContainer,
  Text,
  Titule,
  Styledimg,
} from "../Styled-components/index";

import LinkIcon from "@mui/icons-material/Link";
import { subservice1, subservice4, subservice5 } from "../../../assets";
import theme from "../../../theme";
import { useTranslation } from "react-i18next";
import { Navigate, useNavigate } from "react-router-dom";
import ImageComponent from "../Styled-components/StyledImageComponent";
import { animationgreen } from "../../../assets";
import { SettleSection } from "../Components/SettleSection";
import usePageTracking from "../../../Hooks/usePageTracking";

export const SubService = () => {
  usePageTracking("SubService");
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const [animate, setAnimate] = useState(false);
  const ref = useRef();

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (setAnimate(entry.isIntersecting)) {
          setAnimate(true);
          setTimeout(() => setAnimate(false), 1000);
        }
      },
      { threshold: 0.5 } // Se activa cuando el 50% del elemento es visible
    );

    if (ref.current) {
      observer.observe(ref.current);
    }

    return () => {
      if (ref.current) {
        observer.unobserve(ref.current);
      }
    };
  }, []);

  return (
    <>
      <Grid
        container
        spacing={2}
        alignItems="center"
        justifyContent="center"
        sx={{
          backgroundColor: theme.Palette.quinary,
          fontFamily: theme.Font.primary,
          padding: "20px",
        }}
      >
        <NavBar backgroundcolor={theme.Palette.quinary} />
        <StyledMUIGridContainer
          container
          spacing={2}
          alignItems="center"
          justifyContent="center"
          sx={{ maxWidth: theme.Breakpoint.xlarge, position: "relative" }}
          ref={ref}
        >
          <Styledimg
            $top="70px"
            $left="-400px"
            $rotate="rotate(130deg)"
            $animate={animate}
          >
            <ImageComponent src={animationgreen} alt="hoserviceme" />
          </Styledimg>
          <Grid item xs={12} sm={5}>
            <Titule>{t("subServiceContainer_1.title")}</Titule>
            <Text>{t("subServiceContainer_1.description_1")}</Text>
            <StyledMUIButton
              onClick={() => Navigate("/PreRegistro")}
              $width="50%"
            >
              {t("subServiceContainer_1.button")}
            </StyledMUIButton>
          </Grid>
          <Grid
            item
            xs={12}
            sm={7}
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <ImageServiceComponent src={subservice1} alt="edupayments" />
          </Grid>
        </StyledMUIGridContainer>
      </Grid>
      <BodyContainer>
        <SettleSection />
        <SeguroMedico />
        <StyledMUIGridContainer
          id="auto-transferencia"
          container
          spacing={2}
          alignItems="center"
          justifyContent="center"
          mt={40}
          sx={{
            backgroundColor: theme.Palette.senary,
            overflow: "hidden",
          }}
        >
          <Styledimg
            $top="-80px"
            $left="1000px"
            $rotate="rotate(270deg)"
            $width="260px"
            $height="300px"
          >
            <ImageComponent src={animationgreen} alt="hoserviceme" />
          </Styledimg>
          <Grid
            item
            xs={12}
            sm={6}
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <ImageServiceComponent src={subservice4} alt="edupayments" />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Titule>{t("subServiceContainer_4.title_1")}</Titule>
            <Text>{t("subServiceContainer_4.description_1")}</Text>
            <DivContainer>
              <StyledCheckCircleIcon />
              <Text>{t("subServiceContainer_4.description_2")}</Text>
            </DivContainer>
            <DivContainer>
              <StyledCheckCircleIcon />
              <Text>{t("subServiceContainer_4.description_3")}</Text>
            </DivContainer>
            <DivContainer>
              <StyledCheckCircleIcon />
              <Text>{t("subServiceContainer_4.description_4")}</Text>
            </DivContainer>
          </Grid>
        </StyledMUIGridContainer>
        <StyledMUIGridContainer container justifyContent="center">
          <Grid item xs={12} sm={7}>
            <Titule textalign="center">
              {t("subServiceContainer_5.title_1")}
            </Titule>
          </Grid>
        </StyledMUIGridContainer>
        <StyledMUIGridContainer
          id="link-de-pago"
          container
          justifyContent="center"
          alignItems="center"
          sx={{ backgroundColor: theme.Palette.septenary }}
        >
          <Grid item xs={12} sm={5}>
            <LinkIcon fontSize="large" sx={{ color: theme.Palette.tertiary }} />
            <Titule>{t("subServiceContainer_5.title_2")}</Titule>
            <Text>{t("subServiceContainer_5.description_1")}</Text>
            <StyledMUIButton
              onClick={() => navigate("/service/#link-de-pago")}
              $width="50%"
            >
              {t("subServiceContainer_5.button")}
            </StyledMUIButton>
          </Grid>
          <Grid
            item
            xs={12}
            sm={7}
            sx={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            <ImageServiceComponent src={subservice5} alt="" />
          </Grid>
        </StyledMUIGridContainer>
        <Footer />
      </BodyContainer>
    </>
  );
};
