import React from "react";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Typography from "@mui/material/Typography";
import { Grid } from "@mui/material";
import theme from "../../../theme";
import { SettleForm } from "./SettleForm";
import { getDataSteps } from "../Helpers/getDataSteps";
import { useNavigate } from "react-router-dom";
import { useStoreSeattlePaymentStep } from "../../../store";

export const SeattlePaymentSteps = ({ rowData }) => {
  const [activeStep, setActiveStep] = React.useState(0);
  const [skipped, setSkipped] = React.useState(new Set());
  const setSeattlePayment = useStoreSeattlePaymentStep(
    (state) => state.setSeattlePaymentStep
  );

  // Set global state for SeattlePaymentStep
  setSeattlePayment({
    agencyPayment: {
      agencyId: rowData.agencyId,
      senderCurrency: rowData.currency,
      beneficiaryCurrency: rowData.currency,
      organizationReferenceId: rowData.paymentReference,
      companyName: rowData.agencyName,
      paymentReference: rowData.paymentReference,
    },
    beneficiaryPayment: {
      agencyId: rowData.agencyId,
      senderCurrency: rowData.currency,
      organizationReferenceId: rowData.paymentReference,
      companyName: rowData.beneficiaryFirstName,
      beneficiaryCurrency: rowData.currency,
      paymentReference: rowData.paymentReference,
    },
  });

  const navigate = useNavigate();

  const isStepOptional = (step) => {
    return step === 5;
  };

  const isStepSkipped = (step) => {
    return skipped.has(step);
  };

  const handleNext = () => {
    let newSkipped = skipped;

    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStep);
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped(newSkipped);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const dataSteps = getDataSteps(rowData);
  const handleReset = () => {
    // setActiveStep(0);
    //navigate("/");
  };

  return (
    <Grid container spacing={2} alignItems="center" justifyContent="center">
      <Grid
        container
        spacing={2}
        alignItems="center"
        justifyContent="center"
        sx={{ maxWidth: theme.Breakpoint.xlarge }}
      >
        <Box mt={10} sx={{ width: "100%" }}>
          <Stepper activeStep={activeStep} alternativeLabel>
            {dataSteps.map((item, index) => {
              const stepProps = {};
              const labelProps = {};
              if (isStepOptional(index)) {
                labelProps.optional = (
                  <Typography variant="caption">Optional</Typography>
                );
              }
              if (isStepSkipped(index)) {
                stepProps.completed = false;
              }
              return (
                <Step key={item.label} {...stepProps}>
                  <StepLabel {...labelProps}>{item.label}</StepLabel>
                </Step>
              );
            })}
          </Stepper>
          {activeStep === dataSteps.length ? (
            navigate("/Dashboard/SettlePaymentsList")
          ) : (
            <React.Fragment>
              <Grid
                container
                justifyContent={"center"}
                alignItems={"center"}
                spacing={3}
              >
                <SettleForm
                  handleNext={handleNext}
                  handleBack={handleBack}
                  activeStep={activeStep}
                  dataStepActive={dataSteps[activeStep]}
                  dataSteps={dataSteps}
                />
              </Grid>
            </React.Fragment>
          )}
        </Box>
      </Grid>
    </Grid>
  );
};
