import React from "react";
import { Button, Box, Grid, Popover, Link, Typography } from "@mui/material";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import theme from "../../../theme";
import styled from "styled-components";
import LinkIcon from "@mui/icons-material/Link";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

function StyledComponentDropDownMenu() {
  const { t, i18n } = useTranslation();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const navigate = useNavigate();

  //const theme = useTheme();
  //const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleNavigate = (path, id) => {
    navigate(path);
    setTimeout(() => {
      const element = document.getElementById(id);
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
    }, 100);
    handleClose();
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  return (
    <>
      <div>
        <StyledMUIButton
          aria-describedby={id}
          disableRipple
          onClick={handleClick}
          endIcon={<ArrowDropDownIcon />}
        >
          {t("navBar.solutions")}
        </StyledMUIButton>
        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
          sx={{
            fontFamily: theme.Font.primary,
            "& .MuiPopover-paper": {
              marginLeft: "-140px",
              borderRadius: "25px",
            },
          }}
        >
          <StyledMUIGrid container>
            <Grid item xs={12} md={4}>
              <StyledMUIBox>
                <LinkIcon fontSize="large" />
                <Typography
                  fontSize={theme.FontSize.small}
                  color={theme.Palette.primary}
                >
                  <StyledMUIButtonLink
                    onClick={() =>
                      handleNavigate("/service/#link-de-pago", "link-de-pago")
                    }
                  >
                    {t("dropDownMenu.buttonLink_1")}
                  </StyledMUIButtonLink>
                </Typography>
              </StyledMUIBox>
            </Grid>
            <Grid item xs={12} md={8}>
              <Grid container spacing={2}>
                <Grid item xs={12} md={12}>
                  <Typography
                    fontSize={theme.FontSize.small}
                    color={theme.Palette.primary}
                  >
                    {t("dropDownMenu.title_1")}
                  </Typography>
                </Grid>
                <Grid item xs={12} md={4}>
                  <Typography fontSize="12px">
                    {t("dropDownMenu.title_2")}
                  </Typography>
                  <StyledMUIButtonLink2
                    onClick={() =>
                      handleNavigate(
                        "/subservice/#liquida-en-linea",
                        "liquida-en-linea"
                      )
                    }
                  >
                    {t("dropDownMenu.buttonLink_2")}
                  </StyledMUIButtonLink2>
                </Grid>
                <Grid item xs={12} md={4}>
                  <Typography fontSize="12px">
                    {t("dropDownMenu.title_3")}
                  </Typography>
                  <StyledMUIButtonLink2
                    onClick={() =>
                      handleNavigate(
                        "/subservice/#seguros-estudiantiles",
                        "seguros-estudiantiles"
                      )
                    }
                  >
                    {t("dropDownMenu.buttonLink_3")}
                  </StyledMUIButtonLink2>
                </Grid>
                <Grid item xs={12} md={4}>
                  <Typography fontSize="12px">
                    {t("dropDownMenu.title_4")}
                  </Typography>
                  <StyledMUIButtonLink2
                    onClick={() =>
                      handleNavigate(
                        "/subservice/#auto-transferencia",
                        "auto-transferencia"
                      )
                    }
                  >
                    {t("dropDownMenu.buttonLink_4")}
                  </StyledMUIButtonLink2>
                </Grid>
              </Grid>
            </Grid>
          </StyledMUIGrid>
        </Popover>
      </div>
    </>
  );
}

const StyledMUIButton = styled(Button)`
  && {
    color: ${theme.Palette.primary};
    font-family: ${theme.Font.primary};
    font-weight: 500;
    font-size: 18px;
    text-transform: none;
  }
`;
const StyledMUIBox = styled(Box)`
  && {
    background-color: ${theme.Palette.quinary};
    margin: 20px;
    padding: 10px;
    border-radius: 25px;
  }
`;
const StyledMUIGrid = styled(Grid)`
  && {
    align-items: center;
    justify-content: center;
    width: 1140px;
    height: 250px;
    background-color: ${theme.Palette.senary};
  }

  @media screen and (max-width: 768px) {
    && {
      width: 100%;
    }
  }
`;

const StyledMUILink = styled(Link)`
  && {
    color: ${theme.Palette.secondary};
    font-family: ${theme.Font.primary};
    font-weight: 500;
    font-size: 12px;
    text-decoration: none;
  }
`;

const StyledMUIButtonLink = styled(Button)`
  && {
    color: ${theme.Palette.primary};
    font-family: ${theme.Font.primary};
    font-weight: ${theme.FontWeight.bold};
    font-size: ${theme.FontSize.small};
    background-color: ${theme.Palette.quinary};
    text-align: left;
    text-transform: none;
    &:hover {
      background-color: ${theme.Hover.quinary};
    }
  }
`;

const StyledMUIButtonLink2 = styled(Button)`
  && {
    color: ${theme.Palette.secondary};
    font-family: ${theme.Font.primary};
    font-weight: ${theme.FontWeight.regular};
    background-color: ${theme.Palette.senary};
    text-align: left;
    text-transform: none;
    padding: 0;
    hover: "none";
    font-size: 11px;
    &:hover {
      background-color: ${theme.Hover.senary};
      focus: "none";
      elevation: 0;
    }
  }
`;

export default StyledComponentDropDownMenu;
