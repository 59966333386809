import React from "react";
import {
  DivContainer,
  Icon,
  StyledMUIGridContainer,
  Text,
  Titule,
} from "../Styled-components/index";
import { Grid } from "@mui/material";
import ImageComponent from "../Styled-components/StyledImageComponent";
import { facebookicon, instagramicon, linkedinicon } from "../../../assets";
import theme from "../../../theme";
import { useTranslation } from "react-i18next";
import { Link } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { tc } from "../../../assets";
import { ptd } from "../../../assets";

export const Footer = () => {
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const menuItems = [
    { name: "home", path: "/" },
    { name: "solutions", path: "/service" },
    { name: "features", path: "/subservice" },
    { name: "we", path: "/Nosotros" },
    //{ name: "persons", path: "/Personas" },
    { name: "prices", path: "/Calculator" },
  ];
  const handleOption = (path) => {
    navigate(path);
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  return (
    <StyledMUIGridContainer
      container
      spacing={2}
      mt={10}
      sx={{
        backgroundColor: "#19274B",
      }}
    >
      <Grid item xs={12} sm={3}>
        <Titule fontSize="20px" color={theme.Palette.quinary}>
          Edupayments
        </Titule>
        {menuItems.map((item, index) => (
          <Grid item xs={12} key={index}>
            <Link
              color={theme.Palette.quinary}
              onClick={() => handleOption(item.path)}
              sx={{
                cursor: "pointer",
              }}
            >
              {t("navBar." + item.name)}
            </Link>
          </Grid>
        ))}
      </Grid>
      <Grid item xs={12} sm={3}>
        <Titule fontSize="20px" color="#fff">
          {t("footer.title_1")}
        </Titule>
        <Grid item xs={12}>
          <Link
            color={theme.Palette.quinary}
            sx={{
              cursor: "pointer",
            }}
            href={tc}
            terget="_blank"
            rel="noreferrer noopener"
          >
            {t("footer.sub_Title_2")}
          </Link>
        </Grid>
        <Grid item xs={12}>
          <Link
            color={theme.Palette.quinary}
            sx={{
              cursor: "pointer",
            }}
            href={ptd}
            terget="_blank"
            rel="noreferrer noopener"
          >
            {t("footer.sub_Title_1")}
          </Link>
        </Grid>
      </Grid>
      <Grid item xs={12} sm={3}>
        <Titule fontSize="20px" lineheight="15px" color="#fff">
          {t("footer.title_2")}
        </Titule>
        <Text fontSize="14px" color="#fff">
          81-83 Campbell Street Surry Hills NSW 2010 SYDNEY- AUSTRALIA
        </Text>
        <Text fontSize="14px" color="#fff">
          support@edupayments.net
        </Text>
      </Grid>
      <Grid item xs={12} sm={3}>
        <Titule fontSize="20px" lineheight="15px" color="#fff">
          Austrac registration:
        </Titule>
        <Text fontSize="14px" color="#fff">
          Independent remittance dealer number is IND100684994-001
        </Text>
      </Grid>
      <Grid item xs={12} sm={12}>
        <hr />
      </Grid>
      <Grid container justifyContent="space-between">
        <Grid item xs={12} sm={3}>
          <DivContainer>
            <Link
              href="https://www.facebook.com/edupayments"
              terget="_blank"
              rel="noreferrer noopener"
            >
              <Icon>
                <ImageComponent src={facebookicon} alt="logo" />
              </Icon>
            </Link>
            <Link
              href="https://www.instagram.com/edupayments/?hl=es"
              terget="_blank"
              rel="noreferrer noopener"
            >
              <Icon>
                <ImageComponent src={instagramicon} alt="logo" />
              </Icon>
            </Link>
            <Link href="" terget="_blank" rel="noreferrer noopener">
              <Icon>
                <ImageComponent src={linkedinicon} alt="logo" />
              </Icon>
            </Link>
          </DivContainer>
        </Grid>
        <Grid item xs={12} sm={3}>
          <Text fontSize="14px" color="#fff">
            Copyright © 2023 Edupayments.
          </Text>
        </Grid>
        <Grid item xs={12} sm={3}></Grid>
      </Grid>
    </StyledMUIGridContainer>
  );
};
