import React from "react";
import { CrossDataGrid } from "../../CrossComponents";
import { useFetchGet } from "../../../Hooks/useFetchGet";
import { useLocation } from "react-router-dom";
import { Tooltip } from "@mui/material";
import { StyledGridActionsCellItem } from "../../../StyledCrossComponents";
import { SelectStateUser } from "./SelectStateUser";
import BadgeIcon from "@mui/icons-material/Badge";
import { UserDetails } from "./UserDetails";
import { useStorageModal } from "../../../store";

export const UserList = () => {
  const location = useLocation();
  const { data } = useFetchGet(`Account/GetUserByIsNotAgency`);
  const setModalCross = useStorageModal((state) => state.setModalCross);

  const handleSelectRow = (row) => {
    setModalCross({
      openModal: true,
      component: () => <UserDetails rowData={row} />,
      setValue: null,
      formValues: null,
    });
  };

  const columns = [
    {
      headerName: "Acciones",
      field: "actions",
      type: "actions",
      flex: 1,
      renderCell: (params) => [
        <Tooltip title="Ver información del representante legal">
          <StyledGridActionsCellItem
            key={`read-${params.row.id}`}
            label={"Ver"}
            icon={<BadgeIcon />}
            onClick={() => handleSelectRow(params.row)}
          />
        </Tooltip>,
      ],
    },
    {
      field: "fullName",
      headerName: "Nombre del Usuario",
      headerAlign: "left",
      align: "left",
      flex: 1,
    },
    {
      field: "email",
      headerName: "Correo del Usuario",
      headerAlign: "left",
      align: "left",
      flex: 1,
    },
    {
      field: "emailConfirmed",
      headerName: "Email Confirmado",
      headerAlign: "center",
      align: "center",
      flex: 1,
      renderCell: (params) => {
        return params.value ? "Si" : "No";
      },
    },
    {
      field: "checkValidationIdentity",
      headerName: "Validación de Identidad",
      headerAlign: "center",
      align: "center",
      flex: 1,
      renderCell: (params) => <SelectStateUser row={params.row} />,
    },
    {
      field: "publicPerson",
      headerName: "Persona Pública",
      headerAlign: "center",
      align: "center",
      flex: 1,
      renderCell: (params) => {
        return params.value ? "Si" : "No";
      },
    },
  ];
  return (
    <>
      <CrossDataGrid
        title={`Lista de Usuarios:`}
        columns={columns}
        data={data}
        rowId={"id"}
      />
    </>
  );
};
