import { Box } from "@mui/material";
import styled from "styled-components";
import theme from "../theme";
import { PropaneSharp } from "@mui/icons-material";

export const StyledMUIBoxContainerForm = styled(Box)`
  && {
    border: 0px solid;
    border-color: ${theme.Palette.octonary};
    border-radius: 25px;
    background-color: ${(props) =>
      props.backgroundColor ? props.backgroundColor : theme.Palette.nonary};
    padding: 20px;
    width: ${(props) => (props.width ? props.width : "50%")};

    @media screen {
      @media screen and (max-width: 768px) {
        width: "100%";
        padding: 0%;
      }
      @media screen and (max-width: 576px) {
        width: "100%";
        padding: 1%;
      }
    }
  }
`;

export const StyledMUIBoxContainerCalc = styled(Box)`
  && {
    border: none;
    border-color: ${theme.Palette.octonary};
    border-radius: 10px;
    background-color: ${(props) =>
      props.backgroundColor ? props.backgroundColor : theme.Palette.quinary};
    padding: 20px;
    margin-top: ${(props) => (props.marginTop ? props.marginTop : "5%")};
  }
`;
