import { yupResolver } from "@hookform/resolvers/yup";
import { Grid } from "@mui/material";
import React from "react";
import { useForm } from "react-hook-form";
import { useFetchPost } from "../../../Hooks/useFetchPost";
import { useValidateForm } from "../../../Hooks/useValidateForm";
import { StyledMUIBoxContainerForm } from "../../../StyledCrossComponents";
import { CrossInputText } from "../../CrossComponents";
import { StyledMUIButton } from "../Styled-components";
import { useStoreModalNotification } from "../../../store";
import { set } from "date-fns";

export const RecoverPasswordForm = () => {
  const { validationshema, initialValues } =
    useValidateForm(recoverPasswordForm);
  const { SubmitFormJson } = useFetchPost();
  const {
    handleSubmit,
    formState: { errors },
    control,
  } = useForm({
    defaultValues: initialValues,
    resolver: yupResolver(validationshema),
  });

  const { setModalAlert } = useStoreModalNotification();

  const onSubmit = (data) => {
    console.log(data);
    SubmitFormJson(data, "Autentication/RecoverPassword").then((response) => {
      console.log(response);
      if (!response.ok) {
        setModalAlert({
          openModal: true,
          message: "Usurio no encontrado",
          severity: "error",
          variant: "filled",
        });
        return;
      }
      setModalAlert({
        openModal: true,
        message: "Valida tu correo, para restablecer la contraseña", //response.message,
        severity: "success",
        variant: "filled",
      });
    });
  };

  return (
    <>
      <StyledMUIBoxContainerForm width={"20%"}>
        <Grid container justifyContent="center">
          <Grid item xs={12}>
            <form noValidate onSubmit={handleSubmit(onSubmit)}>
              <Grid
                container
                justifyContent="center"
                flexDirection="column"
                spacing={2}
                p={5}
              >
                {recoverPasswordForm.map((item) => {
                  switch (item.componenttype) {
                    case "input":
                      return (
                        <CrossInputText
                          key={item.name}
                          control={control}
                          item={item}
                          errors={errors}
                        />
                      );
                    case "submitButton":
                      return (
                        <Grid item>
                          <StyledMUIButton
                            key={item.name}
                            $height={"40px"}
                            type={"submit"}
                          >
                            {item.label}
                          </StyledMUIButton>
                        </Grid>
                      );
                  }
                })}
              </Grid>
            </form>
          </Grid>
        </Grid>
      </StyledMUIBoxContainerForm>
    </>
  );
};

const recoverPasswordForm = [
  {
    componenttype: "input",
    label: "Ingrese Correo Electrónico",
    type: "email",
    name: "Email",
    value: "",
    size: "small",
    margin: "normal",
    xs: 12,
    validations: [
      {
        type: "required",
      },
    ],
  },
  {
    componenttype: "submitButton",
    name: "RecoverPassword",
    label: "Recuperar contraseña",
    variant: "contained",
    mode: "submit",
    xs: 12,
    validations: [],
  },
];
