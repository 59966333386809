import React from "react";
import { AgencyList } from "../Components/AgencyList";

export const AgencyAdmin = () => {
  return (
    <>
      <AgencyList />
    </>
  );
};
