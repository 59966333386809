import React, { useState } from "react";
import {
  NavBarButton,
  NavBarContainer,
  NavBarLogo,
  NavBarMenu,
  NavBarMenuItem,
  NavBarWrapper,
  NavBarWrapperLenguage,
  StyledMUINavBarButton,
} from "../Styled-components/Navbarelements";

import MenuIcon from "@mui/icons-material/Menu";
import { useNavigate } from "react-router-dom";
import { navLogo } from "../../../assets";
import { StyledComponentDropDownMenu } from "../Styled-components";
import ImageComponent from "../Styled-components/StyledImageComponent";
import { useTranslation } from "react-i18next";
import { Lenguage } from "./Lenguage";
import { useTheme } from "@mui/system";
import { useMediaQuery } from "@mui/material";
import { ListOptionMenu } from "./ListOptionMenu";
import { is } from "date-fns/locale";
export const NavBar = ({ backgroundcolor }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const [showMenu, setShowMenu] = useState(false);
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();

  const menuItems = [
    { name: "home", path: "/" },
    { name: "solutions", path: "/service" },
    { name: "features", path: "/subservice" },
    { name: "we", path: "/Nosotros" },
    { name: "persons", path: "/Personas" },
    { name: "prices", path: "/Calculator" },
  ];

  const handleOption = (path) => {
    navigate(path);
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
    setShowMenu(false);
  };
  return (
    <>
      <NavBarContainer $backgroundcolor={backgroundcolor}>
        <NavBarWrapperLenguage>
          <Lenguage />
        </NavBarWrapperLenguage>
        <NavBarWrapper>
          <NavBarLogo onClick={() => navigate("/")}>
            <ImageComponent src={navLogo} alt="logo" />
          </NavBarLogo>
          <NavBarButton onClick={() => setShowMenu(!showMenu)}>
            <MenuIcon fontSize="large" />
          </NavBarButton>
          <NavBarMenu $showmenu={showMenu ? 1 : 0}>
            {isMobile ? (
              <ListOptionMenu showMenu={showMenu} setShowMenu={setShowMenu} />
            ) : (
              menuItems.map((item, index) =>
                item.name === "solutions" ? (
                  <NavBarMenuItem key={index}>
                    <StyledComponentDropDownMenu />
                  </NavBarMenuItem>
                ) : (
                  <NavBarMenuItem
                    key={index}
                    onClick={() => handleOption(item.path)}
                  >
                    {t(`navBar.${item.name}`)}
                  </NavBarMenuItem>
                )
              )
            )}
            <StyledMUINavBarButton onClick={() => handleOption("/PreRegistro")}>
              {t("navBar.button_2")}
            </StyledMUINavBarButton>
          </NavBarMenu>
        </NavBarWrapper>
      </NavBarContainer>
    </>
  );
};
