import { Grid } from "@mui/material";
import React from "react";
import { StyledMUIButtonIcon } from "../../../StyledCrossComponents";

export const SendPersonPaymentLink = ({ item }) => {
  const handleClick = () => {
    console.log("Enviar link de pago PSE");
  };

  return (
    <Grid item xs={12}>
      <StyledMUIButtonIcon
        variant="contained"
        fullWidth
        type="submit"
        onClick={handleClick}
      >
        {item.label}
      </StyledMUIButtonIcon>
    </Grid>
  );
};
