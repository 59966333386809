import React, { useEffect, useRef, useState } from "react";
import { NavBar } from "../Components/NavBar";
import {
  BodyContainer,
  DivContainer,
  StyledCheckCircleIcon,
  StyledMUIButton,
  StyledMUIGrid,
  StyledMUIGridContainer,
  StyledMUIGridReverse,
  StyledMUIPaper,
  StyledMUIPaperContainer,
  Styledimg,
  Text,
  Titule,
} from "../Styled-components/index";
import { Grid, Typography } from "@mui/material";
import SupportAgentIcon from "@mui/icons-material/SupportAgent";
import ComputerIcon from "@mui/icons-material/Computer";
import PaidIcon from "@mui/icons-material/Paid";
import TimerIcon from "@mui/icons-material/Timer";
import PersonIcon from "@mui/icons-material/Person";
import CurrencyExchangeIcon from "@mui/icons-material/CurrencyExchange";
import { Empresas, Footer } from "../Components";
import ImageComponent from "../Styled-components/StyledImageComponent";
import { homeimage1, homeimage2, homeimage4 } from "../../../assets";
import theme from "../../../theme";
import { useTranslation } from "react-i18next";
import { useLenguageStore } from "../../../store";
import { useLocation, useNavigate } from "react-router-dom";
import { animationblue2 } from "../../../assets";
import { InsuranseSection } from "../Components/InsuranseSection";
import { use } from "i18next";
import ReactGA from "react-ga4";
import usePageTracking from "../../../Hooks/usePageTracking";

export const Home = () => {
  const [animate, setAnimate] = useState(false);
  const ref = useRef();
  usePageTracking("Home");
  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (setAnimate(entry.isIntersecting)) {
          setAnimate(true);
          setTimeout(() => setAnimate(false), 1000);
        }
      },
      { threshold: 0.5 } // Se activa cuando el 50% del elemento es visible
    );

    if (ref.current) {
      observer.observe(ref.current);
    }

    return () => {
      if (ref.current) {
        observer.unobserve(ref.current);
      }
    };
  }, []);
  const { t, i18n } = useTranslation();
  const { insurancelinkEs } = useLenguageStore();
  const navigate = useNavigate();

  const handleNavigate = (path, id) => {
    navigate(path);
    setTimeout(() => {
      const element = document.getElementById(id);
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
    }, 100);
  };

  return (
    <>
      <Grid
        container
        spacing={2}
        alignItems="center"
        justifyContent="center"
        sx={{
          backgroundColor: theme.Palette.senary,
          fontFamily: theme.Font.primary,
          padding: "20px",
        }}
      >
        <NavBar backgroundcolor={theme.Palette.senary} />
        <Grid
          container
          spacing={2}
          alignItems="center"
          justifyContent="center"
          sx={{ maxWidth: theme.Breakpoint.xlarge }}
        >
          <Grid item xs={12} md={5}>
            <Titule>{t("homeContainer_1.title")}</Titule>
            <Text>{t("homeContainer_1.description")}</Text>
            <StyledMUIButton
              variant="contained"
              onClick={() => navigate("/PreRegistro")}
              $width="50%"
            >
              {t("homeContainer_1.button")}
            </StyledMUIButton>
          </Grid>
          <StyledMUIGridReverse item xs={12} md={7}>
            <ImageComponent src={homeimage1} alt="home" />
          </StyledMUIGridReverse>
        </Grid>
      </Grid>

      <BodyContainer>
        <StyledMUIGridContainer
          container
          spacing={2}
          alignItems="center"
          mt={10}
          marginBottom="420px"
          sx={{
            background: theme.Palette.septenary,
          }}
          ref={ref}
        >
          <Grid item xs={12} md={5}>
            <Titule>{t("homeContainer_2.title")}</Titule>
            <Text>{t("homeContainer_2.description")}</Text>
            <DivContainer>
              <StyledCheckCircleIcon />
              <Text>{t("homeContainer_2.label_1")}</Text>
            </DivContainer>
            <DivContainer>
              <StyledCheckCircleIcon />
              <Text>{t("homeContainer_2.label_2")}</Text>
            </DivContainer>
            <DivContainer>
              <StyledCheckCircleIcon />
              <Text>{t("homeContainer_2.label_3")}</Text>
            </DivContainer>
            <DivContainer>
              <StyledCheckCircleIcon />
              <Text>{t("homeContainer_2.label_4")}</Text>
            </DivContainer>
            <StyledMUIButton
              variant="contained"
              $width="50%"
              onClick={() =>
                handleNavigate("/service/#link-de-pago", "link-de-pago")
              }
            >
              {t("homeContainer_2.button")}
            </StyledMUIButton>
          </Grid>
          <StyledMUIGridReverse item xs={12} md={5}>
            <ImageComponent src={homeimage2} alt="home" />
          </StyledMUIGridReverse>
          <StyledMUIGrid
            container
            justifyContent="center"
            spacing={2}
            sx={{ top: "95%" }}
            //scrolled={scrolled}
          >
            <Grid item xs={12} md={5}>
              <StyledMUIPaperContainer
                elevation={1}
                sx={{
                  backgroundColor: "#EAF4FF",
                }}
              >
                <SupportAgentIcon fontSize="large" />
                <Titule fontSize={theme.FontSize.medium}>
                  {t("homeContainer_2.subTitle_1")}
                </Titule>
                <Text>{t("homeContainer_2.subDescription_1")}</Text>
              </StyledMUIPaperContainer>
            </Grid>
            <Grid item xs={12} md={5}>
              <StyledMUIPaperContainer
                elevation={1}
                sx={{
                  backgroundColor: "#EAF4FF",
                }}
              >
                <SupportAgentIcon fontSize="large" />
                <Titule fontSize={theme.FontSize.medium}>
                  {t("homeContainer_2.subTitle_2")}
                </Titule>
                <Text>{t("homeContainer_2.subDescription_2")}</Text>
              </StyledMUIPaperContainer>
            </Grid>
          </StyledMUIGrid>
          <Styledimg
            $top="-80px"
            $left="1030px"
            $width="260px"
            $height="260px"
            //$rotate="rotate(360deg)"
            $zindex="-1"
            $animate={animate}
          >
            <ImageComponent src={animationblue2} alt="home" />
          </Styledimg>
        </StyledMUIGridContainer>
        <InsuranseSection />
        <StyledMUIGridContainer
          container
          spacing={2}
          alignItems="center"
          justifyContent="center"
          mt={50}
          sx={{
            backgroundColor: theme.Palette.tertiary,
          }}
        >
          <Grid item>
            <Titule color={theme.Palette.quinary}>
              {t("homeContainer_4.title")}
            </Titule>
          </Grid>
          <Grid item container justifyContent={"center"} xs={12} md={12}>
            <StyledMUIButton
              href={insurancelinkEs}
              variant="contained"
              $width="20%"
            >
              {t("homeContainer_4.button")}
            </StyledMUIButton>
          </Grid>
        </StyledMUIGridContainer>
        <Empresas />
        <StyledMUIGridContainer container spacing={2} mt={10}>
          <Grid item>
            <ImageComponent src={homeimage4} alt="home" />
          </Grid>
          <StyledMUIGrid
            container
            justifyContent="center"
            spacing={3}
            sx={{ top: "5%", left: "50%", width: "50%" }}
          >
            <Grid item xs={12} md={6}>
              <StyledMUIPaper elevation={1}>
                <ComputerIcon fontSize="large" />
                <Typography variant="h6" gutterBottom>
                  {t("homeContainer_5.title_1")}
                </Typography>
                <Text fontSize="14" $lineheight="15px">
                  {t("homeContainer_5.description_1")}
                </Text>
              </StyledMUIPaper>
            </Grid>
            <Grid item xs={12} md={6}>
              <StyledMUIPaper elevation={1}>
                <PaidIcon fontSize="large" sx={{ color: "#FCD53A" }} />
                <Typography variant="h6" gutterBottom>
                  {t("homeContainer_5.title_2")}
                </Typography>
                <Text fontSize="14" $lineheight="15px">
                  {t("homeContainer_5.description_2")}
                </Text>
              </StyledMUIPaper>
            </Grid>
            <Grid item xs={12} md={6}>
              <StyledMUIPaper elevation={1}>
                <TimerIcon fontSize="large" sx={{ color: "#46BA7C" }} />
                <Typography variant="h6" gutterBottom>
                  {t("homeContainer_5.title_3")}
                </Typography>
                <Text fontSize="14" $lineheight="15px">
                  {t("homeContainer_5.description_3")}
                </Text>
              </StyledMUIPaper>
            </Grid>
            <Grid item xs={12} md={6}>
              <StyledMUIPaper elevation={1}>
                <PersonIcon fontSize="large" sx={{ color: "#19274B" }} />
                <Typography variant="h6" gutterBottom>
                  {t("homeContainer_5.title_4")}
                </Typography>
                <Text fontSize="14" $lineheight="15px">
                  {t("homeContainer_5.description_4")}
                </Text>
              </StyledMUIPaper>
            </Grid>
            <Grid item xs={12} md={6}>
              <StyledMUIPaper elevation={1}>
                <CurrencyExchangeIcon
                  fontSize="large"
                  sx={{ color: "#8FC3FF" }}
                />
                <Typography variant="h6" gutterBottom>
                  {t("homeContainer_5.title_5")}
                </Typography>
                <Text fontSize="14" $lineheight="15px">
                  {t("homeContainer_5.description_5")}
                </Text>
              </StyledMUIPaper>
            </Grid>
            <Grid item xs={12} md={6}>
              <StyledMUIPaper elevation={1}>
                <SupportAgentIcon fontSize="large" sx={{ color: "#FCD53A" }} />
                <Typography variant="h6" gutterBottom>
                  {t("homeContainer_5.title_6")}
                </Typography>
                <Text fontSize="14" $lineheight="15px">
                  {t("homeContainer_5.description_6")}
                </Text>
              </StyledMUIPaper>
            </Grid>
          </StyledMUIGrid>
        </StyledMUIGridContainer>
        <Footer />
      </BodyContainer>
    </>
  );
};
