import React from "react";
import { SettlePaymentsDataList } from "../Components/SettlePaymentsDataList";

export const SettlePaymentsList = () => {
  return (
    <>
      <SettlePaymentsDataList />
    </>
  );
};
