import React from "react";
import styled from "styled-components";
import { Grid, Link } from "@mui/material";
import { useLenguageStore } from "../../../store";
import { StyledMUINavbarLink } from "../Styled-components";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import theme from "../../../theme";

export const Lenguage = () => {
  const stateLenguage = useLenguageStore();
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();

  const handleOption = (path) => {
    navigate(path);
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    <>
      <StyledMUIGridLenguage
        container
        justifyContent={"end"}
        spacing={1}
        fontFamily={theme.Font.primary}
      >
        <Grid item>
          <Link
            sx={{
              textDecoration: "none",
            }}
            onClick={() =>
              stateLenguage.setLenguage(
                "es",
                "https://studentinsurancefinder.com/edupayments/customer/home"
              )
            }
          >
            ESP
          </Link>
        </Grid>
        <Grid item>|</Grid>
        <Grid item>
          <Link
            sx={{
              textDecoration: "none",
            }}
            onClick={() =>
              stateLenguage.setLenguage(
                "en",
                "https://studentinsurancefinder.com/edupayment/customer/home"
              )
            }
          >
            ENG
          </Link>
        </Grid>
        <Grid item>|</Grid>
        <Grid item>
          <StyledMUINavbarLink onClick={() => handleOption("/login")}>
            {t("navBar.button_1")}
          </StyledMUINavbarLink>
        </Grid>
      </StyledMUIGridLenguage>
    </>
  );
};

const StyledMUIGridLenguage = styled(Grid)`
  && {
    /* position: absolute;
    left: 1206px;
    top: -19px;
    width: 137px;
    height: 54px;
    z-index: 1000; */
    cursor: pointer;
  }
`;
