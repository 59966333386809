import { Box } from "@mui/material";
import styled from "styled-components";
import theme from "../theme";

export const StyledMUIBox = styled(Box)`
  && {
    border: 1px solid;
    border-color: ${theme.Palette.octonary};
    border-radius: 25px;
    background-color: ${theme.Palette.quinary};
    padding: 20px;
    width: 50%;
    //height: 460px;
    top: 155%;
    left: 50%;
    transform: translate(-50%, -50%);
    position: absolute;
  }
`;
