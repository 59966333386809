import React, { useEffect } from "react";
import {
  StyledHr,
  StyledMUIButtonIcon,
  StyledSpan,
} from "../../../StyledCrossComponents";
import { Grid, Typography } from "@mui/material";
import { useFetchPost } from "../../../Hooks/useFetchPost";
import {
  useStorageModal,
  useStoreModalNotification,
  useStorePaymentStep,
} from "../../../store";
import { useNavigate } from "react-router-dom";
import { getPaymentData } from "../../SettlePayments/Helpers";
import { CrossLoader } from "../../CrossComponents";
import { set } from "date-fns";

export const SendOthersPaymentsMethods = ({
  formValues,
  activeStep,
  dataSteps,
  setValue,
  handleNext,
  reset,
}) => {
  const navigate = useNavigate();
  const { SubmitFormMedia } = useFetchPost();
  const setModalAlert = useStoreModalNotification(
    (state) => state.setModalAlert
  );
  const store = useStorePaymentStep();
  const { setModalCross } = useStorageModal();
  const setLoader = (openModal) => {
    setModalCross({
      openModal: openModal,
      component: () => <CrossLoader />,
      setValue: () => {},
      formValues: {},
    });
  };

  useEffect(() => {
    setValue("paymentOptionId", 1, true);
  }, []);

  const handleSubmit = () => {
    const formData = new FormData();
    if (activeStep === dataSteps.length - 1) {
      setLoader(true);
      for (let key in formValues) {
        if (formValues[key] instanceof FileList) {
          formData.append("myFile", formValues.myFile[0]);
        }
        getPaymentData({ key, formValues, formData, store });
      }

      SubmitFormMedia(formData, "Payments")
        .then((responseData) => {
          if (!responseData.ok) {
            setModalAlert({
              openModal: true,
              variant: "filled",
              severity: "error",
              message: "Error al enviar el pago",
            });
            setLoader(false);
            return;
          } else {
            setLoader(false);
            setModalAlert({
              openModal: true,
              variant: "filled",
              severity: "success",
              message: "Pago enviado con exito",
            });
            reset();
            handleNext();
          }
        })
        .catch((error) => {
          setLoader(false);
          setModalAlert(true, "filled", "error", "Error al enviar el pago");
          navigate("/Dashboard/PaymentsList");
        });
    } else {
      handleNext();
    }
  };

  return (
    <>
      <Grid item xs={12}>
        <StyledHr />
        <StyledSpan>Opción 2:</StyledSpan>
      </Grid>
      <Grid item xs={12}>
        <StyledMUIButtonIcon
          variant="contained"
          fullWidth
          onClick={handleSubmit}
        >
          Enviar pago por transferencia y/o efectivo
        </StyledMUIButtonIcon>
      </Grid>
    </>
  );
};
