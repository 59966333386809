import React, { useEffect } from "react";
import { StyledMUIBoxContainerForm } from "../../../StyledCrossComponents";
import { Grid, Typography } from "@mui/material";
import { useFetchGet } from "../../../Hooks/useFetchGet";
import theme from "../../../theme";
import { useLedgerAccountStore, useUserStore } from "../../../store";
import { set } from "date-fns";

export const PersonLedgerAccount = () => {
  const { user } = useUserStore();
  const { setLedgerAccount } = useLedgerAccountStore();
  const userLanguage = navigator.language || navigator.userLanguage;
  const { data } = useFetchGet(`LedgerAccount/${user.ledgerAccount}`);

  useEffect(() => {
    setLedgerAccount(data);
  }, [data]);

  if (!data) return null;

  return (
    <StyledMUIBoxContainerForm width={"100%"} mt={10}>
      <Grid
        container
        justifyContent={"center"}
        alignItems={"center"}
        spacing={2}
      >
        <Grid item xs={6} md={3}>
          <Typography
            //fontSize={"12px"}
            sx={{ fontSize: { xs: "9px", sm: "12px" } }}
            color={theme.Palette.quaternary}
            textAlign={"center"}
            fontWeight={"bold"}
          >
            Nombre: {data.name}
          </Typography>
        </Grid>
        <Grid item xs={6} md={3}>
          <Typography
            //fontSize={"12px"}
            sx={{ fontSize: { xs: "9px", sm: "12px" } }}
            color={theme.Palette.quaternary}
            textAlign={"center"}
            fontWeight={"bold"}
          >
            Balance:{" "}
            {data.balance.toLocaleString(userLanguage, {
              style: "currency",
              currency: "AUD",
            })}
          </Typography>
        </Grid>
        <Grid item xs={6} md={3}>
          <Typography
            //fontSize={"12px"}
            sx={{ fontSize: { xs: "9px", sm: "12px" } }}
            color={theme.Palette.quaternary}
            textAlign={"center"}
            fontWeight={"bold"}
          >
            Bsb: {user.bsb}
          </Typography>
        </Grid>
        <Grid item xs={6} md={3}>
          <Typography
            //fontSize={"12px"}
            sx={{ fontSize: { xs: "9px", sm: "12px" } }}
            color={theme.Palette.quaternary}
            textAlign={"center"}
            fontWeight={"bold"}
          >
            Número de cuenta: {data.automatcherBankAccountNumber}
          </Typography>
        </Grid>
      </Grid>
    </StyledMUIBoxContainerForm>
  );
};
