import React, { useState } from "react";
import { useStorageModal } from "../../../store";
import {
  StyledHr,
  StyledMUIBoxContainerForm,
  StyledMUIButtonIcon,
  StyledSpan,
} from "../../../StyledCrossComponents";
import { Grid, Stack, Typography } from "@mui/material";
import theme from "../../../theme";
import { Text } from "../../Home/Styled-components";

export const DetailedAudCopPayment = ({ row }) => {
  const userLanguage = navigator.language || navigator.userLanguage;
  const { resetModalCross } = useStorageModal();
  const handleClose = () => {
    resetModalCross();
  };

  return (
    <StyledMUIBoxContainerForm
      width={{ sx: "100%", md: "50%" }}
      height="100vh"
      ml={{ sx: "0", md: "4px" }}
      backgroundColor={theme.Palette.senary}
    >
      <Grid container direction={"column"}>
        <Grid item>
          <Typography
            sx={{
              color: theme.Palette.quaternary,
            }}
            fontSize={"14px"}
          >
            Detalle del Pago
          </Typography>
          <StyledHr />
          <StackedItem
            label="Referencia de pago:"
            value={row.paymentReference}
          />
          <StackedItem label="Motivo:" value={row.paymentActionTypeName} />
          <StackedItem label="Banco:" value={row.bankName} />
          <StackedItem label="Tipo de cuenta:" value={row.accountTypeName} />
          <StackedItem label="Número de cuenta:" value={row.accountNumber} />
          <StackedItem
            label="Valor:"
            value={row.estimatePayment.toLocaleString(userLanguage, {
              style: "currency",
              currency: "COP",
            })}
          />
          <Typography
            sx={{
              color: theme.Palette.quaternary,
            }}
            fontSize={"14px"}
          >
            Datos del Beneficiario
          </Typography>
          <StyledHr />
          <StackedItem label="Beneficiario:" value={row.beneficiaryFullName} />
          <StackedItem
            label="Número de documento:"
            value={row.beneficiaryIdentificationNumber}
          />
          <StackedItem label="Correo:" value={row.beneficiaryMail} />
          <StackedItem label="Teléfono:" value={row.beneficiaryPhoneNumber} />
          <StackedItem label="Pais:" value={row.beneficiaryCountryName} />
          <StackedItem label="Ciudad:" value={row.beneficiaryCityName} />
          <StackedItem label="Dirección:" value={row.beneficiaryAddress} />
        </Grid>
        <Grid item mt={5}>
          <StyledHr />
          <Grid container spacing={2} justifyContent={"end"}>
            <Grid item>
              <StyledMUIButtonIcon variant="contained" onClick={handleClose}>
                Cerrar
              </StyledMUIButtonIcon>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </StyledMUIBoxContainerForm>
  );
};

const StackedItem = ({ label, value, link }) => (
  <Stack direction="row" alignItems="center" height="25px">
    <StyledSpan>{label}</StyledSpan>
    <Text $fontSize="8px">{value}</Text>
  </Stack>
);
