import React from "react";
import { useLedgerAccountStore, useUserStore } from "../../../store";
import { useFetchGet } from "../../../Hooks/useFetchGet";
import { CrossDataGrid } from "../../CrossComponents";
import { min } from "date-fns";
import { minWidth } from "@mui/system";

export const NppPaymentList = () => {
  const { user } = useUserStore();
  const { ledgerAccount } = useLedgerAccountStore();
  const userLanguage = navigator.language || navigator.userLanguage;
  const columns = [
    {
      field: "Type",
      headerName: "Tipo de pago",
      headerAlign: "center",
      align: "center",
      renderCell: (params) => {
        return <>TOPUP</>;
      },
      flex: 1,
      minWidth: 200,
    },
    {
      field: "transactionId",
      headerName: "Id Transacción",
      headerAlign: "center",
      align: "center",
      flex: 1,
      minWidth: 200,
    },
    {
      field: "dateTime",
      headerName: "Fecha de transacción",
      headerAlign: "center",
      align: "center",
      flex: 1,
      minWidth: 200,
      renderCell: (params) => {
        const date = new Date(params.row.dateTime);
        return date.toLocaleDateString(userLanguage);
      },
    },
    {
      field: "amount",
      headerName: "Valor transacción",
      headerAlign: "center",
      align: "center",
      flex: 1,
      minWidth: 200,
      renderCell: (params) => {
        return (
          <>
            {params.row.amount.toLocaleString(userLanguage, {
              style: "currency",
              currency: "AUD",
            })}
          </>
        );
      },
    },
    {
      field: "State",
      headerName: "Estado",
      headerAlign: "center",
      align: "center",
      renderCell: (params) => {
        return <>Recibido</>;
      },
      flex: 1,
      minWidth: 200,
    },
  ];
  const { data } = useFetchGet(
    `NppPaymentUp/account/${ledgerAccount.automatcherBankAccountNumber}`
  );

  return (
    <>
      <CrossDataGrid
        title={"Lista de transacciones"}
        columns={columns}
        data={data}
        rowId={"id"}
        sortModel={[
          {
            field: "dateTime",
            sort: "desc",
          },
        ]}
      />
    </>
  );
};
