import React from "react";
import { PaymentListAudCop } from "../Components/PaymentListAudCop";

export const PaymentPerAudCop = () => {
  return (
    <>
      <PaymentListAudCop />
    </>
  );
};
