import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import React from "react";
import { Controller } from "react-hook-form";
import { StyledMUITextField } from "../../StyledCrossComponents";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { Grid } from "@mui/material";

export const CrossDatePicker = ({ item, control, errors }) => {
  return (
    <>
      <Grid item xs={item.xs} justifyContent={"center"}>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <Controller
            name={item.name} //"PolicyEndsOn"
            control={control}
            //rules={{ required: "Este campo es requerido" }}
            render={({ field }) => (
              <DesktopDatePicker
                {...field}
                inputFormat="YYYY/MM/DD"
                label={item.label} //"Policy ends on"
                renderInput={(params) => (
                  <StyledMUITextField
                    {...params}
                    size="small"
                    error={!!errors[item.name]}
                    helperText={
                      errors[item.name] ? errors[item.name].message : null
                    }
                  />
                )}
              />
            )}
          />
        </LocalizationProvider>
      </Grid>
    </>
  );
};
