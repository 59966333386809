import React from "react";
import PaymentSteps from "../Components/PaymentSteps";

export const Payments = () => {
  return (
    <>
      <PaymentSteps />
    </>
  );
};
