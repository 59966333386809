import { FlashOffRounded, TrendingUpOutlined } from "@mui/icons-material";
import { ru } from "date-fns/locale";
import * as Yup from "yup";
export const useValidateForm = (formJson, isNew) => {
  const initialValues = {};
  const requiredFields = {};
  const userLogged = JSON.parse(localStorage.getItem("myuser"));

  for (const input of formJson) {
    initialValues[input.nameId ? input.nameId : input.name] = input.value;

    let shema = Yup.string();

    for (const rule of input.validations) {
      if (rule.type === "required") {
        shema = shema.required("Este campo es requerido");

        if (input.componenttype === "select") {
          shema = shema.notOneOf(["0"], "Seleccione una opción valida");
        }
      }
      if (rule.type === "confirmPassword") {
        shema = shema.oneOf(
          [Yup.ref(rule.name), null],
          "Las contraseñas no coinciden"
        );
      }
      if (rule.type === "email") {
        shema = shema.email("El correo no es valido");
      }
      if (rule.type === "checkTermsOk") {
        shema = Yup.bool().isTrue("Debe aceptar los terminos y condiciones");
      }
      if (rule.type === "checkPrivacyOk") {
        shema = Yup.bool().isTrue("Debe aceptar las politicas de privacidad");
      }
      if (rule.type === "pattern") {
        shema = shema.matches(rule.value, rule.message);
      }
      if (rule.type === "min") {
        shema = Yup.number().test("isnotZero", rule.message, (value) => {
          return value > 0;
        });
      }
      if (rule.type === "max-value-Settle") {
        shema = Yup.number().test("isnotMax", rule.message, (value) => {
          return value <= rule.value;
        });
      }
      if (rule.type === "maxDigits") {
        shema = shema.test("maxDigits", rule.message, (value) => {
          return String(value).length <= 10;
        });
      }
      if (rule.type === "max") {
        shema = shema.max(rule.value, rule.message);
      }
      if (rule.type === "min") {
        shema = shema.test("minDigits", rule.message, (value) => {
          return String(value).length >= rule.valueMin;
        });
      }
    }
    requiredFields[input.nameId ? input.nameId : input.name] = shema;
  }
  const validationshema = Yup.object({ ...requiredFields });
  return { validationshema, initialValues };
};
