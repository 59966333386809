import { Grid, Typography } from "@mui/material";
import React from "react";
import { StyledSpan } from "../../../StyledCrossComponents";
import { useStoreSeattlePaymentStep } from "../../../store";
import theme from "../../../theme";

export const AboutSettlePayment = () => {
  const stateGlobal = useStoreSeattlePaymentStep(
    (state) => state.seattlePaymentStepState
  );
  const userLanguage = navigator.language || navigator.userLanguage;

  return (
    <Grid item container xs={6} md={12} justifyContent={"center"} spacing={5}>
      <Grid item>
        <Typography variant="h6">Liquidación Agencia</Typography>
        <Typography
          variant="h6"
          gutterBottom
          textAlign={"left"}
          sx={{
            width: "300px",
            color: theme.Palette.tertiary,
            fontSize: 14,
            // display: "flex",
            // justifyContent: "space-between",
          }}
        >
          <StyledSpan>Empresa: </StyledSpan>
          {stateGlobal.agencyPayment.companyName}
          <br />
          <StyledSpan>BSB: </StyledSpan>
          {stateGlobal.agencyPayment.bsb}
          <br />
          <StyledSpan>Número de cuenta: </StyledSpan>
          {stateGlobal.agencyPayment.accountNumber}
          <br />
          <StyledSpan>Monto a liquidar: </StyledSpan>
          {stateGlobal.agencyPayment.senderAmount.toLocaleString(userLanguage, {
            style: "currency",
            currency: stateGlobal.agencyPayment.beneficiaryCurrency || "AUD",
          })}
          <br />
        </Typography>
      </Grid>
      <Grid item>
        <Typography variant="h6">Liquidación Prov. Educativo</Typography>
        <Typography
          variant="h6"
          gutterBottom
          textAlign={"left"}
          sx={{
            width: "300px",
            color: theme.Palette.tertiary,
            fontSize: 14,
            // display: "flex",
            // justifyContent: "space-between",
          }}
        >
          <StyledSpan>Empresa: </StyledSpan>
          {stateGlobal.beneficiaryPayment.companyName}
          <br />
          <StyledSpan>BSB: </StyledSpan>
          {stateGlobal.beneficiaryPayment.bsb}
          <br />
          <StyledSpan>Número de cuenta: </StyledSpan>
          {stateGlobal.beneficiaryPayment.accountNumber}
          <br />
          <StyledSpan>Monto a liquidar: </StyledSpan>
          {stateGlobal.beneficiaryPayment.senderAmount.toLocaleString(
            userLanguage,
            {
              style: "currency",
              currency:
                stateGlobal.beneficiaryPayment.beneficiaryCurrency || "AUD",
            }
          )}
          <br />
        </Typography>
      </Grid>
    </Grid>
  );
};
