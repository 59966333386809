import { Select } from "@mui/material";
import theme from "../theme";
import styled from "styled-components";

export const StyledMUISelect = styled(Select)`
  && {
    &.MuiInputBase-root {
      border-radius: 25px;
    }
    &.MuiInput-underline:after {
      border-bottom-color: ${theme.Palette.octonary};
    }
    &.MuiOutlinedInput-root {
      & fieldset {
        border-color: ${theme.Palette.octonary};
      }
      &:hover fieldset {
        border-color: ${theme.Palette.septenary};
      }
      &.Mui-focused fieldset {
        border-color: ${theme.Palette.septenary};
      }
    }
  }
`;
