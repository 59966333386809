export const getDataSteps = (rowData) => [
  {
    label: "Liquidar Pago Agencia",
    step: 0,
    boxWidth: "60%",
    nameStep: "agency",
    validations: [],
    form: [
      {
        componenttype: "NoShow",
        name: "targetAmount",
        value: rowData.targetAmount,
        validations: [],
        xs: 12,
      },
      {
        componenttype: "input",
        label: "Digite Comisión de la agencia",
        type: "number",
        size: "small",
        name: "stellPaymentValue",
        value: 0,
        margin: "normal",
        xs: 12,
        disabled: false,
        validations: [
          {
            type: "required",
          },
          {
            type: "max-value-Settle",
            value: rowData.targetAmount,
            message: `El valor debe ser menor o igual ${rowData.targetAmount}`,
          },
        ],
      },
      // {
      //   componenttype: "inputcross",
      //   label: "Nombre de la cuenta",
      //   type: "number",
      //   size: "small",
      //   name: "companyName",
      //   value: "",
      //   margin: "normal",
      //   xs: 12,
      //   disabled: false,
      //   validations: [{ type: "required" }],
      // },
      // {
      //   componenttype: "inputcross",
      //   label: "bsb",
      //   type: "number",
      //   size: "small",
      //   name: "bsb",
      //   value: "",
      //   margin: "normal",
      //   xs: 12,
      //   disabled: false,
      //   validations: [{ type: "required" }],
      // },
      // {
      //   componenttype: "inputcross",
      //   label: "Número de cuenta",
      //   type: "number",
      //   size: "small",
      //   name: "accountNumber",
      //   value: 0,
      //   margin: "normal",
      //   xs: 12,
      //   disabled: false,
      //   validations: [{ type: "required" }],
      // },
      // {
      //   componenttype: "select",
      //   type: "select",
      //   label: "Tipo persona",
      //   size: "small",
      //   name: "id",
      //   nameId: "typePersonAgencyId",
      //   description: "typePersonName",
      //   value: "0",
      //   filter: [1],
      //   margin: "normal",
      //   xs: 12,
      //   apiEndPoint: "TypePerson",
      //   validations: [
      //     {
      //       type: "required",
      //     },
      //   ],
      // },
      // {
      //   componenttype: "select",
      //   type: "select",
      //   label: "Beneficiarios Disponibles",
      //   size: "small",
      //   name: "id",
      //   nameId: "bankAgencyId",
      //   description: "nameBank",
      //   value: "0",
      //   margin: "normal",
      //   xs: 12,
      //   apiEndPoint: `AgencyBank/GetBanksByAgencyId/${rowData.agencyId}`,
      //   validations: [
      //     {
      //       type: "required",
      //     },
      //   ],
      // },
      // {
      //   componenttype: "select",
      //   type: "select",
      //   label: "Medios de Pago",
      //   size: "small",
      //   name: "payoutMethodId",
      //   nameId: "payoutAgencytMethodId",
      //   description: "payoutMethodDescription",
      //   filter: ["pom_1a3f27eb698f4854bdfc26c81d4c1c33"],
      //   value: "0",
      //   margin: "normal",
      //   xs: 12,
      //   apiEndPoint: `PaymentMethod/Finmo/All?SenderCountry=AU&SenderCurrency=AUD&BeneficiaryCountry=${rowData.beneficiaryShortCountryName}&BeneficiaryCurrency=${rowData.beneficiaryCurrency}`,

      //   validations: [
      //     {
      //       type: "required",
      //     },
      //   ],
      // },
    ],
  },
  {
    label: "Liquidar Pago Proveedor Educativo",
    step: 1,
    nameStep: "beneficiary",
    boxWidth: "60%",
    validations: [],
    form: [
      {
        componenttype: "input",
        label: "Valor a pagar proveedor educativo",
        type: "number",
        size: "small",
        name: "stellProvePaymentValue",
        value: 0,
        margin: "normal",
        xs: 12,
        disabled: true,
        validations: [],
      },
      // {
      //   componenttype: "select",
      //   type: "select",
      //   label: "Beneficiarios Disponibles",
      //   size: "small",
      //   name: "id",
      //   nameId: "bankProveId",
      //   description: "acountNameOwner",
      //   value: "0",
      //   margin: "normal",
      //   xs: 12,
      //   apiEndPoint: `AgencyBank/GetBanksByAgencyId/${rowData.agencyId}`,
      //   validations: [
      //     {
      //       type: "required",
      //     },
      //   ],
      // },
      // {
      //   componenttype: "select",
      //   type: "select",
      //   label: "Tipo persona",
      //   size: "small",
      //   name: "id",
      //   nameId: "typePersonBeneId",
      //   description: "typePersonName",
      //   value: "0",
      //   filter: [1],
      //   margin: "normal",
      //   xs: 12,
      //   apiEndPoint: "TypePerson",
      //   validations: [
      //     {
      //       type: "required",
      //     },
      //   ],
      // },
      // {
      //   componenttype: "select",
      //   type: "select",
      //   label: "Medios de Pago",
      //   size: "small",
      //   name: "payoutMethodId",
      //   nameId: "payouBeneMethodId",
      //   description: "payoutMethodDescription",
      //   filter: ["pom_1a3f27eb698f4854bdfc26c81d4c1c33"],
      //   value: "0",
      //   margin: "normal",
      //   xs: 12,
      //   apiEndPoint: `PaymentMethod/Finmo/All?SenderCountry=AU&SenderCurrency=AUD&BeneficiaryCountry=${rowData.beneficiaryShortCountryName}&BeneficiaryCurrency=${rowData.beneficiaryCurrency}`,
      //   validations: [
      //     {
      //       type: "required",
      //     },
      //   ],
      // },
    ],
  },
  {
    label: "Resumen de Liquidación",
    step: 2,
    boxWidth: "60%",
    validations: [],
    form: [],
  },
];
