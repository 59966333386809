import { yupResolver } from "@hookform/resolvers/yup";
import { Grid } from "@mui/material";
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { useFetchPost } from "../../../Hooks/useFetchPost";
import { useValidateForm } from "../../../Hooks/useValidateForm";
import { StyledMUIBoxContainerForm } from "../../../StyledCrossComponents";
import { CrossInputText } from "../../CrossComponents";
import { StyledMUIButton } from "../Styled-components";
import { useLocation, useNavigate } from "react-router-dom";
import { sub } from "date-fns";
import { useStoreModalNotification } from "../../../store";

export const ResetPasswordForm = () => {
  const { setModalAlert } = useStoreModalNotification();
  const locaion = useLocation();
  const queryParams = new URLSearchParams(locaion.search);
  const navigate = useNavigate();

  const { validationshema, initialValues } = useValidateForm(resetPasswordForm);
  const { SubmitFormJson } = useFetchPost();
  const {
    handleSubmit,
    formState: { errors },
    control,
  } = useForm({
    defaultValues: initialValues,
    resolver: yupResolver(validationshema),
  });

  const onSubmit = (data) => {
    let requestResetPassword = {
      token: queryParams.get("token"),
      userId: queryParams.get("id"),
      newPassword: data.NewPassword,
    };
    console.log(data);
    SubmitFormJson(requestResetPassword, "Autentication/ResetPassword").then(
      (response) => {
        if (!response.ok) {
          setModalAlert({
            openModal: true,
            message: "Error al cambiar la contraseña",
            severity: "error",
            variant: "filled",
          });
          return;
        }
        setModalAlert({
          openModal: true,
          message: "Contraseña cambiada correctamente",
          severity: "success",
          variant: "filled",
        });
        navigate("/login");
      }
    );
  };

  return (
    <>
      <StyledMUIBoxContainerForm width={"20%"}>
        <Grid container justifyContent="center">
          <Grid item xs={12}>
            <form noValidate onSubmit={handleSubmit(onSubmit)}>
              <Grid
                container
                justifyContent="center"
                flexDirection="column"
                spacing={2}
                p={5}
              >
                {resetPasswordForm.map((item) => {
                  switch (item.componenttype) {
                    case "input":
                      return (
                        <CrossInputText
                          key={item.name}
                          control={control}
                          item={item}
                          errors={errors}
                        />
                      );
                    case "submitButton":
                      return (
                        <Grid item>
                          <StyledMUIButton
                            key={item.name}
                            $height={"40px"}
                            type={"submit"}
                          >
                            {item.label}
                          </StyledMUIButton>
                        </Grid>
                      );
                  }
                })}
              </Grid>
            </form>
          </Grid>
        </Grid>
      </StyledMUIBoxContainerForm>
    </>
  );
};

const resetPasswordForm = [
  {
    componenttype: "input",
    label: "Nueva Contraseña",
    type: "password",
    name: "NewPassword",
    value: "",
    size: "small",
    margin: "normal",
    xs: 12,
    validations: [
      {
        type: "required",
      },
    ],
  },
  {
    componenttype: "submitButton",
    name: "RecoverPassword",
    label: "Reset contraseña",
    variant: "contained",
    mode: "submit",
    xs: 12,
    validations: [],
  },
];
