import React from "react";
import { StyledMUIGridContainer, Titule } from "../Styled-components/index";
import { Grid } from "@mui/material";
import theme from "../../../theme";
import { ozzy } from "../../../assets";
import styled from "styled-components";
import { aic } from "../../../assets";
import { studylites } from "../../../assets";
import { austral } from "../../../assets";
import { useTranslation } from "react-i18next";
import { yes } from "../../../assets";

export const Empresas = () => {
  const { t, i18n } = useTranslation();
  return (
    <StyledMUIGridContainer
      container
      spacing={4}
      alignItems="center"
      justifyContent="center"
      mt={10}
      sx={{
        backgroundColor: theme.Palette.octonary,
      }}
    >
      <Grid item xs={6} md={12}>
        <Titule $textalign="center" color={theme.Palette.tertiary}>
          {t("companies.title_1")}
        </Titule>
      </Grid>
      <Grid item xs={6} md={2}>
        <ImgStyled src={ozzy} alt="empresas" />
      </Grid>
      <Grid item xs={6} md={2}>
        <ImgStyled src={aic} alt="empresas" />
      </Grid>
      <Grid item xs={6} md={2}>
        <ImgStyled src={studylites} alt="empresas" />
      </Grid>
      <Grid item xs={6} md={2}>
        <ImgStyled src={yes} alt="empresas" />
      </Grid>
      <Grid item xs={6} md={2}>
        <ImgStyled src={austral} alt="empresas" />
      </Grid>
    </StyledMUIGridContainer>
  );
};

const ImgStyled = styled.img`
  width: 100%;
  height: 100%;
  //object-fit: cover;
`;
