import {
  FormControlLabel,
  FormHelperText,
  Grid,
  Switch,
  Tooltip,
} from "@mui/material";
import React from "react";
import { Controller } from "react-hook-form";

export const CrossCheck = ({ item, errors, control, register }) => {
  return (
    <Grid item xs={item.xs}>
      <Controller
        name={item.name}
        control={control}
        defaultValue={false}
        render={({ field }) => (
          <Tooltip title={item.title} placement="top">
            <FormControlLabel
              control={<Switch {...field} size="small" />}
              label={item.label}
              labelPlacement="end"
              sx={{
                "& .MuiFormControlLabel-label": {
                  fontSize: "0.8rem",
                  color: "#000000",
                },
              }}
            />
          </Tooltip>
        )}
      />
      {errors[item.name] && (
        <FormHelperText id="my-helper-text" error>
          {errors[item.name]["message"]}
        </FormHelperText>
      )}
    </Grid>
  );
};
