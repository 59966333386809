const theme = {
  Palette: {
    primary: "#09051a",
    secondary: "#434749",
    tertiary: "#19274B",
    quaternary: "#46ba7c",
    quinary: "#fff",
    senary: "#DFE8F8",
    septenary: "#E5E5E5",
    octonary: "#FCD53A",
    nonary: "#FAFAFA",
  },
  Font: {
    primary: "Poppins",
    secondary: "Roboto",
  },
  FontSize: {
    small: "0.8rem",
    medium: "1.3rem",
    large: "2.0rem",
  },
  FontWeight: {
    light: 300,
    regular: 500,
    bold: 700,
  },
  Breakpoint: {
    small: "576px",
    medium: "768px",
    large: "992px",
    xlarge: "1180px",
  },

  LineHeight: {
    small: "1.7rem",
    medium: "2rem",
    large: "4rem",
  },
  Hover: {
    primary: "#f9a826",
    secondary: "#434749",
  },
};
export default theme;
